import { ButtonAction } from "@/interfaces/persons/v10/tabs/pois";
import { TranslateResult } from "vue-i18n";

export class ButtonActionEntity implements ButtonAction {
	type: string = "";
	loading: Boolean = false;
	disabled: Boolean = true;
	icon: string = "";
	title: TranslateResult = "";

	constructor(
		type: string,
		title: TranslateResult,
		icon: string,
		disabled: Boolean = true
	) {
		this.type = type;
		this.title = title;
		this.icon = icon;
		this.disabled = disabled;
	}

	setLoading(loading: Boolean = false) {
		this.loading = loading;
	}

	setDisabled(disabled: Boolean = true) {
		this.disabled = disabled;
	}
}

export function setButtonAction(type: string, icon: string) {
	const title = `persons.v10.panelsPois.button.${type}`;
	return new ButtonActionEntity(type, title, icon, true);
}

export function getPoisActions() {
	return [
		setButtonAction("analize_pois", "mdi-search-web"),
		setButtonAction("calculate_reach", "mdi-earth"),
		setButtonAction("store_attribution", "mdi-table-of-contents"),
		setButtonAction("analize_audience", "mdi-account-group-outline"),
		setButtonAction("save_pois", "mdi-download-box-outline"),
		setButtonAction("geo_fencing", "mdi-map-marker-outline"),
		//setButtonAction("geo_report", "mdi-file-document"),
		setButtonAction("target_audience", "mdi-crosshairs"),
	];
}
