import { Filters } from "@/interfaces/person";
import {
	GetData,
	GetErrors,
	GetMessage,
	AxiosGet,
} from "@/services/axios-service";
import { parseEncode } from "@/utils/filter-global";
import { isEmpty, isNull, isUndefined } from "lodash";

const ROUTE = require("../api/routes").PERSON.POIS;

class GraphicsPoisService {
	async getGraphics(params: { filters?: any; type: string }) {
		try {
			let filter = "";

			if (!isUndefined(params.filters)) {
				filter = getFilters(params.filters);
			}

			const response = await AxiosGet(
				`${ROUTE.PERSON_POIS_GRAPHIC}?${filter}`
			);

			return Promise.resolve(GetData(response));
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error),
			});
		}
	}
}

/**
 * @param {Filters} filters The values for filters
 * @return {string} Returns string with filters, else empty
 */
function getFilters(filters: Filters, filter_country_code?: string): string {
	let filter = "";

	//const filter_country_code = filters.filter_country_code || null; // fiters[country_code]=
	const country_code = filters.country_code || null; // country_code=
	const layers = filters.layers || [];
	const pais = !filter_country_code ? filters.pais : "";
	const categories = filters.categoria || [];
	const subcategoria = filters.subcategoria || [];
	const dpto = filters.dpto || [];
	const nombre = filters.nombre || [];
	const ciudad = filters.ciudad || [];
	const marca = filters.marca || [];
	const localidad_barrio = filters.localidad_barrio || [];
	const search = filters.search || null;
	const term = filters.term || null;
	const paises = filters.paises || [];
	const layer_paises = filters.layer_paises || [];
	const layer_dpto = filters.layer_dpto || [];
	const layer_ciudad = filters.layer_ciudad || [];
	const layer_categoria = filters.layer_categoria || [];
	const layer_subcategoria = filters.layer_subcategoria || [];
	const layer_nombre = filters.layer_nombre || [];
	const layer_marca = filters.layer_marca || [];
	const layer_term = filters.layer_term || null;
	const layer_localidad_barrio = filters.layer_localidad_barrio || [];

	var symbol = "";

	if (country_code) {
		filter += `${symbol}country_code=${country_code}`;
		symbol = "&";
	}

	if (filter_country_code) {
		filter += `${symbol}filters[country_code]=${filter_country_code}`;
		symbol = "&";
	}

	if (pais) {
		filter += `${symbol}filters[pais]=${pais}`;
		symbol = "&";
	}

	if (!filter_country_code && !isEmpty(paises)) {
		paises.forEach((c) => {
			filter += `${symbol}filters[pais][]=${c}`;
			symbol = "&";
		});
	}

	if (!isEmpty(categories)) {
		categories.forEach((c) => {
			filter += `${symbol}filters[categoria][]=${parseEncode(c)}`;
			symbol = "&";
		});
	}

	if (!isEmpty(subcategoria)) {
		subcategoria.forEach((c) => {
			filter += `${symbol}filters[subcategoria][]=${parseEncode(c)}`;
			symbol = "&";
		});
	}

	if (!isEmpty(dpto)) {
		dpto.forEach((c) => {
			filter += `${symbol}filters[dpto][]=${parseEncode(c)}`;
			symbol = "&";
		});
	}

	if (!isEmpty(nombre)) {
		nombre.forEach((c) => {
			filter += `${symbol}filters[nombre][]=${parseEncode(c)}`;
			symbol = "&";
		});
	}

	if (!isEmpty(ciudad)) {
		ciudad.forEach((c) => {
			filter += `${symbol}filters[ciudad][]=${parseEncode(c)}`;
			symbol = "&";
		});
	}

	if (!isEmpty(marca)) {
		marca.forEach((c) => {
			filter += `${symbol}filters[marca][]=${parseEncode(c)}`;
			symbol = "&";
		});
	}

	if (!isEmpty(localidad_barrio)) {
		localidad_barrio.forEach((c) => {
			filter += `${symbol}filters[localidad_barrio][]=${parseEncode(c)}`;
			symbol = "&";
		});
	}

	if (!isNull(search) && !isEmpty(search)) {
		filter += `${symbol}search=${parseEncode(search)}`;
		symbol = "&";
	}

	if (!isNull(term) && !isEmpty(term)) {
		filter += `${symbol}term=${parseEncode(term)}`;
		symbol = "&";
	}

	if (!isEmpty(layers)) {
		layers.forEach((c) => {
			filter += `${symbol}layers[]=${parseEncode(c)}`;
			symbol = "&";
		});
	}

	if (!isEmpty(layer_paises)) {
		layer_paises.forEach((c) => {
			filter += `${symbol}layer_filters[pais][]=${c}`;
			symbol = "&";
		});
	}

	if (!isEmpty(layer_dpto)) {
		layer_dpto.forEach((c) => {
			filter += `${symbol}layer_filters[dpto][]=${parseEncode(c)}`;
			symbol = "&";
		});
	}

	if (!isEmpty(layer_ciudad)) {
		layer_ciudad.forEach((c) => {
			filter += `${symbol}layer_filters[ciudad][]=${parseEncode(c)}`;
			symbol = "&";
		});
	}

	if (!isEmpty(layer_categoria)) {
		layer_categoria.forEach((c) => {
			filter += `${symbol}layer_filters[categoria][]=${parseEncode(c)}`;
			symbol = "&";
		});
	}

	if (!isEmpty(layer_subcategoria)) {
		layer_subcategoria.forEach((c) => {
			filter += `${symbol}layer_filters[subcategoria][]=${parseEncode(
				c
			)}`;
			symbol = "&";
		});
	}

	if (!isEmpty(layer_nombre)) {
		layer_nombre.forEach((c) => {
			filter += `${symbol}layer_filters[nombre][]=${parseEncode(c)}`;
			symbol = "&";
		});
	}

	if (!isEmpty(layer_marca)) {
		layer_marca.forEach((c) => {
			filter += `${symbol}layer_filters[marca][]=${parseEncode(c)}`;
			symbol = "&";
		});
	}

	if (!isEmpty(layer_localidad_barrio)) {
		layer_localidad_barrio.forEach((c) => {
			filter += `${symbol}layer_filters[localidad_barrio][]=${parseEncode(c)}`;
			symbol = "&";
		});
	}

	if (!isNull(layer_term) && !isEmpty(layer_term)) {
		filter += `${symbol}term=${parseEncode(layer_term)}`;
		symbol = "&";
	}

	return filter;
}

export default new GraphicsPoisService();
