import { LIMIT, Mode } from "@/interfaces/persons/v10/query/global";
import {
	Paginate,
	PaginateData,
	PostData,
} from "@/interfaces/persons/v10/query/pois";
import { PersonPoisKey } from "@/interfaces/persons/v10/types";

export class PostDataEntity implements PostData {
	paginateData: PaginateDataEntity = new PaginateDataEntity();

	constructor() {}

	// SETTERS //

	setSearch(filterName: string, search: string | null) {
		this.paginateData[filterName].page = 1;
		this.paginateData[filterName].search = search;
		this.paginateData[filterName].searching = true;
	}

	setSearching(filterName: string, searching: Boolean = false) {
		this.paginateData[filterName].searching = searching;
	}

	setCurrentPage(filterName: string, page: number = 1) {
		this.paginateData[filterName].page = page;
	}

	setMode(filterName: string, mode: Mode) {
		this.paginateData[filterName].mode = mode;
	}

	// GETTERS //

	async getCurrentPage(filterName: string) {
		return this.paginateData[filterName].page;
	}

	// ACTIONS

	async resetFilters(filterName: string) {
		if (filterName === PersonPoisKey.CATEGORIES) {
			this.paginateData[PersonPoisKey.SUBCATEGORIES] =
				new PaginateEntity();
			this.paginateData[PersonPoisKey.BRANDS] = new PaginateEntity();
			this.paginateData[PersonPoisKey.NAMES] = new PaginateEntity();
		}

		if (filterName === PersonPoisKey.SUBCATEGORIES) {
			this.paginateData[PersonPoisKey.BRANDS] = new PaginateEntity();
			this.paginateData[PersonPoisKey.NAMES] = new PaginateEntity();
		}

		if (filterName === PersonPoisKey.BRANDS) {
			this.paginateData[PersonPoisKey.NAMES] = new PaginateEntity();
		}
	}

	async increasePage(filterName: string) {
		this.paginateData[filterName].page++;
	}

	async decreasePage(filterName: string) {
		this.paginateData[filterName].page--;
	}

	getQuery(filterName: string) {
		return this.paginateData[filterName];
	}
}

export class PaginateDataEntity implements PaginateData {
	categories: PaginateEntity = new PaginateEntity();
	subcategories: PaginateEntity = new PaginateEntity();
	brands: PaginateEntity = new PaginateEntity();
	names: PaginateEntity = new PaginateEntity();
	privates: PaginateEntity = new PaginateEntity();

	constructor() {}
}

export class PaginateEntity implements Paginate {
	mode: Mode = Mode.PAGINATED;
	limit: number = LIMIT;
	page: number = 1;
	search: string | null = null;
	searching: Boolean = false;

	constructor(mode?: Mode) {
		if(mode) {
			this.mode = mode;
		}
	}

	async setMode(mode: Mode) {
		this.mode = mode;
	}
}
