import {
	ModuleSelectAll,
	SelectAll,
	SelectAllGeo,
	SelectAllPois,
	SelectAllPrivate,
	TotalTypeAll,
} from "@/interfaces/persons/v10/select_all";
import { TotalType } from "@/interfaces/persons/v10/types";
import { isUndefined } from "lodash";

export enum SelectAllProp {
	STATES = "states",
	CITIES = "cities",
	NEIGHBORHOODS = "neighborhoods",
	CATEGORIES = "categories",
	SUBCATEGORIES = "subcategories",
	BRANDS = "brands",
	NAMES = "names",
	PRIVATE = "privates",
}

export class SelectAllEntity implements SelectAll {
	loading: Boolean = false;
	disabled: Boolean = false;
	checked: Boolean = false;
	term: String = "";

	constructor(properties?: SelectAllEntity) {
		if(properties) {
			this.loading = properties.loading;
			this.disabled = properties.disabled;
			this.checked = properties.checked;
			this.term = properties.term;
		}
	}
}

export class TotalTypeAllEntity implements TotalTypeAll {
	total: SelectAllEntity = new SelectAllEntity();
	shown: SelectAllEntity = new SelectAllEntity();

	constructor(properties?: TotalTypeAllEntity) {
		if(properties) {
			this.total = new SelectAllEntity(properties.total);
			this.shown = new SelectAllEntity(properties.shown);
		}
	}

	reset(type: "total" | "shown") {
		this[type] = {
			...this[type],
			checked: false,
			loading: false,
			disabled: false,
		};
	}
}

export class SelectAllGeoEntity implements SelectAllGeo {
	states: TotalTypeAllEntity = new TotalTypeAllEntity();
	cities: TotalTypeAllEntity = new TotalTypeAllEntity();
	neighborhoods: TotalTypeAllEntity = new TotalTypeAllEntity();

	constructor(properties?: SelectAllGeoEntity) {
		if(properties) {
			this.states = new TotalTypeAllEntity(properties.states);
			this.cities = new TotalTypeAllEntity(properties.cities);
			this.neighborhoods = new TotalTypeAllEntity(properties.neighborhoods);
		}
	}
}

export class SelectAllPoisEntity implements SelectAllPois {
	categories: TotalTypeAllEntity = new TotalTypeAllEntity();
	subcategories: TotalTypeAllEntity = new TotalTypeAllEntity();
	brands: TotalTypeAllEntity = new TotalTypeAllEntity();
	names: TotalTypeAllEntity = new TotalTypeAllEntity();

	constructor(properties?: SelectAllPoisEntity) {
		if(properties) {
			this.categories = new TotalTypeAllEntity(properties.categories);
			this.subcategories = new TotalTypeAllEntity(properties.subcategories);
			this.brands = new TotalTypeAllEntity(properties.brands);
			this.names = new TotalTypeAllEntity(properties.names);
		}
	}
}

export class SelectAllPrivateEntity implements SelectAllPrivate {
	privates: TotalTypeAllEntity = new TotalTypeAllEntity();

	constructor(properties?: SelectAllPrivateEntity) {
		if(properties) {
			this.privates = new TotalTypeAllEntity(properties.privates);
		}
	}
}

export class ModuleSelectAllEntity implements ModuleSelectAll {
	geo: SelectAllGeo = new SelectAllGeoEntity();
	pois: SelectAllPois = new SelectAllPoisEntity();
	privates: SelectAllPrivate = new SelectAllPrivateEntity();

	constructor(properties?: ModuleSelectAllEntity) {
		if(properties) {
			this.geo = new SelectAllGeoEntity(properties.geo);
			this.pois = new SelectAllPoisEntity(properties.pois);
			this.privates = new SelectAllPrivateEntity(properties.privates);
		}
	}

	async resetByType(type: "geo" | "pois" | "privates") {
		for (const [key, value] of Object.entries(this[type])) {
			value.reset("total");
			value.reset("shown");
		}
	}

	/**
	 * Get value by {type} & {key}
	 * @param type
	 * @param name
	 * @param key
	 */
	getModuleAll(
		type: keyof ModuleSelectAll, // geo|pois
		name: keyof SelectAllGeoEntity | keyof SelectAllPoisEntity, // filters
		key: keyof TotalTypeAll, // total|shown
		property?: string
	) {
		if (isUndefined(property)) return this[type][name][key];
		return this[type][name][key][property];
	}

	setChecked(
		type: keyof ModuleSelectAll, // geo|pois
		name: keyof SelectAllGeoEntity | keyof SelectAllPoisEntity, // filters
		key: keyof TotalTypeAll, // total|shown
		data: Boolean
	) {
		// type . name . key
		//this.geo.cities.shown.checked
		this[type][name][key].checked = data;
		switch (key) {
			case TotalType.TOTAL:
				this[type][name][TotalType.SHOWN].checked = false;
				break;

			case TotalType.SHOWN:
				this[type][name][TotalType.TOTAL].checked = false;
				break;
		}
	}

	clearCheck(
		type: keyof ModuleSelectAll, // geo|pois
		name: keyof SelectAllGeoEntity | keyof SelectAllPoisEntity, // filters
		key: keyof TotalTypeAll // total|shown
	) {
		// type . name . key
		//this.geo.cities.shown.checked
		this[type][name][key].checked = false;
	}

	clearSearch(
		type: keyof ModuleSelectAll, // geo|pois
		name: keyof SelectAllGeoEntity | keyof SelectAllPoisEntity, // filters
		key: keyof TotalTypeAll // total|shown
	) {
		// type . name . key
		//this.geo.cities.shown.checked
		this[type][name][key].term = "";
	}

	clearChecked(
		type: keyof ModuleSelectAll, // geo|pois|privates
		name:
			| keyof SelectAllGeoEntity
			| keyof SelectAllPoisEntity
			| keyof SelectAllPrivateEntity // filters
	) {
		this[type][name].total.checked = false;
		this[type][name].shown.checked = false;
	}

	updateChecked(
		type: keyof ModuleSelectAll, // geo|pois
		name: keyof SelectAllGeoEntity | keyof SelectAllPoisEntity, // filters
		key: keyof TotalTypeAll, // total|shown
		checked: Boolean = false
	) {		
		this[type][name][key].checked = checked;

		switch (key) {
			case TotalType.TOTAL:
				this[type][name][TotalType.SHOWN].checked = false;
				break;

			case TotalType.SHOWN:
				this[type][name][TotalType.TOTAL].checked = false;
				break;
		}
	}
}
