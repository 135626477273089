import { Profile, Role, Account_scheme_1 } from '@/interfaces/user'
import { AxiosPost, GetData, GetErrors, GetMessage, AxiosGet } from '@/services/axios-service'
import { isEmpty, isUndefined } from 'lodash'

export const PROFILE_ROUTE = '/api/auth/me'
export const LOOK_AND_FELL_LOGIN = '/api/settings'
export const AVATAR_DEFAULT = `${process.env.VUE_APP_API_URL}/avatars/default.png`

class ProfileService {
    async profile() {
        try {
            const response = await AxiosPost(PROFILE_ROUTE, {});
            return Promise.resolve(GetData(response));

        } catch (error) {
            return Promise.reject({
                success: false,
                message: GetMessage(error),
                errors: GetErrors(error)
            });
        }
    }

    async getLookAndFeelLogin(params : { domain: string }) {
        try {
            const response = await AxiosGet(`${LOOK_AND_FELL_LOGIN}/${params.domain}`);
            return Promise.resolve(GetData(response));

        } catch (error) {
            return Promise.reject({
                success: false,
                message: GetMessage(error),
                errors: GetErrors(error)
            });
        }
    }
}

/**
 * Util: get profile
 * @param response
 */
export function ProviderProfile(response: any, account: any) {

    const profile: Profile = {} as Profile

    if (!isEmpty(response) && !isUndefined(response)) {
        profile.id = response.id
        profile.account_id = response.account_id
        profile.name = response.name
        profile.last_name = response.last_name
        profile.email = response.email
        profile.accounts = response.accounts as Account_scheme_1[]
        profile.account = response.account as Account_scheme_1
        profile.roles = response.roles as Role[]
        profile.email = response.email
        profile.avatar_file = response.avatar_file ?? AVATAR_DEFAULT
        profile.colors = account.colors
        profile.logo = account.logo
        profile.help_url = account.help_url
        profile.language = response.language
    }

    return profile
}

export default new ProfileService()
