import UserSetting, {
	UserSettingBody,
	UserSettingField,
} from "@/models/Setting";
import {
	AxiosPost,
	GetData,
	GetErrors,
	GetMessage,
} from "@/services/axios-service";

const ROUTE = require("../api/routes").USER;

class UserSettingService {
	async update(user: UserSettingBody, id: number) {
		try {
			const response = await AxiosPost(
				`${ROUTE.USER_ROUTE}/${id}`,
				prepareFormDataUpdate(user),
				true
			);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error),
			});
		}
	}
}

function prepareFormDataUpdate(user: UserSettingBody) {
	const formData = new FormData();
	formData.append(UserSettingField.NAME, user.name);
	formData.append(UserSettingField.LAST_NAME, user.last_name);
	formData.append(UserSettingField.LANGUAGE, user.language);
	if(user.avatar_file) {
		formData.append(UserSettingField.AVATAR_FILE, user.avatar_file);
	}
	formData.append(UserSettingField.ROLE_ID, user.role_id);
	return formData;
}

export default new UserSettingService();
