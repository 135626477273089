///
/// SOLO CONFIGURACIONES DE AXIOS
///

import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { HasProviderToken, ProviderToken } from "./auth-service";
import store from "@/store";
import { hasBearer, isValidToken } from "@/services/jwt-service";
import { isArray, isString } from "lodash";

import router from "@/router";
import { getTokenFromStorage, setTokenToStorage } from "./storage-service";
import { MessageTypes } from "@/interfaces/proccess";
import { prepareFileName } from "@/utils/services-global";
import { ResultData } from "@/interfaces/persons/v10/response";
import { DownloadType, EnumReportType, EnumStatus } from "@/interfaces/report";

/**
 * Set axios configuration defaults
 */
axiosDefaultsConfig(process.env.VUE_APP_API_URL);

/**
 * Get token from storage
 */
let token: string = getTokenFromStorage();

/**
 * Verify if token is valid
 */
let isValid: boolean = isValidToken(token);

/**
 * Add the token in the axios header
 */
setAuthentication(token || "");

// Add a request interceptor
axios.interceptors.request.use(
	async function (config) {
		const result = await verifyToken();
		token = result._token;
		isValid = result._isValid;
		return config;
	},
	function (error) {
		return Promise.reject(error);
	}
);

// Add a response interceptor
axios.interceptors.response.use(
	async function (response) {
		if (HasProviderToken(response)) {
			const provider = ProviderToken(response);

			isValid = isValidToken(provider);

			if (hasBearer(provider) && isValid) {
				setTokenToStorage(provider);
				setAuthentication(provider);
			}
		}

		return response;
	},
	function (error) {
		if (error.code == "ERR_NETWORK") {
			store.commit("proccess/SET_NOTIFICATION", {
				message: error.message,
				type: MessageTypes.ERROR,
				title: "",
				btn_text: "",
				details: {},
				show: true,
			});
		}
		if (error.response && error.response.status === 401) {
			setTokenToStorage("");
			store.commit("auth/SET_TOKEN", "");
			router.push({ path: "/auth/login" });
		}

		console.error("AxiosService.interceptors", { error });

		return Promise.reject(error);
	}
);

/**
 * POST
 * @param url
 * @param payload
 */
export function AxiosPost(
	url: string,
	payload: any,
	has_file: boolean = false
) {
	var headers = {
		headers: {
			"Content-Type": has_file
				? "multipart/form-data"
				: "application/json",
		},
	};
	return axios.post(url, payload, headers);
}

/**
 * GET
 * @param url
 */
export function AxiosDelete(url: string) {
	return axios.delete(url);
}

/**
 * GET
 * @param url
 */
export function AxiosGet(url: string, config?: AxiosRequestConfig<any>) {
	return axios.get(url, config);
}

/**
 * GET with data
 * @param url
 * @param data
 * @returns
 */
export function AxiosGetData(url: string, data: any) {
	const requestHeader = {
		"Content-Type": "application/json",
	};
	return axios.get(url, { params: data, headers: requestHeader });
}

/**
 * PUT
 * @param url
 * @param payload
 */
export function AxiosPut(url: string, payload: any) {
	return axios.put(url, payload);
}

/**
 * PATCH
 * @param url
 * @param payload
 */
export function AxiosPatch(url: string, payload: any) {
	return axios.patch(url, payload);
}

const getFormData = (attributes) => {
	let formData = new FormData();
	Object.keys(attributes).forEach((key, value) => {
		if (Array.isArray(attributes[key])) {
			formData.append(key, JSON.stringify(attributes[key]));
		} else {
			formData.append(key, attributes[key]);
		}
	});
	return formData;
};

export function AxiosUpload(url: string, payload: any, onUploadProgress: any) {
	const formData = getFormData(payload);
	return axios.post(url, formData, {
		onUploadProgress,
		headers: { "Content-Type": "multipart/form-data" },
	});
}

/**
 * GET
 * @param url
 */
export async function AxiosDownload(url: string, title: string) {
	const response = await axios.get(url, { responseType: "blob" });
	forceFileDownload(response, title);
}

/**
 * Download
 * POST
 * @param url
 * @param title
 * @param data
 */
export async function AxiosPostDownload(url: string, title: string, data: any, extension = "csv") {
	const response = await axios.post(url, data, { responseType: "blob" });
	forceFileDownload(response, prepareFileName(title, extension));
}

/**
 * Download
 * Get
 * @param url
 * @param title
 * @param data
 */
export async function AxiosGetDownload(url: string, title: string) {
	const response = await axios.get(url, { responseType: "blob" });
	forceFileDownload(response, prepareFileName(title));
}

/**
 * GET
 * @param url
 */
export async function ForceDownload(
	response: AxiosResponse<any>,
	title: string
) {
	forceFileDownload(response, title);
}

export async function dataForceDownload(
	response: string,
	title: string,
	type: EnumReportType
) {
	dataDownload(response, title, type);
}

function forceFileDownload(response: AxiosResponse<any>, title: string) {
	const url = window.URL.createObjectURL(new Blob([response.data]));
	const link = document.createElement("a");
	link.href = url;
	link.setAttribute("download", title);
	document.body.appendChild(link);
	link.click();
}

function dataDownload(data: string, title: string, type: EnumReportType) {
	const url = window.URL.createObjectURL(new Blob([data], { type: DownloadType[type] }));
	const link = document.createElement("a");
	link.href = url;
	link.setAttribute("download", title);
	document.body.appendChild(link);
	link.click();
}

// RESPONSE BOOLEAN CHECK //

/**
 * HasSuccess
 * @param response AxiosResponse<any>
 */
export function HasSuccess(response: AxiosResponse<any>) {
	return Boolean(
		response.status >= 200 &&
			response.status < 300 &&
			typeof response.data.success !== typeof undefined &&
			response.data.success
	);
}

/**
 * HasError
 * @param response AxiosResponse<any>
 */
export function HasError(response: AxiosResponse<any>) {
	return Boolean(
		(response.status < 200 || response.status > 300) &&
			!response.data.success
	);
}

/**
 * HasErrors
 * @param error AxiosResponse<any>
 */
export function HasErrors(error: any) {
	return Boolean(error.response?.data?.errors);
}

/**
 * HasMessage
 * @param response AxiosResponse<any>
 */
export function HasMessage(response: AxiosResponse<any>) {
	return String(response.data?.message).length > 0;
}

// GET DATA FROM RESPONSE //

/**
 * GetData
 * @param response AxiosResponse<any>
 */
export function GetData(response: AxiosResponse<any>) {
	if (isArray(response.data)) return response.data;
	return response?.data?.response;
}

/**
 * GetDataResponse
 * Se verifica si el { data } es un string | array | object
 * @param response AxiosResponse<any>
 */
export function GetDataResponse(response: AxiosResponse<any>) {
	if (isString(response.data)) return response.data;
	if (isArray(response.data)) return response.data;
	return response?.data?.response;
}

export function ForceGetData(response: AxiosResponse<any>) {
	return response.data as ResultData;
}

/**
 * GetDataError
 * @param error AxiosError<any>
 */
export function GetDataError(error: any) {
	return error.response?.data;
}

/**
 * GetErrors
 * @param error AxiosError<any>
 */
export function GetErrors(error: any) {
	return error.response?.data?.errors || {};
}

/**
 * GetMessage
 * @param response AxiosError<any>
 */
export function GetMessage(error: any) {
	return error.response?.data?.message;
}

/**
 * Set Authorization
 * @param token string
 */
export function setAuthentication(token: string = "") {
	axios.defaults.headers.common.Authorization = token;
}

export async function verifyToken() {
	let _token = getTokenFromStorage();
	let _isValid: boolean = isValidToken(_token);
	return { _token, _isValid };
}

/**
 * Set axios configuration defaults
 */
export function axiosDefaultsConfig(baseUrl: string = "") {
	axios.defaults.baseURL = baseUrl;
	axios.defaults.headers.common = {
		"Access-Control-Allow-Origin": "*",
		// Request methods you wish to allow
		"Access-Control-Allow-Methods":
			"GET, POST, OPTIONS, PUT, PATCH, DELETE",
		// Request headers you wish to allow
		"Access-Control-Allow-Headers": "Content-Type,Authorization",
		"Access-Control-Allow-Credentials": true,
		Accept: "application/json",
	};
}
