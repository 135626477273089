import { ModifierDataCreate, ModifierFilters } from "@/interfaces/modifier";
import { Paginated, SortingOption } from "@/interfaces/paginated";
import {
	AxiosGet,
	AxiosPost,
	AxiosPatch,
	GetData,
	GetErrors,
	GetMessage
} from "@/services/axios-service";
import { prepareParams, modes, downloadFile } from "@/utils/services-global";
import { isNull, isUndefined } from "lodash";

export const BID_MODIFIER_ROUTE = "/api/bid_modifiers";

const ROUTE = require("../api/routes").MODIFIERS;

const ENTITY: string = "modifier";

class ModifierService {
	async paginated(params: {
		paginated: Paginated;
		filters?: ModifierFilters;
		options?: SortingOption;
		fields?: Array<string>;
	}) {
		try {
			let filter = "";

			if (!isUndefined(params.filters)) {
				filter = getFilters(params.filters);
			}

			const url = await prepareParams({
				route: ROUTE.MODIFIER_ROUTE,
				entity: ENTITY,
				mode: modes.PAGINATED,
				filter: filter,
				...params
			});

			const response = await AxiosGet(url);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error)
			});
		}
	}

	async create(modifier: ModifierDataCreate, isBidModifier: Boolean = false, isDeliveryModifier: Boolean = false) {
		try {
			let response;

			if(isDeliveryModifier) {
				response = await AxiosPost(ROUTE.DELIVERY_MODIFIER_ROUTE, modifier)
			}
			else if (isBidModifier) {
				response = await AxiosPost(BID_MODIFIER_ROUTE, modifier);
			} else {
				response = await AxiosPost(ROUTE.MODIFIER_ROUTE, modifier);
			}
			return Promise.resolve(GetData(response));
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error)
			});
		}
	}

	async show(id: number) {
		try {
			const response = await AxiosGet(`${ROUTE.MODIFIER_ROUTE}/${id}`);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error)
			});
		}
	}

	async update(modifier: any, isBidModifier: Boolean = false, isDeliveryModifier: Boolean = false) {
		try {
			let response;
			if(isDeliveryModifier) {
				response = await AxiosPatch(
					`${ROUTE.DELIVERY_MODIFIER_ROUTE}/${modifier?.id}`,
					modifier
				)
			}
			else if (isBidModifier) {
				response = await AxiosPatch(
					`${BID_MODIFIER_ROUTE}/${modifier?.id}`,
					modifier
				);
			} else {
				response = await AxiosPatch(
					`${ROUTE.MODIFIER_ROUTE}/${modifier?.id}`,
					modifier
				);
			}
			return Promise.resolve(GetData(response));
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error)
			});
		}
	}

	async list(params: { filters?: ModifierFilters; options?: SortingOption; paginated?: Paginated; }) {
		try {
			let filter = "";

			if (!isUndefined(params.filters)) {
				filter = getFilters(params.filters);
			}

			const url = await prepareParams({
				route: ROUTE.MODIFIER_ROUTE,
				entity: ENTITY,
				mode: modes.LIST,
				filter: filter,
				...params
			});

			const response = await AxiosGet(url);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error)
			});
		}
	}

	async listDeliveryModifier(params: { 
		filters?: ModifierFilters; 
		options?: SortingOption;
		paginated?: Paginated;
	}) {
		try {
			let filter = "";

			if (!isUndefined(params.filters)) {
				filter = getFilters(params.filters);
			}

			const url = await prepareParams({
				route: ROUTE.MODIFIER_ROUTE,
				entity: ENTITY,
				mode: modes.PAGINATED,
				filter: filter,
				...params
			});

			const response = await AxiosGet(url);
			
			return Promise.resolve(GetData(response));
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error)
			});
		}
	}
	async download(params: {
		paginated?: Paginated;
		filters?: ModifierFilters;
		options?: SortingOption;
		fields: Array<string>;
	}) {
		try {
			let filter = "";

			if (!isUndefined(params.filters)) {
				filter = getFilters(params.filters);
			}

			await downloadFile({
				entity: ENTITY,
				route: ROUTE.MODIFIER_ROUTE,
				filter,
				mode: modes.DOWNLOAD,
				...params
			});

			return Promise.resolve({});
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error)
			});
		}
	}

	async typeList(params: {
		filters?: ModifierFilters;
		options?: SortingOption;
	}) {
		try {
			let filter = "";

			if (!isUndefined(params.filters)) {
				filter = getFilters(params.filters);
			}

			const url = await prepareParams({
				route: ROUTE.MODIFIER_TYPE_ROUTE,
				entity: ENTITY,
				mode: modes.LIST,
				filter: filter,
				...params
			});

			const response = await AxiosGet(url);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error)
			});
		}
	}

	async moduleList(params: {
		filters?: ModifierFilters;
		options?: SortingOption;
	}) {
		try {
			let filter = "";

			if (!isUndefined(params.filters)) {
				filter = getFilters(params.filters);
			}

			const url = await prepareParams({
				route: ROUTE.MODIFIER_MODULE_ROUTE,
				entity: ENTITY,
				mode: modes.LIST,
				filter: filter,
				...params
			});

			const response = await AxiosGet(url);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error)
			});
		}
	}

	async moduleOption() {
		try {
			const response = await AxiosGet(`${ROUTE.MODULE_MODIFIER}`);
			return Promise.resolve(response.data);
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error)
			});
		}
	}

	async deliveryModifiersType(){
		try {
			let response = [
				{
					id: "101",
					value: "Width Term"
				}
			];
			return Promise.resolve(response);
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error)
			});
		}
	}
}

function getFilters(filters: ModifierFilters): string {
	let filter = "";

	const id = !!filters.id ? filters.id : "";
	const external_id = !!filters.external_id ? filters.external_id : "";
	const name = !!filters.name ? filters.name : "";
	const alternative_id = !!filters.alternative_id ? filters.alternative_id : "";
	const advertiser_id = !!filters.advertiser_id ? filters.advertiser_id : "";
	const modifier_type_id = !!filters.modifier_type_id ? filters.modifier_type_id : "";
	const active =
		isUndefined(filters.active) || isNull(filters.active)
			? ""
			: !!filters.active ? 1 : 0;
	const updated_at = !!filters.updated_at ? filters.updated_at : "";
	const modifier_name = !!filters.modifier_name ? filters.modifier_name : "";
	const modifier_type = !!filters.type_description ? filters.type_description : "";
	const advertiser_name = !!filters.advertiser_name ? filters.advertiser_name : "";
	const modifier_type_extra = !!filters.modifier_type_extra ? filters.modifier_type_extra : "";

	var symbol = "";

	if (external_id) {
		filter += `${symbol}filters[external_id]=${external_id}`;
		symbol = "&";
	}

	if (name) {
		filter += `${symbol}filters[name]=${name}`;
		symbol = "&";
	}

	if (alternative_id) {
		filter += `${symbol}filters[alternative_id]=${alternative_id}`;
		symbol = "&";
	}

	if (advertiser_id) {
		filter += `${symbol}filters[advertiser_id]=${advertiser_id}`;
		symbol = "&";
	}

	if (modifier_type_id) {
		filter += `${symbol}filters[modifier_type_id]=${modifier_type_id}`;
		symbol = "&";
	}

	if (updated_at) {
		filter += `${symbol}filters[updated_at]=${updated_at}`;
		symbol = "&";
	}

	if (modifier_name) {
		filter += `${symbol}filters[modifiers.name]=${modifier_name}`;
		symbol = "&";
	}

	if (modifier_type) {
		filter += `${symbol}filters[modifiers.description]=${modifier_type }`;
		symbol = "&";
	}


	if (modifier_type_extra) {
		filter += `${symbol}filters[modifier_type.extra]=${modifier_type_extra}`;
		symbol = "&";
	}
	if (advertiser_name) {
		filter += `${symbol}filters[advertisers.name]=${advertiser_name}`;
		symbol = "&";
	}

	if (active) {
		filter += `${symbol}filters[active]=${active}`;
		symbol = "&";
	}

	if (id) {
		filter += `${symbol}filters[id]=${id}`;
		symbol = "&";
	}

	return filter;
}

export default new ModifierService();
