import { BulkCampaign, BulkLine } from "@/interfaces/bulk";
import {
	AxiosGet,
	AxiosPut,
	GetErrors,
	GetMessage,
} from "@/services/axios-service";

const creativeBulkTypes: string = "/api/list/creative_bulk_rules";
const creativeBulkTemplate: string = "/api/list/creative_bulk_templates";

class BulkService {
	async activeBulk(
		entity: string,
		data: BulkLine | BulkCampaign,
		active: Boolean
	) {
		try {
			const extractEntity = matchedEntityParam[entity];

			let prepareParams = {};
			prepareParams[extractEntity.param] = data;

			const response = await AxiosPut(
				`/api/${extractEntity.path}/bulk_set/${active ? 1 : 0}`,
				prepareParams
			);

			return Promise.resolve(response);
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error),
			});
		}
	}

	async getResource(key: string) {
		try {
			const response = await AxiosGet(matchedKeyPath[key]);
			return Promise.resolve(response);
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error),
			});
		}
	}
}

/**
 * Match: Nombre de la ruta => { path, param }
 */
export const matchedEntityParam = {
	LineItemList: {
		path: "line_items",
		param: "lines",
	},
	CampaignsIndex: {
		path: "campaigns",
		param: "campaigns",
	},
};

const matchedKeyPath: any = {
	creative_template_id: creativeBulkTemplate,
	creative_rule_id: creativeBulkTypes
};

export default new BulkService();
