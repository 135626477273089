import { LineItem, LineItemDataCreate, GraphicBidWin } from "@/interfaces/line_item";
import { ResultPaginate } from "@/interfaces/paginated";
import LineItemService from "@/services/line-item-service";
import CampaignService from "@/services/campaign-service";
import BulkService from "@/services/bulk-service";
import notificationService from "@/services/notification-service";
import { MessageTypes, Notification } from "@/interfaces/proccess";
import { CatcherError, catchError, CreateNotification } from "@/store/Util";
import { resolveList } from "@/utils/resolveObjectArray";
import i18n from "@/plugins/i18n";
import { BulkLine } from "@/interfaces/bulk";
import { orderBy } from "lodash";

export const LineItemModule = {
	namespaced: true,
	state: () => ({
		result_paginate: {} as ResultPaginate,
		result_pag_asso_creative: {} as ResultPaginate,
		lineItems: [] as LineItem[],
		lineItem: {} as LineItem,
		lineItem_list: [] as LineItem[],
	}),
	mutations: {
		SET_RESULT_PAGINATED(
			state,
			_result_paginate: ResultPaginate = {} as ResultPaginate
		) {
			state.result_paginate = _result_paginate;
		},
		SET_RESULT_PAG_ASSOC_CREATIVE(
			state,
			_result_pag_asso_creative: ResultPaginate = {} as ResultPaginate
		) {
			state.result_pag_asso_creative = _result_pag_asso_creative;
		},
		SET_LINE_ITEM(state, _lineItem: LineItem = {} as LineItem) {
			state.lineItem = _lineItem;
		},
		SET_LINE_ITEMS(state, _lineItems: LineItem[] = [] as LineItem[]) {
			state.lineItems = _lineItems;
		},
		SET_LINE_ITEM_LIST(
			state,
			_line_item_list: LineItem[] = [] as LineItem[]
		) {
			state.lineItem_list = _line_item_list;
		},
	},
	getters: {
		result_paginate(state): ResultPaginate {
			return state.result_paginate;
		},
		result_pag_assoc_creative(state): ResultPaginate {
			return state.result_pag_asso_creative;
		},
		getLineItem(state): LineItem{
			return state.lineItem;
		}
	},
	actions: {
		async create(
			{},
			params: { data: LineItemDataCreate; continue: Boolean }
		) {
			try {
				const response = await LineItemService.create(params.data);

				notificationService.notifySuccess(this, {
					to: params.continue ? "" : "LineItemList",
				} as Notification);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async show({ commit }, id: number) {
			try {
				let response = await LineItemService.show(id);
				if (response?.campaign?.id) {
					const aux_campaign = await CampaignService.show(
						response?.campaign?.id
					);
					response.campaign = aux_campaign;
				}
				commit("SET_LINE_ITEM", response);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error, { to: "LineItemList" });
				return await Promise.reject(error);
			}
		},
		async update(
			{},
			params: {
				data: LineItemDataCreate;
				id: number;
				continue: Boolean;
			}
		) {
			try {
				const response = await LineItemService.update(
					params.data,
					params.id
				);
				notificationService.notifySuccess(this, {
					to: params.continue ? "" : "LineItemList",
				} as Notification);
				return await Promise.resolve(response);
			} catch (error) {
				CatcherError(this.dispatch, error, {
					to: "",
				});
				return await Promise.reject(error);
			}
		},
		async paginated({ commit }, params) {
			try {
				const response = await LineItemService.paginated(params);
				commit("SET_RESULT_PAGINATED", response);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async download({}, params) {
			try {
				await LineItemService.download(params);
				return await Promise.resolve({});
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async list({ commit }, params) {
			try {
				const response = await LineItemService.list(params);
				commit("SET_LINE_ITEM_LIST", resolveList(response));
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async all({ commit }, params) {
			try {
				const response = await LineItemService.all(params);
				commit("SET_LINE_ITEMS", response);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async activate({}, id: number) {
			try {
				let response = await LineItemService.changeStatus({
					id: id,
					active: true,
				});
				CreateNotification(this.dispatch, {
					type: MessageTypes.SUCCESS,
					title: i18n.t("title-success"),
					message: i18n.t("success"),
					btn_text: i18n.t("close"),
					to: "LineItemList",
				} as Notification);
				return await Promise.resolve(response);
			} catch (error) {
				CatcherError(this.dispatch, error, { to: "" });
				return await Promise.reject(error);
			}
		},

		async setActive({ commit }, params: { id: number; active: Boolean }) {
			try {
				const response = await LineItemService.setActive(
					params.id,
					params.active
				);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error, { to: "" });
				return await Promise.reject(error);
			}
		},

		async getDataById({ commit }, id: number) {
			try {
				let response = await LineItemService.show(id);
				commit("SET_LINE_ITEM", response);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error, { to: "" });
				return await Promise.reject(error);
			}
		},

		async createAssociationWithModifier(
			{ commit },
			params: { entity: any }
		) {
			try {
				const response = await LineItemService.createAssociation(
					params.entity
				);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		
		async activeBulk({ commit }: any, params: { data: BulkLine; active: Boolean; }) {
			try {
				const response = await BulkService.activeBulk(
					"line_items",
					params.data,
					params.active
				);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async listCreativeAssociated({}, params) {
			try {
				const response = await LineItemService.getCreativesAssociated(params);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async dataGraphicBidWin({}, params: GraphicBidWin) {
			try {
				const response = await LineItemService.getDataGraphicBidWin(params);
				return await Promise.resolve(orderBy(response, "hour", "desc"));
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async periodGraphicBidWin({}) {
			try {
				const response = await LineItemService.getPeriodGraphicBidWin();
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async paginatedAssocCreative({ commit }, params) {
			try {
				const response = await LineItemService.paginatedAssoCreative(params);
				commit("SET_RESULT_PAG_ASSOC_CREATIVE", response);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		
		async setLine({ commit }, params: any) {
			try {
				commit("SET_LINE_ITEM", params);
			} catch (error) {
				catchError(this, error, { to: "LineItemList" });
				return await Promise.reject(error);
			}
		}
	},
};
