
import Vue from "vue";
//@ts-ignore
import { debounce } from "lodash";
//@ts-ignore
import { SortingOption } from "@/interfaces/paginated";
//@ts-ignore
import CardSwitch from "@/components/Content/CardSwitch.vue";

export default Vue.extend({
	name: "ActiveFieldTable",
	props: {},
	components: {
        CardSwitch
    },
	data: () => ({
        status: false,
    }),
	created() {},
	mounted() {
        this.load();
    },
	computed: {

		getFieldFilter(){
            return this.params.colDef.field;
        },

		getFieldName(){
            return this.params.colDef.headerName;
        },

        isEventActive(){
            return this.params.eventActive;
        },

        isFlagText(){
            return this.params.flagText;
        },

        isReadOnly(){
            return this.params.readonly;
        }
	},
	methods: {

        load(){
            let option = String(this.params.field).toUpperCase();
            switch(option){
                case "ACTIVE":
                    this.status = this.params.data.active;
                    break;
                case "APPNEXUS":
                    this.status = this.params.data.appnexus_submit;
                    break;
                case "STATUS":
                    if(this.isFlagText){
                        this.status = this.params.data.status == "active" ? true : false;
                    }else{
                        this.status = this.params.data.status;
                    }
                    break;
                default:
                    this.status = false;
            }
        },

		getColor(active: Boolean) {
			return active ? "text-bold green--text" : "text-bold red--text";
		},

		getActiveText(active: Boolean) {
			return active ? "Active" : "Inactive";
		},

        invokeParentActiveMethod() {    
            if(this.isReadOnly) return false;
            let param = {
                id: this.params.data.id,
                active: this.status,
                rowIndex: this.params.rowIndex
            }
            this.params.contextT.componentParent.handleActive(param);
        },
	},
	watch: {
	},
});
