import { isNumber, isUndefined, String } from "lodash";

export function convDigitFormatter(num: number, digits: number = 3) {
	var si = [
		{ value: 1, symbol: "" },
		{ value: 1e3, symbol: "k" },
		{ value: 1e6, symbol: "M" },
		{ value: 1e9, symbol: "G" },
		{ value: 1e12, symbol: "T" },
		{ value: 1e15, symbol: "P" },
		{ value: 1e18, symbol: "E" },
	];

	var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;

	var i: number;

	for (i = si.length - 1; i > 0; i--) {
		if (num >= si[i].value) {
			break;
		}
	}

	// const obt = si.find(s => s.value >= num);

	const formatted = convLocaleString(num / si[i].value);

	return formatted.replace(rx, "$1") + si[i].symbol;
	//return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
}

export function convLocaleString(val: number | null | undefined) {
	if (!isNumber(val)) return "0";
	return val.toLocaleString("en");
}

/**
 * Generate hash
 * @returns
 */
export function generateHash() {
	return new Date().getUTCMilliseconds();
}

/**
 * Generate ramdom hash
 * @param radix — Specifies a radix for converting numeric values to strings.
 * This value is only used for numbers.
 * @returns
 */
export async function getHash(radix: number = 36) {
	return (
		Math.random().toString(radix).substring(2, 36) +
		Math.random().toString(radix).substring(2, 36)
	)
		.match(/.{1,8}/g)
		?.reverse()
		.join("-");
}

/**
 * Converts a date to a string by using the current or specified locale.
 * @returns
 */
export function generateDateFormat() {
	return new Date().toLocaleDateString();
}

/**
 * Get Random Int Inclusive.
 * The maximum is inclusive and the minimum is inclusive.
 * @param min
 * @param max
 * @returns
 */
export function getRandomIntInclusive(min: number, max: number) {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min + 1) + min);
}

/**
 * sleep time expects milliseconds
 * @param time
 * @returns
 */
export async function sleep(time: number) {
	return new Promise((resolve) => setTimeout(resolve, time));
}

export function getRandomDate() {
	const maxDate = Date.now();
	const timestamp = Math.floor(Math.random() * maxDate);
	return new Date(timestamp);
}

export function addColorAlpha(color: string, opacity: number = 1): string {
	// coerce values so ti is between 0 and 1.
	const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
	return color + _opacity.toString(16).toUpperCase();
}

/**
 * Get color (black/white) depending on bgColor so it would be clearly seen.
 * @param bgColor 
 * @returns 
 */
export function getColorByBgColor(bgColor: string) {
	if (!bgColor) return "#000000";
	return parseInt(bgColor.replace("#", ""), 16) > 0xffffff / 2
		? "#000000"
		: "#ffffff";
}
