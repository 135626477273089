import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import i18n from "@/plugins/i18n";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import moment, { isMoment, Moment } from "moment-timezone";
import VueMasonry from 'vue-masonry-css'
import * as VueGoogleMaps from 'vue2-google-maps'

import VCalendar from "v-calendar";
import { isDate } from "date-fns";
import {
	DEFAULT_MOMENT_SHORT_FORMAT,
	DEFAULT_MOMENT_SQL_FORMAT,
	DEFAULT_MOMENT_YEAR_FORMAT,
	fnsDateTimeSQLFormat,
	fnsFormat,
	fnsYearFormat,
	storeLanguage,
} from "./services/date-service";

// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
	componentPrefix: "vc", // Use <vc-calendar /> instead of <v-calendar />
	firstDayOfWeek: 1,
	titlePosition: "left",
	datePickerShowCaps: true,
});

Vue.use(VueGoogleMaps, {
	load: {
		key: process.env.VUE_APP_GMAP_KEY || ""
	}
})

Vue.use(VueMasonry);

moment.locale(storeLanguage);

//Esto para hacer los test
//import test_api from './api/test_methods_ts'
//test_api
//test_api.data()

Vue.config.productionTip = false;

Vue.config.warnHandler = function (msg, vm, trace) {
	console.log(`Warn: ${msg}\nTrace: ${trace}`);
};

Vue.prototype.moment = moment;

Vue.filter("toSqlFormat", function (value: Moment | Date) {
	if (value) {
		if (isMoment(value)) {
			return moment(value).format(DEFAULT_MOMENT_SQL_FORMAT);
		}
		if (isDate(value)) {
			return fnsDateTimeSQLFormat(value);
		}
	}
});

Vue.filter("readFormat", function (value: Date) {
	if (value) {
		if (isMoment(value)) {
			return moment(value).format(DEFAULT_MOMENT_SHORT_FORMAT);
		}
		if (isDate(value)) {
			return fnsFormat(value);
		}
	}
});

Vue.filter("yearFormat", function (value: Date) {
	if (value) {
		if (isMoment(value)) {
			return moment(value).format(DEFAULT_MOMENT_YEAR_FORMAT);
		}
		if (isDate(value)) {
			return fnsYearFormat(value);
		}
	}
});

Vue.directive("numeric", {
	bind(el, binding, vnode) {
		el.addEventListener("keydown", (e) => {
			if (
				[46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
				// Allow: Ctrl+A
				(e.keyCode === 65 && e.ctrlKey === true) ||
				// Allow: Ctrl+C
				(e.keyCode === 67 && e.ctrlKey === true) ||
				// Allow: Ctrl+X
				(e.keyCode === 88 && e.ctrlKey === true) ||
				// Allow: home, end, left, right
				(e.keyCode >= 35 && e.keyCode <= 39)
			) {
				// let it happen, don't do anything
				return;
			}
			// Ensure that it is a number and stop the keypress
			if (
				(e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
				(e.keyCode < 96 || e.keyCode > 105)
			) {
				e.preventDefault();
			}
		});
	},
});

Vue.filter("decimal", function(value: number | bigint, decimal = 0) {
	if (typeof value !== "number") {
		return value;
	}
	var formatter = new Intl.NumberFormat("en-US", {
		style: "decimal",
		minimumFractionDigits: decimal
	});
	return formatter.format(value);
});

new Vue({
	i18n,
	router,
	store,
	vuetify,
	render: (h) => h(App),
}).$mount("#app");
