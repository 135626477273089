import { 
	LineItemDataCreate, 
	LineItemFilters, 
	GraphicBidWin,
	LineItemFiltersAssoc
} from "@/interfaces/line_item";
import { Paginated, SortingOption } from "@/interfaces/paginated";
import {
	AxiosGet,
	AxiosPost,
	AxiosPatch,
	GetData,
	GetErrors,
	GetMessage,
	AxiosPut,
} from "@/services/axios-service";
import { prepareParams, modes, downloadFile } from "@/utils/services-global";
import { isNull, isUndefined } from "lodash";

const ROUTE = require("../api/routes").LINE_ITEMS;

const ENTITY: string = "line_item";

class LineItemService {
	async paginated(params: {
		paginated?: Paginated;
		filters?: LineItemFilters;
		options?: SortingOption;
		fields?: Array<string>;
	}) {
		try {
			let filter = "";

			if (!isUndefined(params.filters)) {
				filter = getFilters(params.filters);
			}

			const url = await prepareParams({
				route: ROUTE.LINE_ITEMS_ROUTE,
				entity: ENTITY,
				mode: modes.PAGINATED,
				filter: filter,
				...params,
			});

			const response = await AxiosGet(url);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error),
			});
		}
	}

	async paginatedAssoCreative(params: {
		paginated?: Paginated;
		filters?: LineItemFiltersAssoc;
		options?: SortingOption;
		fields?: Array<string>;
	}) {
		try {
			
			let filter = "";

			if (!isUndefined(params.filters)) {
				filter = getFiltersAssoc(params.filters);
			}
			
			const url = await prepareParams({
				route: ROUTE.CREATIVE_LINE_ASSOCIATIONS_ROUTE,
				entity: ENTITY,
				mode: modes.PAGINATED,
				filter: filter,
				...params,
			});

			const response = await AxiosGet(url);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error),
			});
		}
	}

	async download(params: {
		paginated?: Paginated;
		filters?: LineItemFilters;
		options?: SortingOption;
		fields: Array<string>;
	}) {
		try {
			let filter = "";

			if (!isUndefined(params.filters)) {
				filter = getFilters(params.filters);
			}

			await downloadFile({
				entity: ENTITY,
				route: ROUTE.LINE_ITEMS_ROUTE,
				filter,
				mode: modes.DOWNLOAD,
				...params,
			});

			return Promise.resolve({});
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error),
			});
		}
	}

	async create(lineItem: LineItemDataCreate) {
		try {
			const response = await AxiosPost(ROUTE.LINE_ITEMS_ROUTE, lineItem);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error),
			});
		}
	}

	async show(id: number) {
		try {
			const response = await AxiosGet(`${ROUTE.LINE_ITEMS_ROUTE}/${id}`);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error),
			});
		}
	}

	async update(lineItem: any, id: number) {
		try {
			const response = await AxiosPatch(
				`${ROUTE.LINE_ITEMS_ROUTE}/${id}`,
				lineItem
			);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error),
			});
		}
	}

	async list(params: { filters?: LineItemFilters; options?: SortingOption }) {
		try {
			let filter = "";

			if (!isUndefined(params.filters)) {
				filter = getFilters(params.filters);
			}

			const url = await prepareParams({
				route: ROUTE.LINE_ITEMS_ROUTE,
				entity: ENTITY,
				mode: modes.LIST,
				filter: filter,
				...params,
			});

			const response = await AxiosGet(url);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error),
			});
		}
	}

	async all(params: {
		filters?: LineItemFilters;
		options?: SortingOption;
		paginated?: Paginated;
	}) {
		try {
			let filter = "";

			if (!isUndefined(params.filters)) {
				filter = getFilters(params.filters);
			}

			const url = await prepareParams({
				route: ROUTE.LINE_ITEMS_ROUTE,
				entity: ENTITY,
				paginated: params.paginated,
				fields: [],
				mode: modes.ALL,
				filter: filter,
				options: params.options,
			});

			const response = await AxiosGet(url);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error),
			});
		}
	}

	async changeStatus(params: { id: number; active: Boolean }) {
		try {
			const response = await AxiosPut(
				`${ROUTE.LINE_ITEMS_ROUTE}/${params.id}/set/${
					params.active ? 1 : 0
				}`,
				{}
			);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error),
			});
		}
	}

	async setActive(id: Number, active: Boolean) {
		try {
			const response = await AxiosPut(
				`${ROUTE.LINE_ITEMS_ROUTE}/${id}/set/${active ? 1 : 0}`,
				{}
			);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error),
			});
		}
	}

	async createAssociation(entity: any) {
		try {
			const response = await AxiosPost(
				ROUTE.LINE_ITEMS_MODIFIER_ASSOCIATION_ROUTE,
				entity
			);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error),
			});
		}
	}

	async getCreativesAssociated(idCreative: Number) {
		try {
			const response = await AxiosGet(`${ROUTE.CREATIVE_ASSOCIATION_ROUTE}/${idCreative}/lines`);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error),
			});
		}
	}

	async getDataGraphicBidWin(params: GraphicBidWin) {
		try {
			
			let body = getDataJsonBidAndWin(params);
			const response = await AxiosPost(
				`${ROUTE.LINE_ITEM_BID_WIN_ROUTE}/${params.id}`,
				body
			);
			return Promise.resolve(GetData(response));
			/*
			const response = [
				{
					"hour": "2023-07-05 22:00:00",
					"Account_ID": 243,
					"Advertiser_ID": 2668,
					"Campaign_ID": 19788,
					"Line_Item_ID": 41489,
					"Bids": 969,
					"Win_Rate": 748,
					"Clicks": 2
				},
				{
					"hour": "2023-07-05 23:00:00",
					"Account_ID": 243,
					"Advertiser_ID": 2668,
					"Campaign_ID": 19788,
					"Line_Item_ID": 41489,
					"Bids": 1028,
					"Win_Rate": 759,
					"Clicks": 3
				},
				{
					"hour": "2023-07-06 00:00:00",
					"Account_ID": 243,
					"Advertiser_ID": 2668,
					"Campaign_ID": 19788,
					"Line_Item_ID": 41489,
					"Bids": 985,
					"Win_Rate": 751,
					"Clicks": 3
				},
				{
					"hour": "2023-07-06 01:00:00",
					"Account_ID": 243,
					"Advertiser_ID": 2668,
					"Campaign_ID": 19788,
					"Line_Item_ID": 41489,
					"Bids": 575,
					"Win_Rate": 434,
					"Clicks": 4
				},
				{
					"hour": "2023-07-06 04:00:00",
					"Account_ID": 243,
					"Advertiser_ID": 2668,
					"Campaign_ID": 19788,
					"Line_Item_ID": 41489,
					"Bids": 3941,
					"Win_Rate": 2579,
					"Clicks": 9
				},
				{
					"hour": "2023-07-06 05:00:00",
					"Account_ID": 243,
					"Advertiser_ID": 2668,
					"Campaign_ID": 19788,
					"Line_Item_ID": 41489,
					"Bids": 1088,
					"Win_Rate": 752,
					"Clicks": 1
				},
				{
					"hour": "2023-07-06 06:00:00",
					"Account_ID": 243,
					"Advertiser_ID": 2668,
					"Campaign_ID": 19788,
					"Line_Item_ID": 41489,
					"Bids": 949,
					"Win_Rate": 755,
					"Clicks": 1
				},
				{
					"hour": "2023-07-06 07:00:00",
					"Account_ID": 243,
					"Advertiser_ID": 2668,
					"Campaign_ID": 19788,
					"Line_Item_ID": 41489,
					"Bids": 976,
					"Win_Rate": 751,
					"Clicks": 1
				},
				{
					"hour": "2023-07-06 08:00:00",
					"Account_ID": 243,
					"Advertiser_ID": 2668,
					"Campaign_ID": 19788,
					"Line_Item_ID": 41489,
					"Bids": 949,
					"Win_Rate": 532,
					"Clicks": 0
				},
				{
					"hour": "2023-07-06 09:00:00",
					"Account_ID": 243,
					"Advertiser_ID": 2668,
					"Campaign_ID": 19788,
					"Line_Item_ID": 41489,
					"Bids": 443,
					"Win_Rate": 0,
					"Clicks": 0
				},
				{
					"hour": "2023-07-06 14:00:00",
					"Account_ID": 243,
					"Advertiser_ID": 2668,
					"Campaign_ID": 19788,
					"Line_Item_ID": 41489,
					"Bids": 443,
					"Win_Rate": 0,
					"Clicks": 0
				}
			];*/
		
			//return response;
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error),
			});
		}
	}

	async getPeriodGraphicBidWin() {
		try {
			const response = [
				{
					"id": "1",
					"value": "3 horas",
					"hora": "3"
				},
				{
					"id": "2",
					"value": "6 horas",
					"hora": "6"
				},
				{
					"id": "3",
					"value": "12 horas",
					"hora": "12"
				},
				{
					"id": "4",
					"value": "24 horas",
					"hora": "24"
				},
				{
					"id": "5",
					"value": "48 horas",
					"hora": "48"
				},
				{
					"id": "6",
					"value": "72 horas",
					"hora": "72"
				},
			];
		 
			return response;
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error),
			});
		}
	}
}

function getDataJsonBidAndWin(params: any){
	return {
		"start_date": params.start_date,
        "end_date": params.end_date
	}
}

/**
 * @param {LineItemFilters} filters The values for filters
 * @return {string} Returns string with filters, else empty
 */
function getFilters(filters: LineItemFilters): string {
	let filter = "";

	const name =
		isUndefined(filters.name) || isNull(filters.name) ? "" : filters.name;
	const advertiser_name =
		isUndefined(filters.advertiser_name) || isNull(filters.advertiser_name)
			? ""
			: filters.advertiser_name;
	const advertiser_id =
		isUndefined(filters.advertiser_id) || isNull(filters.advertiser_id)
			? ""
			: filters.advertiser_id;

	const alternative_id =
		isUndefined(filters.alternative_id) || isNull(filters.alternative_id)
			? ""
			: filters.alternative_id;

	const campaign_id =
		isUndefined(filters.campaign?.id) || isNull(filters.campaign?.id)
			? ""
			: filters.campaign?.id;
	const budget_type_description =
		isUndefined(filters.budget_type?.description) ||
		isNull(filters.budget_type?.description)
			? ""
			: filters.budget_type?.description;
	const bid_strategy_description =
		isUndefined(filters.bid_strategy?.description) ||
		isNull(filters.bid_strategy?.description)
			? ""
			: filters.bid_strategy?.description;
	const strategy_description =
		isUndefined(filters.strategy?.description) ||
		isNull(filters.strategy?.description)
			? ""
			: filters.strategy?.description;
	const line_pacing_description =
		isUndefined(filters.line_pacing?.description) ||
		isNull(filters.line_pacing?.description)
			? ""
			: filters.line_pacing?.description;
	const bid_shading_description =
		isUndefined(filters.bid_shading?.description) ||
		isNull(filters.bid_shading?.description)
			? ""
			: filters.bid_shading?.description;
	const creative_method_description =
		isUndefined(filters.creative_method?.description) ||
		isNull(filters.creative_method?.description)
			? ""
			: filters.creative_method?.description;
	const creative_id =
		isUndefined(filters.creative_id) || isNull(filters.creative_id)
			? ""
			: filters.creative_id;

	const id = isUndefined(filters.id) || isNull(filters.id) ? "" : filters.id;

	const external_id =
		isUndefined(filters.external_id) || isNull(filters.external_id)
			? ""
			: filters.external_id;

	const active =
		isUndefined(filters.active) || isNull(filters.active)
			? ""
			: !!filters.active ? 1 : 0;

	const budget =
		isUndefined(filters.budget) || isNull(filters.budget)
			? ""
			: filters.budget;

	const daily_budget =
		isUndefined(filters.daily_budget) || isNull(filters.daily_budget)
			? ""
			: filters.daily_budget;

	const start_date =
		isUndefined(filters.start_date) || isNull(filters.start_date)
			? ""
			: filters.start_date;

	const end_date =
		isUndefined(filters.end_date) || isNull(filters.end_date)
			? ""
			: filters.end_date;

	const line_item_type_name =
		isUndefined(filters.line_item_type_name) ||
		isNull(filters.line_item_type_name)
			? ""
			: filters.line_item_type_name;

	const creative_associations_count =
		isUndefined(filters.creative_associations_count) ||
		isNull(filters.creative_associations_count)
			? ""
			: filters.creative_associations_count;

	const campaign_name =
		isUndefined(filters.campaign_name) || isNull(filters.campaign_name)
			? ""
			: filters.campaign_name;

	const winning_status =
		isUndefined(filters.winning_status) || isNull(filters.winning_status)
			? ""
			: filters.winning_status;

	const bidding_status =
		isUndefined(filters.bidding_status) || isNull(filters.bidding_status)
			? ""
			: filters.bidding_status;

	const clicks =
		isUndefined(filters.clicks) || isNull(filters.clicks)
			? ""
			: filters.clicks;

	const cpc =
		isUndefined(filters.cpc) || isNull(filters.cpc) ? "" : filters.cpc;

	const cpm =
		isUndefined(filters.cpm) || isNull(filters.cpm) ? "" : filters.cpm;

	const ctr =
		isUndefined(filters.ctr) || isNull(filters.ctr) ? "" : filters.ctr;

	const impression =
		isUndefined(filters.impression) || isNull(filters.impression)
			? ""
			: filters.impression;

	const spend =
		isUndefined(filters.spend) || isNull(filters.spend)
			? ""
			: filters.spend;

	const video_complete_percent =
		isUndefined(filters.video_complete_percent) ||
		isNull(filters.video_complete_percent)
			? ""
			: filters.video_complete_percent;

	const video_completes =
		isUndefined(filters.video_completes) || isNull(filters.video_completes)
			? ""
			: filters.video_completes;

	var symbol = "";

	if (id) {
		filter += `${symbol}filters[id]=${id}`;
		symbol = "&";
	}

	if (external_id) {
		filter += `${symbol}filters[external_id]=${external_id}`;
		symbol = "&";
	}

	if (name) {
		filter += `${symbol}filters[name]=${name}`;
		symbol = "&";
	}

	if (active) {
		filter += `${symbol}filters[active]=${active}`;
		symbol = "&";
	}

	if (advertiser_name) {
		filter += `${symbol}filters[advertiser.name]=${advertiser_name}`;
		symbol = "&";
	}

	if (budget) {
		filter += `${symbol}filters[budget]=${budget}`;
		symbol = "&";
	}

	if (daily_budget) {
		filter += `${symbol}filters[daily_budget]=${daily_budget}`;
		symbol = "&";
	}

	if (start_date) {
		filter += `${symbol}filters[start_date]=${start_date}`;
		symbol = "&";
	}

	if (end_date) {
		filter += `${symbol}filters[end_date]=${end_date}`;
		symbol = "&";
	}

	if (line_item_type_name) {
		filter += `${symbol}filters[line_item_type.description]=${line_item_type_name}`;
		symbol = "&";
	}

	if (creative_associations_count) {
		filter += `${symbol}filters[creative_associations_count]=${creative_associations_count}`;
		symbol = "&";
	}

	if (alternative_id) {
		filter += `${symbol}filters[alternative_id]=${alternative_id}`;
		symbol = "&";
	}

	if (advertiser_id) {
		filter += `${symbol}filters[advertiser_id]=${advertiser_id}`;
		symbol = "&";
	}

	if (campaign_name) {
		filter += `${symbol}filters[campaign.name]=${campaign_name}`;
		symbol = "&";
	}

	if (campaign_id) {
		filter += `${symbol}filters[campaign_id]=${campaign_id}`;
		symbol = "&";
	}

	if (budget_type_description) {
		filter += `${symbol}filters[budget_type.description]=${budget_type_description}`;
		symbol = "&";
	}

	if (bid_strategy_description) {
		filter += `${symbol}filters[bid_strategy.description]=${bid_strategy_description}`;
		symbol = "&";
	}

	if (strategy_description) {
		filter += `${symbol}filters[strategy.description]=${strategy_description}`;
		symbol = "&";
	}

	if (line_pacing_description) {
		filter += `${symbol}filters[line_pacing.description]=${line_pacing_description}`;
		symbol = "&";
	}

	if (bid_shading_description) {
		filter += `${symbol}filters[bid_shading.description]=${bid_shading_description}`;
		symbol = "&";
	}

	if (creative_method_description) {
		filter += `${symbol}filters[creative_method.description]=${creative_method_description}`;
		symbol = "&";
	}

	if (creative_id) {
		filter += `${symbol}filters[creative_id]=${creative_id}`;
		symbol = "&";
	}

	if (winning_status) {
		filter += `${symbol}filters[metrics.winning_status]=${winning_status}`;
		symbol = "&";
	}

	if (bidding_status) {
		filter += `${symbol}filters[metrics.bidding_status]=${bidding_status}`;
		symbol = "&";
	}

	if (clicks) {
		filter += `${symbol}filters[metrics.clicks]=${clicks}`;
		symbol = "&";
	}

	if (cpc) {
		filter += `${symbol}filters[metrics.cpc]=${cpc}`;
		symbol = "&";
	}

	if (cpm) {
		filter += `${symbol}filters[metrics.cpm]=${cpm}`;
		symbol = "&";
	}

	if (ctr) {
		filter += `${symbol}filters[metrics.ctr]=${ctr}`;
		symbol = "&";
	}

	if (impression) {
		filter += `${symbol}filters[metrics.impression]=${impression}`;
		symbol = "&";
	}

	if (spend) {
		filter += `${symbol}filters[spend]=${spend}`;
		symbol = "&";
	}

	if (video_complete_percent) {
		filter += `${symbol}filters[metrics.video_complete_percent]=${video_complete_percent}`;
		symbol = "&";
	}

	if (video_completes) {
		filter += `${symbol}filters[metrics.video_completes]=${video_completes}`;
		symbol = "&";
	}

	return filter;
}

/**
 * @param {LineItemFiltersAssoc} filters The values for filters
 * @return {string} Returns string with filters, else empty
 */
function getFiltersAssoc(filters: LineItemFiltersAssoc): string {
	let filter = "";

	const line_item_id = 
		isUndefined(filters.line_item_id) || 
		isNull(filters.line_item_id) 
		? "" : filters.line_item_id;

	const id = 
		isUndefined(filters.id) || 
		isNull(filters.id) 
		? "" : filters.id;
	const creative_external_id = 
		isUndefined(filters.creative_external_id) || 
		isNull(filters.creative_external_id) 
		? "" : filters.creative_external_id;
	const creativeName = 
		isUndefined(filters.creative_name) || 
		isNull(filters.creative_name) 
		? "" : filters.creative_name;
	const creativeSize = 
		isUndefined(filters.creative_size) || 
		isNull(filters.creative_size) 
		? "" : filters.creative_size;

	var symbol = "";

	if (line_item_id) {
		filter += `${symbol}filters[line_item_id]=${line_item_id}`;
		symbol = "&";
	}

	if (creative_external_id) {
		filter += `${symbol}filters[creatives.external_id]=${creative_external_id}`;
		symbol = "&";
	}

	if (id) {
		filter += `${symbol}filters[id]=${id}`;
		symbol = "&";
	}

	if (creativeName) {
		filter += `${symbol}filters[creatives.name]=${creativeName}`;
		symbol = "&";
	}

	if (creativeSize) {
		filter += `${symbol}filters[creatives.size]=${creativeSize}`;
		symbol = "&";
	}

	return filter;
}

export default new LineItemService();
