const detect_prod_key: string = "prod";
const environment: string = isProd() ? "production" : "development";
const version: Version = fetchDataJson();

interface Version {
  branch?: string;
  current_version?: string;
}

export function fetchDataJson() {
  return require(`../../version-${environment}.json`);
}

export function getLocation() {
  return window.location;
}
export function getOrigin() {
  return getLocation().origin;
}
export function isProd() {
  return getOrigin().includes(detect_prod_key);
}

class ProcessService implements Version {
  branch: string = "";
  current_version: string = "";

  constructor() {
    this.branch = version.branch || "";
    this.current_version = version.current_version || "";
  }

  async getVersion() {
    return {
      branch: this.branch,
      current_version: this.current_version,
    } as Version;
  }
}
export default new ProcessService();
