import store from "@/store";

export const moduleAuth: any = store.state["auth"];

export const moduleProfile: any = store.state["profile"];

export const pathname = window.location.pathname;

export const MATCHED_ROLE_ROUTE = {
	"account-report": "Report",
	"account-personas": "PersonsIndex",
	"route-default": "Dashboard",
};

export const ROLE_REPORT: string = "account-report";
export const ROLE_PERSONS: string = "account-personas";
export const ROUTE_DEFAULT: string = "route-default";

export function getModuleAuth(): any {
	return moduleAuth;
}

export function getModuleProfile(): any {
	return moduleProfile;
}

export function isAuthenticated(): Boolean {
	return moduleAuth?.loggedIn;
}

export async function getProfile(): Promise<any> {
	if (!moduleProfile?.profile) {
		await fetchProfile();
	}
	return moduleProfile?.profile;
}

export async function fetchProfile() {
	return await store.dispatch("profile/fetchProfile");
}

export async function getRoles(): Promise<Array<any>> {
	return (await getProfile())?.roles ?? [];
}

export async function hasRole(roleName: string): Promise<Boolean> {
	return (await getRoles()).some((r) => r.name === roleName);
}

export async function hasRoleExcluded(
	excludes: Array<string>
): Promise<Boolean> {
	return (await getRoles()).some((r) => excludes.includes(r.name));
}

export function getSplitedPath(): Array<string> {
	return pathname.split("/");
}

export function isRoute(routePath: string, index: number): boolean {
	return getSplitedPath()[index] === routePath;
}
