import privatePois from "@/services/privatePois/private-pois-service";
import { CatcherError, catchError } from "../../Util";
import { resolveCountryPrivatePois } from "@/utils/resolveObjectArray";
import notificationService from "@/services/notification-service";
import { Notification } from "@/interfaces/proccess";
import { ResultPaginate } from "@/interfaces/paginated";

export const PrivatePoisModule = {
    namespaced:true,
    state:() => ({
		result_paginate_privatePOIs: {} as ResultPaginate,
	}),
    mutations:{
		SET_PRIVATE_POIS_PAGINATED(
			state,
			_result_paginated: ResultPaginate = {} as ResultPaginate
		) {
			state.result_paginate_privatePOIs = _result_paginated;
		},
	},
    getters:{
		getResultPaginatedPrivatePOIs(state: any) {
			return state.result_paginate_privatePOIs;
		},
	},
    actions:{
        async fetchCountry({ commit }, params: { type: string }) {
			try {
				const response = await privatePois.fetchData(params);
				return await Promise.resolve(resolveCountryPrivatePois(response));
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async createPrivatePOIs({}, params) {
			try {
				const response = await privatePois.createPrivatePOis(params);
				notificationService.notifySuccess(this, {
					message: response.message,
				} as Notification);
				return await Promise.resolve(response.data);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async paginatedPrivatePois({ commit }, params) {
			try {
				const response = await privatePois.paginatedPrivatePOIs(
					params
				);
				commit("SET_PRIVATE_POIS_PAGINATED", response);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		}
    }
}