import { ItemGraphic } from "@/interfaces/graphic";
import { ResultPaginate } from "@/interfaces/paginated";
import {
	ConfigPost,
	ResultContinent,
	ResultCountry,
	ResultData,
} from "@/interfaces/persons/v10/response";
import { V10 } from "@/interfaces/persons/v10/route";
import { ListOption } from "@/interfaces/persons/v10/tabs/pois";
import { PersonStorageKey } from "@/interfaces/persons/v10/types";
import {
	ListOptionEntity,
	PersonResourceEntity,
	SelectedDataEntity,
} from "@/models/persons/v10/Implements";
import { PersonEntity } from "@/models/persons/v10/Person";
import { ButtonActionEntity } from "@/models/persons/v10/Tabs/Pois/ButtonAction";
import { RejectError } from "@/models/persons/v10/response";
import {
	AxiosGet,
	AxiosGetData,
	AxiosPost,
	ForceGetData,
	GetData,
	GetErrors,
	GetMessage,
	HasError,
} from "@/services/axios-service";
import {
	getFromStorage,
	removeFromStorage,
	setToStorage,
} from "@/services/storage-service";
import {
	getSpecResultSorted,
	prepareSortedElementData,
} from "@/utils/persons/v10/helpers";
import { ComboListOptions, resolveList } from "@/utils/resolveObjectArray";
import { isUndefined } from "lodash";

const ROUTE = require("@/api/routes").PERSONS_10;
const V10: V10 = require("@/api/routes").V10 as V10;

class PersonService {
	/**
	 * Fetch type: POST paginated
	 * @param params
	 * @returns
	 */
	async fetchGetPaginated(params: { query: string; type: string }) {
		try {
			const url = `${matchedRoutes()[params.type]}${params.query}`;

			const response = await AxiosGet(url);

			if (response.status === 200 && !response.data) {
				return Promise.reject({
					success: false,
					message: "Ocurrio un error (Cod: 1)",
					errors: [],
				});
			}

			if (HasError(response)) {
				return Promise.reject({
					success: false,
					message: "Ocurrio un error (Cod: 2)",
					errors: [],
				});
			}

			const result: ResultPaginate = GetData(response);

			return result;

			// if (!result) return Promise.resolve([] as ElementData);

			// return Promise.resolve(await resolveResult(params.type, result));
		} catch (err) {
			const error: any = err;
			//console.error("PersonService::fetchPaginated", { error });
			if (error.response) {
				// The request was made and the server responded with a status code
				// that falls out of the range of 2xx
				console.error(error.response.data);
				console.error(error.response.status);
				console.error(error.response.headers);
			} else if (error.request) {
				// The request was made but no response was received
				// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
				// http.ClientRequest in node.js
				console.error(error.request);
			} else {
				// Something happened in setting up the request that triggered an Error
				console.error("Error", error.message);
			}
			console.error(error.config);

			return Promise.reject({
				success: false,
				message: error.message,
				errors: GetErrors(error) || [],
			});
		}
	}
	/**
	 * Fetch type: GET paginated
	 * @param params
	 * @returns
	 */
	async fetchPostPaginated(params: { type: string; postData: any }) {
		try {
			const url = matchedRoutes()[params.type];
			const response = await AxiosPost(url, params.postData);

			if (response.status === 200 && !response.data) {
				return Promise.reject({
					success: false,
					message: "Ocurrio un error (Cod: 1)",
					errors: [],
				});
			}

			if (HasError(response)) {
				return Promise.reject({
					success: false,
					message: "Ocurrio un error (Cod: 2)",
					errors: [],
				});
			}

			const result: ResultPaginate = GetData(response);

			return result;

			// if (!result) return Promise.resolve([] as ElementData);

			// return Promise.resolve(await resolveResult(params.type, result));
		} catch (err) {
			const error: any = err;
			//console.error("PersonService::fetchPaginated", { error });
			if (error.response) {
				// The request was made and the server responded with a status code
				// that falls out of the range of 2xx
				console.error(error.response.data);
				console.error(error.response.status);
				console.error(error.response.headers);
			} else if (error.request) {
				// The request was made but no response was received
				// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
				// http.ClientRequest in node.js
				console.error(error.request);
			} else {
				// Something happened in setting up the request that triggered an Error
				console.error("Error", error.message);
			}
			console.error(error.config);

			return Promise.reject({
				success: false,
				message: error.message,
				errors: GetErrors(error) || [],
			});
		}
	}

	async fetchPaginated(params: { filters?: any; type: string }) {
		try {
			let filter = "";

			if (!isUndefined(params.filters)) {
				filter = preparedURL(params.filters);
			}

			const url = `${matchedRoutes()[params.type]}?${filter}`;

			const response = await AxiosGet(url);

			if (response.status === 200 && !response.data) {
				return Promise.reject({
					success: false,
					message: "Ocurrio un error (Cod: 1)",
					errors: [],
				});
			}

			if (HasError(response)) {
				return Promise.reject({
					success: false,
					message: "Ocurrio un error (Cod: 2)",
					errors: [],
				});
			}

			const result: ResultPaginate = GetData(response);

			return result;

			// if (!result) return Promise.resolve([] as ElementData);

			// return Promise.resolve(await resolveResult(params.type, result));
		} catch (err) {
			const error: any = err;
			//console.error("PersonService::fetchPaginated", { error });
			if (error.response) {
				// The request was made and the server responded with a status code
				// that falls out of the range of 2xx
				console.error(error.response.data);
				console.error(error.response.status);
				console.error(error.response.headers);
			} else if (error.request) {
				// The request was made but no response was received
				// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
				// http.ClientRequest in node.js
				console.error(error.request);
			} else {
				// Something happened in setting up the request that triggered an Error
				console.error("Error", error.message);
			}
			console.error(error.config);

			return Promise.reject({
				success: false,
				message: error.message,
				errors: GetErrors(error) || [],
			});
		}
	}

	async postPaginated(params: { filters?: any; type: string }) {
		try {
			const url = `${matchedRoutes()[params.type]}`;

			const response = await AxiosPost(url, params.filters);

			if (response.status === 200 && !response.data) {
				return Promise.reject({
					success: false,
					message: "Ocurrio un error (Cod: 1)",
					errors: [],
				});
			}

			if (HasError(response)) {
				return Promise.reject({
					success: false,
					message: "Ocurrio un error (Cod: 2)",
					errors: [],
				});
			}

			const result: ResultPaginate = GetData(response);

			return result;
		} catch (err) {
			const error: any = err;
			if (error.response) {
				console.error("Error response", { response: error.response });
			} else if (error.request) {
				console.error("Error request", error.request);
			} else {
				console.error("Error message", error.message);
			}
			console.error("Error config", error.config);

			return Promise.reject({
				success: false,
				message: error.message,
				errors: GetErrors(error) || [],
			});
		}
	}

	async fetchData(params: { type: string }) {
		try {
			const url = `${matchedRoutes()[params.type]}`;

			const response = await AxiosGet(url);

			let resultData:
				| ResultContinent[]
				| ComboListOptions[]
				| ListOption[]
				| any[] = GetData(response);

			if (params.type === "countries") {
				resultData = resolveResponseCountry(GetData(response));
			}

			if (params.type === "audiente_type") {
				let items: ListOption[] = [];
				let list = resolveList(GetData(response));
				list.map((l) => {
					const item = new ListOptionEntity(
						l.id,
						String(l.value),
						[2, 3, 4].includes(l.id)
					);
					items.push(item);
				});
				resultData = items;
			}

			return Promise.resolve(resultData);
		} catch (error) {
			console.error("PersonService::fetchData", { error });
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error),
			});
		}
	}

	async fetchGetWithData(params: { type: string; data: any }) {
		try {
			const url = `${matchedRoutes()[params.type]}`;

			const response = await AxiosGetData(url, params.data);

			const result: ResultData = response.data;

			if (!result.response) {
				return Promise.reject({
					success: false,
					message: `Response: ${result.response}`,
					errors: undefined,
				});
			}

			return result;
		} catch (error) {
			console.error("PersonService::fetchGetWithData", { error });
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error),
			});
		}
	}

	async postData(type: string, postData: ConfigPost) {
		try {
			const url = `${matchedRoutes()[type]}`;

			const response = await AxiosPost(url, postData);

			if (response.status === 200 && !response.data) {
				return Promise.reject({
					success: false,
					message: "Ocurrio un error (Cod: 1)",
					errors: [],
				});
			}

			if (HasError(response)) {
				return Promise.reject({
					success: false,
					message: "Ocurrio un error (Cod: 2)",
					errors: [],
				});
			}

			const result: ResultData = ForceGetData(response);

			return Promise.resolve(result);
		} catch (err) {
			const error: any = err;
			if (error.response) {
				console.error("Error response", { response: error.response });
			} else if (error.request) {
				console.error("Error request", error.request);
			} else {
				console.error("Error message", error.message);
			}
			console.error("Error config", error.config);

			return Promise.reject({
				success: false,
				message: error.message,
				errors: GetErrors(error) || [],
			});
		}
	}

	async getGraphicData(key: string, type?: string) {
		let url = `${matchedGraphicRoutes()[key]}`;

		if (type) {
			url = `${matchedGraphicRoutes()[type][key]}`;
		}

		const response = await AxiosGet(url);

		if (response.status === 200 && !response.data) {
			console.error(`PersonService::getGraphicData ${url} (Cod: 1)`, {
				key,
				type,
				url,
				response,
			});
			return Promise.reject({
				success: false,
				message: "Ocurrio un error (Cod: 1)",
				errors: [],
			});
		}

		if (HasError(response)) {
			console.error(`PersonService::getGraphicData ${url} (Cod: 2)`, {
				key,
				type,
				url,
				response,
			});
			return Promise.reject({
				success: false,
				message: "Ocurrio un error (Cod: 2)",
				errors: [],
			});
		}

		const result: ResultData = response.data;

		return Promise.resolve(result);
	}

	async postSavePois(name: string) {
		try {
			const response = await AxiosPost(V10.PERSONS.SAVE_POIS, {
				layer_name: name,
			});
			return response;
		} catch (error) {
			console.error("PersonsService::postSavePois", { error });
			return Promise.reject(new RejectError(error));
		}
	}

	async postSaveGeoFencing(param: {
		country_code: number;
		name: string;
		radio: number;
	}) {
		try {
			const response = await AxiosPost(
				V10.PERSONS.EXPORT_LAT_LON_LIST,
				param
			);
			return response;
		} catch (error) {
			console.error("PersonsService::postSaveGeoFencing", { error });
			return Promise.reject(new RejectError(error));
		}
	}

	async saveDataToStorage(params: {
		person: PersonEntity;
		resources: PersonResourceEntity;
		items: any[];
		pois_actions: ButtonActionEntity[];
	}) {
		try {
			const storedTimeStand = JSON.stringify(new Date().getTime());
			const storedPerson = JSON.stringify(params.person);
			const storedResources = JSON.stringify(params.resources);
			const storedItems = JSON.stringify(params.items);
			const storedActions = JSON.stringify(params.pois_actions);

			setToStorage(PersonStorageKey.STORED_TIMESTAND, storedTimeStand);
			setToStorage(PersonStorageKey.STORED_PERSON, storedPerson);
			setToStorage(PersonStorageKey.STORED_RESOURCE, storedResources);
			setToStorage(PersonStorageKey.STORED_EXPANSION, storedItems);
			setToStorage(PersonStorageKey.STORED_ACTION, storedActions);

			return Promise.resolve({
				timeStand: storedTimeStand,
				person: storedPerson,
				resources: storedResources,
				items: storedItems,
				pois_actions: storedActions,
			});
		} catch (error) {
			console.error("PersonsService::saveDataToStorage", { error });
			return Promise.reject(new RejectError(error));
		}
	}

	async clearDataToStorage() {
		removeFromStorage(PersonStorageKey.STORED_TIMESTAND);
		removeFromStorage(PersonStorageKey.STORED_PERSON);
		removeFromStorage(PersonStorageKey.STORED_RESOURCE);
		removeFromStorage(PersonStorageKey.STORED_EXPANSION);
		removeFromStorage(PersonStorageKey.STORED_ACTION);
	}

	async getSavedDataToStorage() {
		try {
			const storedTimeStand = getFromStorage(
				PersonStorageKey.STORED_TIMESTAND
			);
			const storedPerson = getFromStorage(PersonStorageKey.STORED_PERSON);
			const storedResource = getFromStorage(
				PersonStorageKey.STORED_RESOURCE
			);
			const storedExpansion = getFromStorage(
				PersonStorageKey.STORED_EXPANSION
			);
			const storedAction = getFromStorage(PersonStorageKey.STORED_ACTION);

			return Promise.resolve({
				timeStand: getParsed(storedTimeStand),
				person: getParsed(storedPerson),
				resources: getParsed(storedResource),
				items: getParsed(storedExpansion),
				pois_actions: getParsed(storedAction),
			} as { timeStand: number | undefined; person: PersonEntity; resources: PersonResourceEntity | undefined; items: any[] | undefined; pois_actions: ButtonActionEntity[] | undefined });
		} catch (error) {
			console.error("PersonsService::saveDataToStorage", { error });
			return Promise.reject(new RejectError(error));
		}
	}

	isValidTimeStand() {
		const storedTimeStand = getFromStorage(
			PersonStorageKey.STORED_TIMESTAND
		);

		if (!storedTimeStand) return false;

		return validTimeStand(parseInt(storedTimeStand));
	}
}

export function getParsed(data: string | undefined) {
	if (!data) return undefined;
	return JSON.parse(data);
}

export function validTimeStand(timeStand: number) {
	const currentTime = new Date().getTime();
	const limitTime = 8 * 60 * 60 * 1000; // 8 horas en milisegundos
	//const limitTime = 2 * 60 * 1000; // 2 minutos en milisegundos (para testing)
	return currentTime - timeStand <= limitTime;
}

/**
 * Resolve Result
 * @param type
 * @param result
 * @returns
 */
export async function resolveResult(type: string, result: ResultPaginate) {
	return (
		result?.data?.map((r) => {
			return prepareElementToResolve(type, r);
		}) || []
	);
}

export function prepareElementToResolve(type: string, r: any) {
	const matchedItem = matchedRouteKey();

	const id: number = r[matchedItem[type].key];
	const value: string = r[matchedItem[type].value];
	const count: number = r[matchedItem[type].count];
	const categoria: string | undefined =
		r[matchedItem[type].categoria] || undefined;
	const subcategoria: string | undefined =
		r[matchedItem[type].subcategoria] || undefined;
	const marca: string | undefined = r[matchedItem[type].marca] || undefined;
	const nombre: string | undefined = r[matchedItem[type].nombre] || undefined;

	let element = new SelectedDataEntity({
		id,
		value,
		count,
		categoria,
		subcategoria,
		marca,
		nombre,
	});

	return element;
}

export function resolveResponseCountry(response: ResultCountry[]) {
	let resultSorted = getSpecResultSorted(response, [
		"Asia",
		"Europe",
		"Caribbean",
		"North America",
		"Central America",
		"South America",
	]);

	let resultElements: ResultContinent[] = [];

	const uniqueElements = [...new Set(resultSorted.map((v) => v.continent))];

	resultElements = uniqueElements.map((e) => {
		return {
			title: e,
			items: prepareSortedElementData(resultSorted, e, "asc"),
		} as ResultContinent;
	});

	return resultElements;
}

export function resolveConfigurableResponse(
	response: any,
	config: { key: string; value: string; count?: string }
): Array<ItemGraphic> {
	return response.map((item: any) => {
		return {
			uniques: item[config.key],
			name: item[config.key],
		} as ItemGraphic;
	});
}

function matchedRoutes() {
	return {
		countries: ROUTE.PERSONS_10_COUNTRIES_ROUTE,
		states: ROUTE.PERSONS_10_ESTADOS_ROUTE,
		cities: ROUTE.PERSONS_10_MUNICIPIOS_ROUTE,
		neighborhoods: ROUTE.PERSONS_10_BARRIOS_ROUTE,
		categories: ROUTE.PERSONS_10_CATEGORIA_ROUTE,
		subcategories: ROUTE.PERSONS_10_SUBCATEGORIA_ROUTE,
		brands: ROUTE.PERSONS_10_MARCAS_ROUTE,
		names: ROUTE.PERSONS_10_NAMES_ROUTE,
		privates: ROUTE.PERSONS_10_PRIVATE_ROUTE,
		audiente_type: ROUTE.PERSONS_10_AUDIENCE_TYPE_ROUTE,
		analize_pois: V10.PERSONS.ANALIZE.POIS,
		analize_geo: V10.PERSONS.ANALIZE.GEO,
		store_attribution: V10.PERSONS.CALCULATE.STORE_ATTRIBUTION,
	};
}

function matchedGraphicRoutes() {
	return {
		pois: {
			categories: V10.PERSONS.GRAPHIC.POIS.CATEGORIES,
			subcategories: V10.PERSONS.GRAPHIC.POIS.SUBCATEGORIES,
			brands: V10.PERSONS.GRAPHIC.POIS.BRANDS,
			states: V10.PERSONS.GRAPHIC.POIS.STATES,
			cities: V10.PERSONS.GRAPHIC.POIS.CITIES,
			neighborhoods: V10.PERSONS.GRAPHIC.POIS.NEIGHBORHOODS,
		},
		geo: {
			states: V10.PERSONS.GRAPHIC.GEO.STATES,
			cities: V10.PERSONS.GRAPHIC.GEO.CITIES,
			neighborhoods: V10.PERSONS.GRAPHIC.GEO.NEIGHBORHOODS,
		},
		reach: {
			geo: V10.PERSONS.CALCULATE.GEO,
			pois: V10.PERSONS.CALCULATE.POIS,
			store_attribution: V10.PERSONS.CALCULATE.STORE_ATTRIBUTION,
		},
	};
}

function matchedConfigParams() {
	return {
		categories: {
			key: "categoria",
			value: "cant_pois",
		},
		subcategories: {
			key: "subcat",
			value: "cant_pois",
		},
		brands: {
			key: "marca",
			value: "cant_pois",
		},
		states: {
			key: "dpto",
			value: "cant_pois",
		},
		cities: {
			key: "ciudad",
			value: "cant_pois",
		},
		neighborhoods: {
			key: "barrio",
			value: "cant_pois",
		},
	};
}

function matchedRouteKey() {
	return {
		countries: {},
		audiente_type: {},
		states: {
			key: "codigo",
			value: "nombre",
		},
		cities: {
			key: "codigo",
			value: "nombre",
		},
		neighborhoods: {
			key: "codigo",
			value: "nombre",
		},
		categories: {
			key: "nombre",
			value: "nombre",
			count: "count",
		},
		subcategories: {
			key: "nombre",
			value: "nombre",
			count: "count",
			categoria: "categoria",
		},
		brands: {
			key: "nombre",
			value: "nombre",
			count: "count",
			categoria: "categoria",
			subcategoria: "subcategoria",
		},
		names: {
			key: "nombre",
			value: "nombre",
			count: "count",
			categoria: "categoria",
			subcategoria: "subcategoria",
			marca: "marca",
		},
		privates: {
			key: "id",
			value: "name",
		},
	};
}

function preparedURL(filter) {
	let url = "";

	url += "country_code=" + filter.country_code;
	url += "&mode=" + filter.mode || "paginated";
	url += "&limit=" + filter.limit || 25;
	url += "&page=" + filter.page || 1;

	if (filter.filters) {
		for (const [key, value] of Object.entries(filter.filters)) {
			const filterValues: number[] = value as number[];
			filterValues.forEach((f) => {
				url += `&filters[${key}][]=${f}`;
			});
		}
	}

	if (filter.search) {
		url += "&search=" + filter.search;
	}

	return url;
}

export default new PersonService();
