import { EnumStatus, ResultDataEntity } from "@/interfaces/report";
/**
 * Tiempo de espera para reintentar
 */
export const delayAttempt: number = 8000;

export async function isWillBeRetried(response: {
	success: boolean;
	status: EnumStatus;
	message: any;
	data: ResultDataEntity | string;
}) {
	if (response.success === false || typeof response.data === "string") return false;

	return [EnumStatus.PENDING].includes(response.status);
}

export async function isError(response: ResultDataEntity) {
	if (!response.response || typeof response.response === "string") return false;
	return [EnumStatus.ERROR].includes(response.response.status);
}

export function HasStatusAttempt(response: any, status: EnumStatus) {
	return response.response.status === status;
}