export interface Advertiser {
    id: number;
    external_id: number;
    account_id: number;
    name: string;
    domain: string;
    app_bundle: string;
    active: boolean;
    created_by: {
        id: number;
        first_name: string;
        last_name: string;
        email: string;
    },
    updated_by: {
        id: number;
        first_name: string;
        last_name: string;
        email: string;
    },
    deleted_by: {
        id: number;
        first_name: string;
        last_name: string;
        email: string;
    },
    created_at: string;
    updated_at: string;
    category: {
        id: number;
        key: string;
        name: string
    },
    currency: {
        id: number;
        key: string;
        name: string;
        glyph: string;
        emoji_flag: string;
    },
    clicks?: number;
    conversion_orders?: number;
    conversion_value?: number;
    conversions?: number;
    cost_per_acquisition?: number;
    cost_per_acquisition_usd?: number;
    cpm?: number;
    ctr?: number;
    gross_margin?: number;
    impressions?: number;
    net_margin?: number;
    spend?: number;
    update_date?: number;
    vcpm?: number;
    video_complete_percent?: number;
    video_completes?: number;
    video_plays?: number;
    viewable_impressions?: number;
    viewable_percent?: number;
    clicks_lifetime?: number;
    conversion_orders_lifetime?: number;
    conversion_value_lifetime?: number;
    conversions_lifetime?: number;
    cost_per_acquisition_lifetime?: number;
    cost_per_acquisition_lifetime_usd?: number;
    cpm_lifetime?: number;
    ctr_lifetime?: number;
    gross_margin_lifetime?: number;
    impressions_lifetime?: number;
    net_margin_lifetime?: number;
    spend_lifetime?: number;
    vcpm_lifetime?: number;
    video_complete_percent_lifetime?: number;
    video_completes_lifetime?: number;
    video_plays_lifetime?: number;
    viewable_impressions_lifetime?: number;
    viewable_percent_lifetime?: number;
}

export interface AdvertiserDataCreate {
    name: string;
    category_id: number;
    domain: string;
    app_bundle: string;
    active: Boolean;
}

export interface AdvertiserDataUpdate {
    id: number;
    name: string;
    category_id: number;
    domain: string;
    app_bundle: string;
    active: Boolean;
}

export interface AdvertiserList {
    id: number;
    value: string;
}

export interface Notification {
    title: string,
    subtitle?: string,
    message: string,
    details?: any,
    type: string,
    to?: any,
    show?: Boolean,
    btn_text_redirect?: string,
    to_redirect?: any,
    show_redirect?: Boolean,
    query?: any,
    params?: any,
    btn_text: string
}

export enum MessageTypes {
    INFO = "info",
    SUCCESS = "success",
    ERROR = "error",
    WARNING = "warning",
    NOTFOUND = "not-found",
    FAILED = "failed",
    CONTINUE = "continue",
    TRYAGAIN = "try-again",
    TITLE_SUCCESS = "title-success",
    TITLE_FAILED = "title-failed",
    TITLE_WARNING = "title-warning",
    UNAUTHORIZED = "Unauthorized"
}