import { ItemGraphic } from "@/interfaces/graphic";
import { AudienceEntity, PoisCountEntity } from "@/models/persons/v10/Audience";
import { cloneDeep, differenceWith, isArray, isEmpty, isEqual, isNull, sortBy } from "lodash";
import {
	attemptFetchAudienceGraphic, attemptFetchPois, convertToFilterType, exportAudienceSegments, filterAudienceGraphics
} from "./utils";
import {
	FilterItem, ISegmentAudience,
} from "@/interfaces/persons/v10/audience";
import { TypeLoading } from "@/interfaces/loading";
import audienceService from "@/services/persons/v10/audience-service";
import { Notification } from "@/interfaces/proccess";
import i18n from "@/plugins/i18n";
import { RejectError } from "@/models/persons/v10/response";
import notificationService from "@/services/notification-service";
import { FilterEntity } from "@/models/persons/v10/Implements";
import { ITotalPois } from "@/interfaces/persons/v10/person";

const graphics: string[] = ['total_reach', 'proximity_to_poi', 'gender', 'age', 'niv_socio', 'category_poi', 'sub_category_poi', 'marca_poi', 'dpto_poi', 'city_poi', 'barrio_poi', 'date_of_week', 'time_of_day', 'date', 'time_of_day_of_week', 'residence_city', 'residence_barrio', 'iab', 'interest', 'sites', 'app_name', 'content_language', 'city_connection', 'carrier', 'device_type', 'make', 'browser', 'os', 'metrics_by_brand_and_poi', 'attributions', 'rich_attributions', 'rich_attributions_daily'];

export const AudienceModule = {
	namespaced: true,
	state: () => ({
		audience: new AudienceEntity(),
		hasFilters: false,
		pois: new PoisCountEntity()
	}),
	mutations: {
		ADD_FILTER(
			state: { audience: AudienceEntity },
			param: { id: string, data: FilterItem[] }
		) {
			if (isArray(param.data)) {
				state.audience.filters[param.id] = param.data;
			} else {
				state.audience.filters[param.id] = [param.data];
			}
		},
		REMOVE_FILTER(
			state: { audience: AudienceEntity },
			param: FilterItem
		) {
			const filterIndex = state.audience.filters[param.type].findIndex(item => item == param)
			state.audience.filters[param.type].splice(filterIndex, 1)
		},
		SAVE_FILTER(
			state: { audience: AudienceEntity }
		) {
			state.audience.last_filters = cloneDeep(state.audience.filters);
		},
		RESET_FILTER(
			state: { audience: AudienceEntity }
		) {
			state.audience.filters = new FilterEntity();
			state.audience.last_filters = new FilterEntity();
		},
		SET_GRAPHIC(
			state: { audience: AudienceEntity },
			params: {
				name: string;
				loading: Boolean;
				source: ItemGraphic[];
			}
		) {
			let isInvalid = isNull(params.source);

			if (!isInvalid && params.source) {
				params.source.forEach(item => { if (item.f0_) isInvalid = true })
			}

			if (isInvalid) params.source = [];

			state.audience[params.name] = Object.assign(state.audience[params.name], params);
		},
		SET_FILTER_CHANGES(
			state: { hasFilters: boolean, audience: AudienceEntity },
			params: { filters: boolean }
		) {
			state.hasFilters = params.filters
		},
		SET_POIS(			
			state: { pois: PoisCountEntity },
			params: { type: "public" | "private", value: number }
		) {
			if(params.type === "public") {
				state.pois.public += params.value
			} else {
				state.pois.private += params.value
			}
		}
	},
	getters: {
		getAudience(state: { audience: AudienceEntity }) {
			return state.audience;
		},

		getPoisCount(state: { pois: PoisCountEntity }) {
			return state.pois;
		},

		objectFilters(state: { audience: AudienceEntity }) {
			return state.audience.filters;
		},

		getFilters:
			(state, getters: { objectFilters: () => { [key: string]: FilterItem[] | undefined } }) =>
				(type: string | string[]) => {
					let filters = getters.objectFilters;
					if (typeof type === "object") return type.map(item => filters[item] || []).flat()
					return filters[type] || []
				},

		filterHasChanges(state: { audience: AudienceEntity }) {
			let filterHasChange = false;
			let keys = Object.keys(state.audience.filters);

			keys.forEach(item => {
				if (!isEqual(sortBy(state.audience.filters[item], ['type', 'name']), sortBy(state.audience.last_filters[item], ['type', 'name']))) {
					filterHasChange = true;
				}
			})

			return filterHasChange;
		},

		loadingData(state: { audience: AudienceEntity }) {
			let loading = false;
			graphics.forEach(graphic => {
				if (state.audience[graphic]?.loading) {
					loading = true;
				}
			})

			return loading;
		}
	},
	actions: {

		async initialize({ commit }) {
			let params;
			try {
				params = await audienceService.fetchPreviousFilters(commit);

				if (!isNull(params) && params.filters) {
					const { filters } = params;
					// const date = filters.start_date;

					// user_id_type: getNames(user_type),
					commit("ADD_FILTER", convertToFilterType("user_type", filters.user_id_type));
					// start_date: date[0] ? date[0].name : null,
					// newFilters.push(...convertToFilterType("date", date ? [[filters.start_date, filters.end_date].join(", ")] : []));
					// end_date: date[1] ? date[1].name : null,
					// radio: poi_distance[0] ? parseInt(poi_distance[0].name) : null,
					commit("ADD_FILTER", convertToFilterType("poi_distance", [filters.radio]));
					// pois: {
					// 	city_poi: getNames(city_poi),
					commit("ADD_FILTER", convertToFilterType("city_poi", filters.pois.city_poi));
					// 	dpto: getNames(dpto_poi),
					commit("ADD_FILTER", convertToFilterType("dpto_poi", filters.pois.dpto));
					// 	category: getNames(category_poi),
					commit("ADD_FILTER", convertToFilterType("category_poi", filters.pois.categoria));
					// 	subcategoria: getNames(sub_category_poi),
					commit("ADD_FILTER", convertToFilterType("sub_category_poi", filters.pois.subcategoria));
					// 	marca: getNames(marca_poi),
					commit("ADD_FILTER", convertToFilterType("marca_poi", filters.pois.marca));
					// 	nombre_poi: getNames(barrio_poi)
					commit("ADD_FILTER", convertToFilterType("barrio_poi", filters.pois.nombre_poi));
					// },
					// bid_request: {
					// 	app_bundle: [],
					// 	app_name: getNames(app_name),
					commit("ADD_FILTER", convertToFilterType("app_name", filters.bid_request.app_name));
					// 	city_seen: getNames(city_connection),
					commit("ADD_FILTER", convertToFilterType("city_connection", filters.bid_request.city_seen));
					// 	domain: getNames(sites),
					commit("ADD_FILTER", convertToFilterType("sites", filters.bid_request.domain));
					// 	iab: getNames(iab),
					commit("ADD_FILTER", convertToFilterType("iab", filters.bid_request.iab));
					// 	platform_browser: getNames(browser),
					commit("ADD_FILTER", convertToFilterType("browser", filters.bid_request.platform_browser));
					// 	platform_device_language: getNames(content_language),
					commit("ADD_FILTER", convertToFilterType("content_language", filters.bid_request.platform_device_language));
					// 	platform_device_make: getNames(make),
					commit("ADD_FILTER", convertToFilterType("make", filters.bid_request.platform_device_make));
					// 	platform_device_type: getNames(device_type),
					commit("ADD_FILTER", convertToFilterType("device_type", filters.bid_request.platform_device_type));
					// 	platform_os: getNames(os),
					commit("ADD_FILTER", convertToFilterType("os", filters.bid_request.platform_os));
					// 	carrier: getNames(carrier)
					commit("ADD_FILTER", convertToFilterType("carrier", filters.bid_request.carrier));
					// },
					// gender_age: {
					// 	gender: getNames(gender),
					commit("ADD_FILTER", convertToFilterType("gender", filters.gender_age.gender));
					// 	age: getNames(age)
					commit("ADD_FILTER", convertToFilterType("age", filters.gender_age.age));
					// },
					// home: {
					// 	neighborhood_residence: getNames(residence_barrio),
					commit("ADD_FILTER", convertToFilterType("residence_barrio", filters.home.neighborhood_residence));
					// 	city_residence: getNames(residence_city),
					commit("ADD_FILTER", convertToFilterType("residence_barrio", filters.home.city_residence));
					// 	nombre_estado: [],
					//	nivsocio: getNames(niv_socio)
					commit("ADD_FILTER", convertToFilterType("niv_socio", filters.home.nivsocio));
					// },
					// interest: {
					// 	interest: getNames(interest)
					commit("ADD_FILTER", convertToFilterType("interest", filters.interest.interest));
					// }
					// frecuency: {
					//    "0": [1, 2, 3, 4, 5, 6, 7, 8, 9]	
					// }
					const date_of_week = Object.keys(filters.frequency || {});

					if (date_of_week.length !== 7) {
						commit("ADD_FILTER", convertToFilterType("date_of_week", date_of_week));
					}

					const time_of_day = filters.frequency ? filters.frequency[date_of_week[0]] : [];
					if (time_of_day.length !== 24) {
						commit("ADD_FILTER", convertToFilterType("time_of_day", time_of_day));
					}

					commit("SAVE_FILTER");
				}
			} catch (err) {
				if (err instanceof RejectError) {
					notificationService.notifyError(this, {
						message: err.message,
						details: err.errors
					} as Notification)
				}
			}

			return Promise.resolve();
		},

		async fetchAll({ commit, state }) {

			graphics.forEach(item => {
				const audience = state.audience as AudienceEntity;

				/**
				 * Verify with the GraphicEntity validator if
				 * this operation must be fetched. Otherwhise 
				 * set the chart loading to false
				 */
				if(!audience[item].validator()) {
					return commit("SET_GRAPHIC", {
						name: item,
						source: [],
						loading: false,
					})
				};
				/** 
				 * NOTE: All GraphicEntity can have their own
				 * custom validators. The default validator
				 * always return true 
				 */

				attemptFetchAudienceGraphic(commit, item, state.hasFilters)
			})
		},

		async fetchFilters({ commit, getters, dispatch }) {
			try {
				dispatch('loading/setLoadingData', TypeLoading.loading, { root: true });
				await filterAudienceGraphics(getters.objectFilters)
				await commit("SET_FILTER_CHANGES", { filters: true })
				await commit("SAVE_FILTER")
				await dispatch('fetchAll')
			} catch (err) {
				if (err instanceof RejectError) {
					notificationService.notifyError(this, {
						message: err.message,
						details: err.errors
					} as Notification)
				}
				dispatch('loading/setLoadingData', undefined, { root: true });
			}

			dispatch('loading/setLoadingData', undefined, { root: true });
		},
		
		async clearFilters({ commit }) {
			commit('RESET_FILTER');
		},

		async fetchPois({ getters, commit }) {
			try {
				const response = await attemptFetchPois() as ITotalPois[];
				let getPoisCount = getters.getPoisCount as PoisCountEntity;

				getPoisCount.fetched = true;
				response.forEach((item) => {
					commit("SET_POIS", {
						type: item.type.split(" ")[0],
						value: item.count
					});
				});
			} catch (err) {
				if (err instanceof RejectError) {
					notificationService.notifyError(this, {
						message: err.message,
						details: err.errors
					} as Notification)
				}
			}
		},

		async addFilter({ commit }, param: { id: string, data: { id: string, data: FilterItem | FilterItem[] | null } }) {
			if (param) commit('ADD_FILTER', param)
		},

		async removeFilter({ commit }, param: FilterItem) {
			commit('REMOVE_FILTER', param)
		},

		async exportAudience({ commit, getters, dispatch }, param: ISegmentAudience) {
			try {
				dispatch('loading/setLoadingData', TypeLoading.loading, { root: true });
				await exportAudienceSegments(param, getters.objectFilters);

				notificationService.notifySuccess(this, {
					message: i18n.t('success'),
				} as Notification)
			} catch (err) {
				if (err instanceof RejectError) {
					notificationService.notifyError(this, {
						details: err.errors,
					} as Notification)
				}
				dispatch('loading/setLoadingData', undefined, { root: true });
			}

			dispatch('loading/setLoadingData', undefined, { root: true });
		},
	},
};
