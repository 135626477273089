import { Paginated, SortingOption } from "@/interfaces/paginated";
import { AxiosDownload } from "@/services/axios-service";
import { isEmpty } from "lodash";

/**
 * file extension
 */
export const extension = ".csv";

/**
 * API response modes
 */
export const modes = {
	DOWNLOAD: "download",
	PAGINATED: "paginated",
	LIST: "list",
	ALL: "all",
};

/**
 *
 * Get Url
 *
 * @param filters
 * @param option
 * @param fields
 * @returns url
 */
export function getURL(
	filters: string,
	option: string,
	fields?: Array<string>,
	term?: string,
	report_type?: string,
	field_type?: string
): string {
	let url = "";

	let symbol = "?";

	if (!isEmpty(filters)) {
		url += `${symbol}${filters}`;
		symbol = "&";
	}

	if (!isEmpty(option)) {
		url += `${symbol}${option}`;
		symbol = "&";
	}

	if (!isEmpty(term)) {
		url += `${symbol}term=${term}`;
		symbol = "&";
	}

	if (!isEmpty(report_type)) {
		url += `${symbol}report_type=${report_type}`;
		symbol = "&";
	}

	if (!isEmpty(field_type)) {
		url += `${symbol}field_type=${field_type}`;
		symbol = "&";
	}

	if (!isEmpty(fields)) {
		fields?.map((f) => {
			url += `${symbol}fields[]=${f}`;
		});
	}

	return url;
}

/**
 *
 * Get options
 *
 * @param mode
 * @param options
 * @param paginated
 * @returns option
 */
export function getOptions(
	mode: string,
	options?: SortingOption,
	paginated?: Paginated
): string {
	const sort = options?.sort || "asc";
	const order = options?.order || "";
	const option = `sort=${sort}&order=${order}&mode=${mode}&page=${paginated?.page}&limit=${paginated?.limit}`;

	return option;
}

/**
 * Prepare Url
 *
 * @param params
 * @returns url
 */
export async function prepareUrlParams(params: {
	mode: string;
	fields?: Array<string>;
	paginated?: Paginated;
	filter?: any;
	options?: SortingOption;
	term?: string;
	report_type?: string;
	field_type?: string;
}) {
	let option = "";

	option = getOptions(params.mode, params.options, params.paginated);

	return getURL(
		params.filter,
		option,
		params.fields,
		params.term,
		params.report_type,
		params.field_type
	);
}

/**
 * Prepare file name
 * @param entity
 * @returns
 */
export function prepareFileName(entity: string, extension: string = "csv") {
	return `${entity}-${new Date().getTime()}.${extension}`;
}

/**
 * Download file {extension}
 *
 * @param params
 * @returns
 */
export async function downloadFile(params: {
	route: string;
	entity: string;
	paginated?: Paginated;
	filter?: any;
	options?: SortingOption;
	fields: Array<string>;
	mode: string;
}) {
	return await AxiosDownload(
		`${params.route}${await prepareUrlParams({
			...params,
		})}`,
		`${prepareFileName(params.entity)}`
	);
}

/**
 * Prepare params
 *
 * @param params
 * @returns
 */
export async function prepareParams(params: {
	route: string;
	entity: string;
	fields?: Array<string>;
	mode: string;
	paginated?: Paginated;
	filter?: string;
	options?: SortingOption;
}) {
	return `${params.route}${await prepareUrlParams({
		...params,
	})}`;
}

export function getCurrentURLParams() {
	return new URLSearchParams(window.location.search);
}

export function isBackFromAudience() {
	const params = getCurrentURLParams();
	return params.has("from") && params.get("from") === "audience";
}
