import { UserLanguage } from "@/interfaces/internalization";
import {
	AxiosGet,
	AxiosPost,
	GetData,
	GetErrors,
	GetMessage,
} from "@/services/axios-service";

const INTERNALIZATION = require("../api/routes").INTERNALIZATION;

class InternalizationService {
	async getLanguages() {
		try {
			const response = await AxiosGet(
				`${INTERNALIZATION.TRANSLATION_LANGUAGES}`
			);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error),
			});
		}
	}
	async setLanguage(params: UserLanguage) {
		try {
			const response = await AxiosPost(
				`api/users/${params.id}/setLanguage`,
				{ language: params.language }
			);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error),
			});
		}
	}
}

export default new InternalizationService();
