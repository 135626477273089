import { Paginated, SortingOption } from "@/interfaces/paginated";
import {
	downloadFile,
	modes,
	prepareFileName,
	prepareParams,
} from "@/utils/services-global";
import { isArray, isUndefined, isEmpty } from "lodash";
import {
	AxiosGet,
	AxiosPost,
	ForceDownload,
	GetData,
	GetDataResponse,
	GetErrors,
	GetMessage,
	AxiosPostDownload,
	HasError,
	ForceGetData,
	dataForceDownload,
} from "./axios-service";
import {
	EnumReportType,
	EnumStatus,
	ReportGetParam,
	ResultDataEntity,
	SchedulerReportFilters,
} from "@/interfaces/report";
import { parseEncode } from "@/utils/filter-global";
import { ResultData } from "@/interfaces/persons/v10/response";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { HasStatusAttempt } from "@/utils/report";

const ROUTE = require("../api/routes").REPORT;
const ROUTE_ACCOUNT = require("../api/routes").AUTH;
const ROUTE_CAMPAIGN = require("../api/routes").CAMPAIGN;

const ENTITY: string = "report";
const ENTITY_CAMPAIGN: string = "campaign";

const keyFilter = {
	line_item: "campaign_id",
	campaign: "advertiser_id",
	creative: "line_item_id",
};

const matchedExtension = {
	csv: "csv",
	xls: "xlsx",
	xlsx: "xlsx",
};

class ReportService {
	async search(params: {
		type: string;
		filters?: any;
		options?: SortingOption;
		term?: string;
		customKey?: string;
		customValue?: string;
		accounts?: { id: number }[];
	}) {
		try {
			let filter = "";

			if (!isUndefined(params.filters)) {
				let key = keyFilter[params.type];
				filter = filters(key, params.filters);
			}

			const url = await prepareParams({
				route: matchedRoutes()[params.type],
				entity: ENTITY,
				mode: modes.LIST,
				filter: filter,
				...params,
			});

			// Prepare accounts filter
			const accountFilter = params.accounts
				? params.accounts
						.map((account) => {
							return "account[]=" + account.id;
						})
						.join("&")
				: "";

			const url_v2 = url + "&" + accountFilter;
			const response = await AxiosGet(url_v2);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error),
			});
		}
	}

	async fields(params: {
		report_type: string;
		field_type: string;
		type: string;
		customKey?: string;
		customValue?: string;
		return_object?: Boolean;
	}) {
		try {
			const url = await prepareParams({
				route: matchedRoutes()[params.type],
				entity: ENTITY,
				mode: modes.ALL,
				...params,
			});

			const response = await AxiosGet(url);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error),
			});
		}
	}

	async run(params: any) {
		try {
			const url = await prepareParams({
				route: matchedRoutes()[params.type],
				entity: ENTITY,
				mode: modes.ALL,
				...params,
			});

			const response = await AxiosPost(url, params.report);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error),
			});
		}
	}

	async download(params: { type: string; payload: any }) {
		try {
			const response = await AxiosPostDownload(
				matchedRoutes()[params.type],
				"report",
				params.payload,
				matchedExtension[params.payload.type]
			);
			/*
			console.log(response);
			if (!isArray(response.data?.response)) {
				ForceDownload(response, prepareFileName("report", params.payload.type));
			}
			return Promise.resolve(GetDataResponse(response));
			*/
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error),
			});
		}
	}

	async downloadReport(
		result: string,
		extension: EnumReportType.CSV | EnumReportType.XLS
	) {
		dataForceDownload(
			result,
			prepareFileName("report", extension),
			extension
		);
	}

	async getAllReportType() {
		try {
			const match = "report_all_type";
			const url = matchedRoutes()[match];
			const response = await AxiosGet(url);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error),
			});
		}
	}

	async getDimMet(params: { report_type: string; field_type: string }) {
		try {
			const url = matchedRoutes()[params.field_type] + params.report_type;
			const response = await AxiosGet(url);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error),
			});
		}
	}

	async getSchedulerTypes() {
		try {
			const match = "scheduler_type";
			const url = matchedRoutes()[match];
			const response = await AxiosGet(url);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error),
			});
		}
	}

	async createSchedulerReport(params: any) {
		try {
			const response = await AxiosPost(
				matchedRoutes()["scheduler"],
				params
			);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error),
			});
		}
	}

	async getSchedulerPaginated(params: {
		paginated?: Paginated;
		filters?: SchedulerReportFilters;
		options?: SortingOption;
		fields?: Array<string>;
	}) {
		try {
			let filter = "";

			if (!isUndefined(params.filters)) {
				filter = getFilters(params.filters);
			}

			const url = await prepareParams({
				route: matchedRoutes()["scheduler"],
				entity: ENTITY,
				mode: modes.PAGINATED,
				filter: filter,
				...params,
			});

			const response = await AxiosGet(url);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error),
			});
		}
	}

	async paginatedReportOnDemand(params: any) {
		try {
			let filter = "";
			if (params.hasOwnProperty("filters")) {
				filter = getFiltersReportOnDemand(params.filters);
			}

			const url = await prepareParams({
				route: matchedRoutes()["report_on_demand"],
				entity: ENTITY,
				mode: modes.PAGINATED,
				filter: filter,
				...params,
			});

			const response = await AxiosGet(url);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error),
			});
		}
	}

	async fetchCampaign(params: any) {
		try {
			let url = await prepareParams({
				route: ROUTE.REPORT_CAMPAIGN_ROUTE,
				entity: ENTITY_CAMPAIGN,
				mode: modes.LIST,
			});
			if (params.term) {
				url = url + "&term=" + params.term;
			}
			const response = await AxiosGet(url);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error),
			});
		}
	}

	async fetchListPois(params: any) {
		try {
			let url = matchedRoutes()["report_list_type"];
			if (params.term) {
				url = url + "&term=" + params.term;
			}
			const response = await AxiosGet(url);

			return Promise.resolve(GetData(response));
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error),
			});
		}
	}

	async fetchCountryStoreAttribution(params: any) {
		try {
			let url = matchedRoutes()["report_country"];
			if (params.term) {
				url = url + "&term=" + params.term;
			}
			const response = await AxiosGet(url);

			return Promise.resolve(GetData(response));
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error),
			});
		}
	}

	async fetchCountryCampaign(params: any) {
		try {
			let url = matchedRoutes()["report_country_campaign"];
			if (params.term) {
				url = url + "&term=" + params.term;
			}
			const response = await AxiosGet(url);

			return Promise.resolve(GetData(response));
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error),
			});
		}
	}

	async createReportDemand(params: any) {
		try {
			const response = await AxiosPost(
				matchedRoutes()[params.KEY_ROUTE],
				params.DATA
			);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error),
			});
		}
	}

	async downloadReportDemand(params: any) {
		try {
			const url =
				matchedRoutes()["report_on_demand_download"] + params.id;
			const response = await AxiosGet(url);
			if (response?.data) {
				ForceDownload(response, prepareFileName("report"));
			}
			return Promise.resolve({});
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error),
			});
		}
	}

	/**
	 * POST
	 */
	async postData(type: string, payload: any) {
		try {
			const url = `${matchedRoutes()[type]}`;

			const response = await AxiosPost(url, payload);

			if (response.status === 200 && !response.data) {
				return Promise.reject({
					success: false,
					message: "Ocurrio un error (Cod: 1)",
					errors: [],
				});
			}

			if (HasError(response)) {
				return Promise.reject({
					success: false,
					message: "Ocurrio un error (Cod: 2)",
					errors: [],
				});
			}

			const result: ResultData = ForceGetData(response);

			return Promise.resolve(result);
		} catch (err) {
			const error: any = err;
			if (error.response) {
				console.error("postData: Error response", {
					response: error.response,
				});
			} else if (error.request) {
				console.error("postData: Error request", error.request);
			} else {
				console.error("postData: Error message", error.message);
			}
			console.error("postData: Error config", error.config);

			return Promise.reject({
				success: false,
				message: error.message,
				errors: GetErrors(error) || [],
			});
		}
	}

	async prepareAttemptReport(result: ResultDataEntity, type: EnumReportType) {
		const { response } = result;

		if (typeof response === "string") return undefined;

		return {
			id: response?.id,
			type: type,
		} as ReportGetParam;
	}

	async attemptGetReport(payload: ReportGetParam) {
		try {
			const url = `${ROUTE.REPORT_ROUTE}/get_report/${payload.id}/${payload.type}`;

			const _config: AxiosRequestConfig<any> = {
				responseType: "arraybuffer", // Esto indica que la respuesta debe ser tratada como un array de bytes {For binary files}
			};

			const response = await AxiosGet(url, _config);

			const contentType: string | undefined =
				response.headers["content-type"];
			const isJson: boolean = contentType.includes("application/json");
			const isTextPlain: boolean = contentType.includes("text/plain");
			const isXls: boolean = contentType.includes(
				"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
			);

			const result: ResultDataEntity | string = response.data;

			if (contentType && isJson) {
				
				const jsonData: ResultData = JSON.parse(
					new TextDecoder().decode(response.data)
				);

				if (HasStatusAttempt(jsonData, EnumStatus.ERROR)) {
					return Promise.reject({
						type: EnumReportType.NO_TYPE,
						success: false,
						status: EnumStatus.ERROR,
						message: EnumStatus.ERROR,
						data: result,
					});
				}

				if (HasStatusAttempt(jsonData, EnumStatus.PENDING)) {
					return Promise.resolve({
						type: EnumReportType.NO_TYPE,
						success: true,
						status: EnumStatus.PENDING,
						message: EnumStatus.PENDING,
						data: result,
					});
				}

				if (HasStatusAttempt(jsonData, EnumStatus.EMPTY)) {
					return Promise.resolve({
						type: EnumReportType.NO_TYPE,
						success: false,
						status: EnumStatus.EMPTY,
						message: EnumStatus.EMPTY,
						data: result,
					});
				}
			}

			if (contentType && isTextPlain) {
				await this.downloadReport(result as string, payload.type);
				return Promise.resolve({
					type: payload.type,
					success: true,
					status: EnumStatus.SUCCESS,
					message: EnumStatus.SUCCESS,
					data: result,
				});
			}

			if (contentType && isXls) {
				await this.downloadReport(result as string, payload.type);
				return Promise.resolve({
					type: payload.type,
					success: true,
					status: EnumStatus.SUCCESS,
					message: EnumStatus.SUCCESS,
					data: result,
				});
			}

			if (HasError(response)) {
				return Promise.reject({
					type: EnumReportType.NO_TYPE,
					success: false,
					status: EnumStatus.ERROR,
					message: "Ocurrio un error (Cod: 200300)",
					errors: [],
					data: result,
				});
			}

			return Promise.reject({
				type: EnumReportType.NO_TYPE,
				success: false,
				status: EnumStatus.ERROR,
				message: "Error desconocido",
				errors: [],
				data: result,
			});
		} catch (err) {
			const error: any = err;
			if (error.response) {
				console.error("postData: Error response", {
					response: error.response,
				});
			} else if (error.request) {
				console.error("postData: Error request", error.request);
			} else {
				console.error("postData: Error message", error.message);
			}
			console.error("postData: Error config", error.config);

			return Promise.reject({
				success: false,
				status: EnumStatus.ERROR,
				message: error.message,
				errors: GetErrors(error) || [],
			});
		}
	}
}

function matchedRoutes() {
	return {
		report: ROUTE.REPORT_ROUTE,
		download: ROUTE.REPORT_ROUTE,
		advertiser: ROUTE.REPORT_ADVERTISER_ROUTE,
		line_item: ROUTE.REPORT_LINE_ROUTE,
		campaign: ROUTE.REPORT_CAMPAIGN_ROUTE,
		creative: ROUTE.REPORT_CREATIVE_ROUTE,
		account: ROUTE_ACCOUNT.ACCOUNT_LIST_ROUTE,
		report_type: ROUTE.REPORT_TYPE_ROUTE,
		report_all_type: ROUTE.REPORT_ALL_TYPE_ROUTE,
		report_data_range: ROUTE.REPORT_DATA_RANGE_ROUTE,
		report_format_types: ROUTE.REPORT_FORMAT_TYPE_ROUTE,
		report_fields: ROUTE.REPORT_FIELD_ROUTE,
		report_dimensions: ROUTE.REPORT_GET_DIMENSIONS_ROUTE,
		report_metrics: ROUTE.REPORT_GET_METRICS_ROUTE,
		scheduler_type: ROUTE.REPORT_GET_SCHEDULER_TYPES_ROUTE,
		scheduler: ROUTE.REPORT_SCHEDULER_ROUTE,
		report_country: ROUTE.REPORT_COUNTRY_ROUTE,
		report_country_campaign: ROUTE.REPORT_COUNTRY_CAMPAIGN_ROUTE,
		report_list_type: ROUTE.REPORT_DEMAND_LIST_ROUTE,
		report_demand_store_attribution:
			ROUTE.REPORT_DEMAND_STORE_ATTRIBUTION_ROUTE,
		report_demand_geo: ROUTE.REPORT_DEMAND_GEO_ROUTE,
		report_demand_campaign_insight:
			ROUTE.REPORT_DEMAND_CAMPAIGN_INSIGHT_ROUTE,
		report_demand_unique_devices: ROUTE.REPORT_DEMAND_UNIQUE_DEVICES_ROUTE,
		report_on_demand: ROUTE.REPORT_ON_DEMAND_ROUTE,
		report_on_demand_download: ROUTE.REPORT_DOWNLOAD_ON_DEMAND_ROUTE,
	};
}

function getFilters(filters: SchedulerReportFilters): string {
	let filter = "";

	const emailSub = !!filters.email_subject ? filters.email_subject : "";
	const typeScheduler = !!filters.scheduler_type
		? filters.scheduler_type
		: "";
	const startDate = !!filters.start_date ? filters.start_date : "";
	const endDate = !!filters.end_date ? filters.end_date : "";
	const status = !!filters.status ? filters.status : "";
	const user = !!filters.user_id ? filters.user_id : "";

	var symbol = "";

	if (emailSub) {
		filter += `${symbol}filters[email_subject]=${emailSub}`;
		symbol = "&";
	}

	if (typeScheduler) {
		filter += `${symbol}filters[scheduler_type]=${typeScheduler}`;
		symbol = "&";
	}

	if (startDate) {
		filter += `${symbol}filters[start_date]=${startDate}`;
		symbol = "&";
	}

	if (endDate) {
		filter += `${symbol}filters[end_date]=${endDate}`;
		symbol = "&";
	}

	if (status) {
		filter += `${symbol}filters[status]=${status}`;
		symbol = "&";
	}

	if (user) {
		filter += `${symbol}filters[user_id]=${user}`;
		symbol = "&";
	}

	return filter;
}

function getFiltersReportOnDemand(filters: any): string {
	let filter = "";

	const id = !!filters.id ? filters.id : "";
	const reporType = !!filters.report_type ? filters.report_type : "";
	const parameters = !!filters.parameters ? filters.parameters : "";
	const status = !!filters.status ? filters.status : "";
	const email = !!filters.email ? filters.email : "";
	const startDate = !!filters.start_date ? filters.start_date : "";
	const finishDate = !!filters.finish_date ? filters.finish_date : "";

	var symbol = "";

	if (id) {
		filter += `${symbol}filters[id]=${id}`;
		symbol = "&";
	}

	if (reporType) {
		filter += `${symbol}filters[report_type]=${reporType}`;
		symbol = "&";
	}

	if (parameters) {
		filter += `${symbol}filters[parameters]=${parameters}`;
		symbol = "&";
	}

	if (email) {
		filter += `${symbol}filters[email]=${email}`;
		symbol = "&";
	}

	if (status) {
		filter += `${symbol}filters[status]=${status}`;
		symbol = "&";
	}

	if (startDate) {
		filter += `${symbol}filters[start_date]=${startDate}`;
		symbol = "&";
	}

	if (finishDate) {
		filter += `${symbol}filters[finish_date]=${finishDate}`;
		symbol = "&";
	}

	return filter;
}

function filters(key: string, filter: any): string {
	let fil = "";
	var symbol = "";

	if (!isEmpty(filter)) {
		filter.forEach((c) => {
			fil += `${symbol}filters[` + key + `][]=${parseEncode(c)}`;
			symbol = "&";
		});
	}
	return fil;
}

export default new ReportService();
