import ProfileService, { ProviderProfile } from "@/services/profile-service";
import AccountService from "@/services/account-service";
import { catchError } from "@/store/Util";
import { Profile } from "@/interfaces/user";
import { Commit } from "vuex";

export const ProfileModule = {
	namespaced: true,
	state: () => ({
		profile: {} as Profile,
		account: null,
		lookFeel: null,
	}),
	mutations: {
		SET_PROFILE(state: any, _profile: Profile = {} as Profile) {
			state.profile = _profile;
		},
		SET_ACCOUNT(state: any, _account = null) {
			state.account = _account;
		},
		SET_LOOK_FEEL(state: any, _lookFeel = null) {
			state.lookFeel = _lookFeel;
		},
		SET_PROFILE_LANGUAGE(state: { profile: Profile }, language: string) {
			state.profile.language = language;
		},
		UPDATE_PROFILE<T>(
			state: { profile: Profile },
			data: { key: string; value: T }
		) {
			state.profile[data.key] = data.value;
		},
	},
	getters: {
		profile(state: { profile: Profile }) {
			return state.profile;
		},
		getCurrentLanguage(state: { profile: Profile }) {
			return state.profile.language;
		},
		account(state: any) {
			return state.account;
		},
		lookFeel(state: any) {
			return state.lookFeel;
		},
		logo(state: any) {
			return state.profile?.logo;
		},
		isRolReport(state: any) {
			return state.profile?.roles
				.map((role) => role.name)
				.includes("account-report");
		},
	},
	actions: {
		async fetchProfile({ commit }) {
			try {
				const response = await ProfileService.profile();
				const account = await AccountService.account();
				commit("SET_PROFILE", ProviderProfile(response, account));
				commit("SET_ACCOUNT", account);
				return await Promise.resolve(
					ProviderProfile(response, account)
				);
			} catch (error) {
				catchError(this, error, { to: "Root" });
				return await Promise.reject(error);
			}
		},
		async fetchLookAndFeel({ commit }: any, params: { domain: string }) {
			try {
				const response = await ProfileService.getLookAndFeelLogin(
					params
				);
				commit("SET_LOOK_FEEL", response);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error, { to: "Root" });
				return await Promise.reject(error);
			}
		},
		async setProfileLanguage(
			{ commit }: { commit: Commit },
			language: string
		) {
			return commit("SET_PROFILE_LANGUAGE", language);
		},
		async setProfileData<T>(
			{ commit }: { commit: Commit },
			data: { key: string; value: T }
		) {
			return commit("UPDATE_PROFILE", data);
		},
	},
};
