import {
	AxiosGet,
	GetData,
	GetErrors,
	GetMessage,
} from "@/services/axios-service";
import { AxiosResponse } from "axios";
const ROUTE = require("../api/routes").REPORT;

class DashboardService {
	async reportList() {
		try {
			const response = await AxiosGet(ROUTE.REPORT_LIST_ROUTE);
			return Promise.resolve(prepareDashboardData(response));
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error),
			});
		}
	}
}

function prepareDashboardData(response: AxiosResponse<any>) {
	const result: Array<any> = GetData(response);
	return result.map((r: any, index: number) => {
		return {
			key: index,
			code: r.code,
			tab: r.name,
			iframe_src: r.src,
			icon: "", // mdi-poll
			disabled: false,
		};
	});
}

export default new DashboardService();
