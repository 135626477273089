import { V10 } from "@/interfaces/persons/v10/route";
import {
	GetErrors,
	GetMessage,
	AxiosPostDownload,
	AxiosGetDownload,
} from "./axios-service";

const { AUDIENCE: ROUTE_AUDIENCE }: V10 = require("../api/routes").V10;

class DownloadService {
	/**
	 * @param params An object with the following struct
	 * - `filters`: filters to be sended to backend
	 * - `title`: name of file
	 * - `type`: name of route to be fetched
	 * @returns
	 */
	async download(params: {
		filters?: any;
		title: string;
		type: string;
		method?: string;
	}) {
		try {
			if (params.method == "GET") {
				await AxiosGetDownload(
					matchedRoutes[params.type],
					params.title
				);
			} else {
				await AxiosPostDownload(
					matchedRoutes[params.type],
					params.title,
					params.filters
				);
			}

			return Promise.resolve({ success: true });
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error),
			});
		}
	}
}

export const matchedRoutes = {
	gender_age: ROUTE_AUDIENCE.DOWNLOAD_METRICS,
	attributions: ROUTE_AUDIENCE.DOWNLOAD_ATTRIBUTIONS,
	rich_attributions_totals: ROUTE_AUDIENCE.DOWNLOAD_RICH_ATTRIBUTIONS,
	rich_attributions_daily: ROUTE_AUDIENCE.DOWNLOAD_RICH_ATTRIBUTIONS_DAILY
};

export default new DownloadService();
