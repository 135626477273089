import {
	GetData,
	GetErrors,
	GetMessage,
	AxiosPost,
} from "@/services/axios-service";

import i18n from "@/plugins/i18n";

const ROUTE = require("../api/routes").PERSON;

import {
	getInitFilterGraphic,
	prepareFilterGraphic,
} from "@/utils/persons/audience";

import { FilterAudience } from "@/interfaces/audience";

class GraphicsAudienceService {
	async prepareFiltersAudience(filters: any, models: any) {
		try {
			const newFilters = await getInitFilterGraphic();

			const resultFilters = await prepareFilterGraphic(
				newFilters,
				filters,
				models
			);

			return Promise.resolve(resultFilters);
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error),
			});
		}
	}

	async filterAudience(filters: FilterAudience) {
		try {
			const response = await AxiosPost(
				ROUTE.GENERATE.PERSON_FILTER_AUDIENCE_ROUTE,
				{ filters: filters }
			);

			const data = GetData(response);

			/**
			 * Response
			 * string [ processing, failed ]
			 * object -> ok
			 */
			if (typeof data === "string") {
				const message = `persons.messages.apply-filters.${data}`;
				return Promise.reject({
					status: data,
					success: false,
					message: i18n.t(message),
					errors: [],
				});
			}
			return Promise.resolve(data);
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error),
			});
		}
	}
}

export default new GraphicsAudienceService();
