import DownloadService from "@/services/download-service";
export const DownloadModule = {
	namespaced: true,
	state: () => ({}),
	mutations: {},
	getters: {},
	actions: {
		/**
		 * Download File
		 * @param params { filters?: any; title: string; type: string, method: string }
		 * @returns
		 */
		async downloadFile({}: any, params: any) {
			return await DownloadService.download(params);
		},
	},
};
