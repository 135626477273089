import { isAuthenticated } from "./utils";

/**
 * Meta:
 * requiresAuth: Requiere autenticacion
 * middleware: Proteccion para las rutas
 */

export default async function ({ from, next, router, to }) {
	if (!isAuthenticated()) {
		return router
			.push({
				name: "Login",
			})
			.catch((failure: any) => {
				//console.error("Auth error on push", { failure });
			});
	}

	return next();
}
