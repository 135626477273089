import * as am5 from "@amcharts/amcharts5";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import { isEmpty, isNull, isUndefined } from "lodash";
import i18n from "@/plugins/i18n";
import { generateDateFormat } from "@/utils/convert";
import { ExportData, InfoData } from "@/interfaces/export";
import { omitExportPDF } from "./index";

const ommitDispose: string[] = [
	"categories_stacked-bar",
	"brands_grouped",
	"cities_grouped",
	"names_grouped",
];

class AmchartGlobal {
	exportings: Array<ExportData> = [];

	async dispose() {
		am5.array.each(am5.registry.rootElements, function (root: am5.Root) {
			if (
				isUndefined(root?.dom?.id) ||
				ommitDispose.includes(root.dom.id)
			)
				return;
			root.dispose();
		});
	}

	async isPassedSource(id: string, source: any[]) {
		if (omitExportPDF().includes(id)) return true;
		return !isEmpty(source) && !isNull(source[0]?.uniques);
	}

	async enableExporting(id: any, root: am5.Root, data: any[]) {
		let exporting = am5plugins_exporting.Exporting.new(root, {
			menu: am5plugins_exporting.ExportingMenu.new(root, {
				align: "right",
				valign: "top",
				container: document.getElementById(id + "_export") || undefined
			}),
			filePrefix: `${id}_${generateDateFormat()}`,
			dataSource: data.map(item => {
				let { type, translation, ...rest } = item;
				return rest;
			}),
			pngOptions: {
				maintainPixelRatio: true,
				disabled: true,
			},
			jpgOptions: {
				maintainPixelRatio: true,
				disabled: false,
			},
			pdfOptions: {
				disabled: true,
			},
			pdfdataOptions: {
				disabled: true,
			},
			xlsxOptions: {
				disabled: true,
			},
			csvOptions: {
				disabled: false,
			},
			jsonOptions: {
				disabled: true,
			},
			htmlOptions: {
				disabled: true,
			},
			printOptions: {
				disabled: true,
			},
		});

		exporting?.get("menu")?.set("items", [
			{
				type: "separator",
				label: i18n.t("exporting.title").toString(),
			},
			{
				type: "format",
				format: "jpg",
				label: i18n.t("exporting.jpg").toString(),
			},
			{
				type: "separator",
			},
			{
				type: "format",
				format: "csv",
				label: i18n.t("exporting.csv").toString(),
			},
		]);

		return exporting;
	}

	async addExporting(exportData: ExportData) {
		if (omitExportPDF().includes(exportData.id)) return;

		const index = this.exportings.findIndex((e) => e.id == exportData.id);

		if (index != -1) {
			this.exportings[index] = exportData;
		} else {
			this.exportings.push(exportData);
		}
	}

	async getAllExporting() {
		return this.exportings;
	}

	async resetExporting() {
		this.exportings = [];
	}

	async prepareExportParams(
		params: { id: string; infoData: InfoData },
		exporting: am5plugins_exporting.Exporting
	) {
		return {
			id: params.id,
			infoData: params.infoData,
			exporting: exporting,
		} as ExportData;
	}
}

export default new AmchartGlobal();
