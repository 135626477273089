import { Geo, GeoFilterData, Person, Pois, PoisFilterData } from "./person";

export type PersonKeyof = keyof Person;

export type GeoFilterDataKeyof = keyof GeoFilterData;
export type PoisFilterDataKeyof = keyof PoisFilterData;

export type GeoKeyof = keyof Geo;

export type PoisKeyof = keyof Pois;

export enum PersonKey {
	COUNTRY_GLOBAL = "country_global",
	AUDIENCE_TYPE = "audience_type",
	GEO = "geo",
	POIS = "pois",
	PRIVATE = "privates",
	AUDIENTE = "audience",
	STORE_ATTRIBUTION = "store_attribution",
}

export enum PersonGeoKey {
	STATES = "states",
	CITIES = "cities",
	NEIGHBORHOODS = "neighborhoods",
}

export enum PersonPoisKey {
	CATEGORIES = "categories",
	SUBCATEGORIES = "subcategories",
	BRANDS = "brands",
	NAMES = "names",
}

export enum MatchFilter {
	categories = "categoria",
	subcategories = "subcategoria",
	brands = "marca",
	names = "nombre",
}

export enum PersonPrivateKey {
	PRIVATES = "privates",
}

export enum TypeFilterKey {
	SELECTED = "selected",
	PRE = "pre",
	AND = "and",
}

export enum GraphicType {
	POIS = "pois",
}

export enum AnalizeType {
	GEO = "analize_geo",
	POIS = "analize_pois",
}

export enum PersonTab {
	POIS = 0,
	AUDIENCE = 1,
	STORE_ATTRIBUTION = 2,
}

export enum ActivePanelTab {
	COUNTRY = 0,
	AUDIENCE_TYPE = 1,
	GEO = 2,
	POIS = 3,
}

export enum TotalType {
	TOTAL = "total",
	SHOWN = "shown",
}

export enum SelectedType {
	UNSELECTED = "unselected",
	SELECTED = "selected",
	CHECKED = "checked",
}

export enum PersonStorageKey {
	STORED_TIMESTAND = "stored_timestamp",
	STORED_PERSON = "stored_person",
	STORED_RESOURCE = "stored_resource",
	STORED_EXPANSION = "stored_expansion",
	STORED_ACTION = "stored_action",
}