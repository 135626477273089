
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { isEmpty } from "lodash";

export default Vue.extend({
    
    name: "CustomizeRowGrid",
    props: { },
    components: {},

    data: () => ({}),

    created() {},
	mounted() {},

    computed: {
        ...mapGetters("breadcrumbsM", [
			"getHistoryData",
		]),

        isLink(){
            return this.params.link;
        },

        isSeccion(){
            return this.params.redirecCustom.hasOwnProperty("section");
        },

        getID(){
            return this.params.data.id;
        },

        getValueField(){
            return this.params.fieldText;
        },

        getText(){
            return this.getPrefix + this.params.data[this.getValueField] + this.getSuffix;
        },

        getPrefix(){
            return this.params.prefix;
        },

        getSuffix(){
            return this.params.suffix;
        },

        getToRedirectCustom(){
            return this.params.redirecCustom.to;
        },

        getEntityRedirectCustom(){
            return this.params.redirecCustom.entity;
        },

        getSeccionRedirectCustom(){
            return this.params.redirecCustom.section;
        }, 
    },

    methods: {
        ...mapActions("breadcrumbsM", ["addHistory"]),

        getHistoryEntity(){
            return {
                id: this.params.data.id,
                name: this.params.data.name,
                external_id: this.params.data.external_id,
                advertiser: null
            }
        },

        async redirectTo(){
            if(this.getToRedirectCustom == "CampaignsIndex"){
                await this.saveHistoryData("advertisers", this.getHistoryEntity());
            }
            if(this.getToRedirectCustom == "LineItemList"){
                await this.saveHistoryData("campaign", this.getHistoryEntity());
            }
            this.$router.push(this.getParams());
        },

        getParams(){
            let obj = {
                name: this.getToRedirectCustom
            };
            if(this.isSeccion){
                obj["params"] = { [this.getEntityRedirectCustom]: this.getID };
                obj["query"] = { section: this.getSeccionRedirectCustom };
            }else{
                obj["query"] = { [this.getEntityRedirectCustom]: String(this.getID) }
            }
            return obj;
        },

        async saveHistoryData(_view: string, _data: any){
			let valuesHistory = await this.getHistoryData(_view);
			if(isEmpty(valuesHistory)){
				await this.addHistory({view: _view, data: _data});
			}else{
				if(!valuesHistory.some((item: any) => item.id == _data.id)){
					await this.addHistory({view: _view, data: _data});	
				}
			}
		},

    },
    
    watch:{

    }

});
