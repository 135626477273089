export interface ReportFilters {
	advertiser_id: string;
	campaign_id: string;
	line_item_id: string;
	creative_id: string;
}

export interface ReportRun {
	report_name: string;
	data_range: string;
	type: string;
	dimensions: Array<string>;
	metrics: Array<string>;
	filters: ReportFilters;
}

export interface SchedulerReportFilters {
	email_subject: string;
	scheduler_type: string;
	start_date: string;
	end_date: string;
	status: string;
	user_id: string;
}

export enum EnumStatus {
	ERROR = "error",
	PENDING = "in_progress",
	SUCCESS = "success",
	EMPTY = "empty",
}

export enum EnumReportType {
	CSV = "csv",
	XLS = "xls",
	NO_TYPE = "",
}

export enum DownloadType {
	csv = "text/csv",
	xls = "pplication/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
}

export interface ReportStatus {
	id?: number;
	status: EnumStatus;
}

export interface ReportGetParam {
	id: number;
	type: EnumReportType.CSV | EnumReportType.CSV;
}

export interface ResultData<T = ReportStatus | string> {
	success: Boolean;
	response?: T;
	message: string;
}

export class ResultDataEntity implements ResultData {
	success: Boolean = false;
	response?: ReportStatus | string;
	message: string = "";

	constructor(params?: ResultData) {
		if (params) {
			this.success = params.success;
			this.response = params.response;
			this.message = params.message;
		}
	}
}
