import FilterEntity from "@/models/FilterEntity";

export const CustomizerFilterModule = {
    namespaced: true,
    state: () => ({
        AdvertiserFilter: new FilterEntity(),
        CampaignFilter: new FilterEntity(),
        lineItemFilter: new FilterEntity(),
        CreativeFilter: new FilterEntity()
    }),
    mutations: {
        SET_FILTERS(state, params) {
			state[params.view] = params.filters;
		},
    },
    getters: {
        getFiltersEntity: (state) => (view: string) => {
			return state[view];
		},
    },
    actions: {
        saveFiltersEntity({commit}, params) {
            return new Promise((resolve) => {
				commit("SET_FILTERS", params);
				resolve(params);
			});
        },
        resetFilterEntity({commit}, params){
            return new Promise((resolve) => {
				commit("SET_FILTERS", params);
				resolve(params);
			});
        }
    }
}