import {
	ConfigPaginate,
	ConfigPost,
	ConfigPostFilter,
	Geography,
} from "@/interfaces/persons/v10/response";
import { TotalTypeAll } from "@/interfaces/persons/v10/select_all";
import { GetErrors, GetMessage } from "@/services/axios-service";

export class ConfigPaginateEntity implements ConfigPaginate {
	mode: "paginated" = "paginated";
	page: number = 1;
	limit: number = 25;
	search?: string;
	filters?: any;
	country_code?: number;

	constructor(parametters?: { country_code?: number }) {
		this.country_code = parametters?.country_code;
	}
}

export class GeographyEntity implements Geography {
	codigo_estado: number[] = [];
	codigo_ciudad: number[] = [];
	codigo_barrio: number[] = [];
	_like_dpto: string = "";
	_like_ciudad: string = "";
	_like_barrio: string = "";
	constructor() {}

	addGeography(
		filter: "states" | "cities" | "neighborhoods",
		likeData: TotalTypeAll,
		value: number[]
	) {
		const { total, shown } = likeData;

		this[matchedFilterlikeData.geography[filter].filter] = total.checked
			? []
			: value;

		if (total.checked && total.term) {
			this[matchedFilterlikeData.geography[filter].like] = total.term;
		} else {
			delete this[matchedFilterlikeData.geography[filter].like];
		}
	}

	setFilter(
		type: "categories" | "subcategories" | "brands" | "names",
		value: number[]
	) {
		this[type] = value;
	}

	setFilterLike(type: string, value: string) {
		this[type] = value;
	}
}

export class ConfigPostFilterEntity implements ConfigPostFilter {
	// geo
	codigo_estado?: number[];
	_like_estado?: string;

	codigo_municipio?: number[];
	_like_municipio?: string;

	codigo_barrio?: number[];
	_like_barrio?: string;

	// pois
	geography: Geography[] = [];

	categoria?: string[];

	subcategoria?: string[];
	_like_categoria?: string;

	marca?: string[];
	_like_subcategoria?: string;

	nombre?: string[];
	_like_nombre?: string;

	layer_name?: string;

	country_name?: string;

	constructor() {}
}

const matchedFilterlikeData = {
	geography: {
		states: {
			filter: "codigo_estado",
			like: "_like_dpto",
		},
		cities: {
			filter: "codigo_ciudad",
			like: "_like_ciudad",
		},
		neighborhoods: {
			filter: "codigo_barrio",
			like: "_like_barrio",
		},
	},
	geo: {
		states: {
			filter: "codigo_estado",
			like: "_like_estado",
		},
		cities: {
			filter: "codigo_municipio",
			like: "_like_municipio",
		},
		neighborhoods: {
			filter: "codigo_barrio",
			like: "_like_barrio",
		},
	},
	pois: {
		categories: {
			filter: "categoria",
			like: "_like_categoria",
		},
		subcategories: {
			filter: "subcategoria",
			like: "_like_subcategoria",
		},
		brands: {
			filter: "marca",
			like: "_like_marca",
		},
		names: {
			filter: "nombre",
			like: "_like_nombre",
		},
		privates: {
			filter: "privates",
			like: "layer_name",
		},
	},
};

export class ConfigPostEntity implements ConfigPost {
	country_code?: number;
	audience_type?: number;
	filters: ConfigPostFilter = new ConfigPostFilterEntity();
	layers: number[] = [];
	constructor(parametters?: {
		country_code?: number;
		audience_type?: number;
	}) {
		this.country_code = parametters?.country_code;
		this.audience_type = parametters?.audience_type;
	}

	setFilterGeography(geography: Geography) {
		this.filters?.geography.push(geography);
	}

	addLayers(layers: number[]) {
		this.layers = layers;
	}

	addGeoFilter(
		filter: "states" | "cities" | "neighborhoods",
		likeData: TotalTypeAll,
		value: number[]
	) {
		const { total, shown } = likeData;

		this.filters[matchedFilterlikeData.geo[filter].filter] = total.checked
			? []
			: value;
		this.filters[matchedFilterlikeData.geo[filter].like] = total.checked
			? total.term
			: undefined;
	}

	addPoisFilter(
		filter:
			| "categories"
			| "subcategories"
			| "brands"
			| "names"
			| "privates",
		likeData: TotalTypeAll,
		value: string[]
	) {
		const { total, shown } = likeData;

		this.filters[matchedFilterlikeData.pois[filter].filter] = total.checked
			? []
			: value;

		this.filters[matchedFilterlikeData.pois[filter].like] = total.checked
			? total.term
			: undefined;
	}
}

export class RejectError {
	success = false;
	message: string;
	errors: any;

	constructor(error: any) {
		this.message = GetMessage(error);
		this.errors = GetErrors(error);
	}
}
