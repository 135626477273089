import { ItemGraphic } from "@/interfaces/graphic";
import { ElementData, Geo, Pois } from "@/interfaces/persons/v10/person";
import {
	ConfigPost,
	GraphicType,
	ResultData,
} from "@/interfaces/persons/v10/response";
import { ActionKeyof } from "@/interfaces/persons/v10/route";
import { PersonEntity, countryDefault } from "@/models/persons/v10/Person";
import { QueryParamEntity } from "@/models/persons/v10/Query";
import { PostDataEntity } from "@/models/persons/v10/Query/Pois";
import { LikeAllDataEntity } from "@/models/persons/v10/SelectLike";
import personService from "@/services/persons/v10/person-service";
import { catchError } from "@/store/Util";
import { Notification } from "@/interfaces/proccess";
import {
	attemptAnalizeAudience,
	attemptFetchGraphic,
	fakeStoreAttributionResult,
	geoGraphTypes,
	poisGraphTypes,
	reachGraphTypes,
} from "./utils";
import {
	ButtonActionEntity,
	getPoisActions,
} from "@/models/persons/v10/Tabs/Pois/ButtonAction";
import { PersonGeoKey, PersonPoisKey } from "@/interfaces/persons/v10/types";
import {
	PersistentPoisDataEntity,
	PersonResourceEntity,
	SelectedDataEntity,
} from "@/models/persons/v10/Implements";
import { Mode } from "@/interfaces/persons/v10/query/global";
import { TypeLoading } from "@/interfaces/loading";
import notificationService from "@/services/notification-service";
import i18n from "@/plugins/i18n";
import { RejectError } from "@/models/persons/v10/response";
import { isEmpty, isUndefined } from "lodash";
import { getCurrentURLParams } from "@/utils/services-global";

export const PersonModule = {
	namespaced: true,
	state: () => ({
		resources: new PersonResourceEntity(),
		person: new PersonEntity(),
		like_all: new LikeAllDataEntity(),
		query_params: new QueryParamEntity(),
		post_data: new PostDataEntity(),
		pois_actions: getPoisActions(),
		persistent: new PersistentPoisDataEntity(),
	}),
	mutations: {
		INIT_POIS_ACTION(state: { pois_actions: ButtonActionEntity[] }) {
			state.pois_actions = getPoisActions();
			state.pois_actions.map((p) => (p.disabled = true));
		},

		SET_FROM_STORAGE(
			state: {
				resources: PersonResourceEntity;
				pois_actions: ButtonActionEntity[];
			},
			params: {
				resources: PersonResourceEntity;
				pois_actions: ButtonActionEntity[];
			}
		) {
			if (!isUndefined(params.resources)) {
				state.resources = new PersonResourceEntity(params.resources);
			}
			if (!isUndefined(params.pois_actions)) {
				state.pois_actions = params.pois_actions.map(
					(a) =>
						new ButtonActionEntity(
							a.type,
							a.title,
							a.icon,
							a.disabled
						)
				);
			}
		},

		SET_RESOURCE(
			state: { resources: PersonResourceEntity },
			params: { key: string; value: any[] }
		) {
			//state.resources.setResource(params.key, params.value);
			state.resources[params.key] = params.value;
		},
		CHECK_COUNTRY_CODE(state: {
			person: PersonEntity;
			resources: PersonResourceEntity;
		}) {
			// Get Id and Value from the state
			let { id, value } = state.person.country_global;

			// Refactor all continent' items on a single array
			let countries = state.resources.countries
				.map((continent) => {
					return continent.items;
				})
				.flat();

			// Verify if the name and country doesn't exists
			if (
				!countries.some(
					(country) => country.id === id && country.value === value
				)
			) {
				state.person.country_global = new SelectedDataEntity({
					id: countryDefault.id,
					value: countryDefault.value,
				});
			}
		},
		RESET_PERSON(
			state: { person: PersonEntity },
			country: SelectedDataEntity,
			audience_type?: SelectedDataEntity
		) {
			state.person = new PersonEntity(country, audience_type);
		},
		SET_LOADING(
			state: { person: PersonEntity },
			params: { type: string; loading: Boolean }
		) {
			state.person.loading.action[params.type] = params.loading;
		},
		SET_RESULT_DATA(
			state: { person: PersonEntity },
			params: { type: string; response: ResultData }
		) {
			state.person.response.action[params.type] = params.response;
		},
		SET_GRAPHIC(
			state: { person: PersonEntity },
			params: {
				type: string;
				key: string;
				loading: Boolean;
				source: ItemGraphic[];
			}
		) {
			state.person.graphics[params.type][params.key] = {
				loading: params.loading,
				data: params.source,
			};
		},
	},
	getters: {
		getPersistent(state: { persistent: PersistentPoisDataEntity }) {
			return state.persistent;
		},
		getCountry(state: { persistent: PersistentPoisDataEntity }) {
			return state.persistent.getCountry();
		},
		getCampaigns(state: { persistent: PersistentPoisDataEntity }) {
			return state.persistent.campaigns;
		},
		getResource:
			(state: { resources: PersonResourceEntity }) =>
			(params: { key: string }) => {
				return state.resources[params.key];
			},
		getPerson(state: { person: PersonEntity }) {
			return state.person;
		},
		getLikeAll(state: { like_all: LikeAllDataEntity }) {
			return state.like_all;
		},
		getQueryParams(state: { query_params: QueryParamEntity }) {
			return state.query_params;
		},
		getPostData(state: { post_data: PostDataEntity }) {
			return state.post_data;
		},
		getPoisActions(state: { pois_actions: ButtonActionEntity[] }) {
			return state.pois_actions;
		},
		getLoading: (state: { person: PersonEntity }) => (key: ActionKeyof) => {
			return state.person.loading.action[key];
		},
		getResponseData:
			(state: { person: PersonEntity }) => (key: ActionKeyof) => {
				return state.person.response.action[key];
			},
		getGraphicResponse:
			(state: { person: PersonEntity }) =>
			(type: string, key: string) => {
				return state.person.graphics[type][key];
			},
		isBackFromAudience: () => {
			const params = getCurrentURLParams();
			return params.has("from") && params.get("from") === "audience";
		},
	},
	actions: {
		async setResource<T>({ commit }, params: { key: string; value: T[] }) {
			await commit("SET_RESOURCE", params);
		},
		async saveDataToStorage(
			{
				state,
			}: {
				state: {
					person: PersonEntity;
					resources: PersonResourceEntity;
					pois_actions: ButtonActionEntity[];
				};
			},
			params: { items: any[] }
		) {
			let paramsTosave: {
				person: PersonEntity;
				resources: PersonResourceEntity;
				items: any[];
				pois_actions: ButtonActionEntity[];
			} = {
				person: state.person,
				resources: state.resources,
				items: params.items,
				pois_actions: state.pois_actions,
			};
			const response = await personService.saveDataToStorage(
				paramsTosave
			);
			return await Promise.resolve(response);
		},
		async clearDataToStorage({}) {
			await personService.clearDataToStorage();
		},
		async getSavedDataToStorage({ commit }) {
			const isValidTimeStand = personService.isValidTimeStand();

			if (!isValidTimeStand) {
				await personService.clearDataToStorage();
			}

			let timeStand: number | undefined;
			let person: PersonEntity | undefined = undefined;
			let resources: PersonResourceEntity | undefined = undefined;
			let items: any[] | undefined = undefined;
			let pois_actions: ButtonActionEntity[] | undefined = undefined;

			if (isValidTimeStand) {
				const storedData = await personService.getSavedDataToStorage();
				({ timeStand, person, resources, items, pois_actions } =
					storedData);
				commit("SET_FROM_STORAGE", { resources, pois_actions });
			}

			return await Promise.resolve({
				timeStand,
				person,
				resources,
				items,
				pois_actions,
			});
		},
		async resetPerson(
			{ commit },
			params: { audience_type: ElementData; country: ElementData }
		) {
			return await commit(
				"RESET_PERSON",
				params.country,
				params.audience_type
			);
		},
		async initPoisActions({ commit }) {
			commit("INIT_POIS_ACTION");
		},
		async setLoading(
			{ commit },
			params: { key: string; loading: Boolean }
		) {
			commit("SET_LOADING", params);
		},
		async setResultData(
			{ commit },
			params: { type: string; response: ResultData }
		) {
			commit("SET_RESULT_DATA", params);
		},
		async fetchData({}, params: { type: string; filters?: any }) {
			try {
				const response = await personService.fetchData(params);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async fetchDataPaginated({}, params: { filters?: any; type: string }) {
			try {
				const response = await personService.fetchPaginated(params);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async fetchGetPaginated(
			{ getters },
			params: {
				type: PersonGeoKey | PersonPoisKey;
				country_global?: ElementData;
				pre: Geo | Pois;
			}
		) {
			try {
				const query = getters.getQueryParams.getQuery(
					params.type,
					params.country_global,
					params.pre
				);
				const dataParams = {
					query,
					type: params.type,
					pre: params.pre,
				};
				const response = await personService.fetchGetPaginated(
					dataParams
				);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async fetchPostPaginated(
			{ dispatch },
			params: { type: PersonPoisKey; mode?: Mode }
		) {
			try {
				const postData = await dispatch("getPostFilter", {
					type: params.type,
					mode: params.mode,
				});

				const response = await personService.fetchPostPaginated({
					type: params.type,
					postData,
				});
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async postDataPaginated({}, params: { filters?: any; type: string }) {
			try {
				const response = await personService.postPaginated(params);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async postData({}, params: { type: string; body: ConfigPost }) {
			try {
				const response = await personService.postData(
					params.type,
					params.body
				);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		/**
		 * Se dispara la llamada de los graficos de pois|geo (sync)
		 */
		async fetchAnalizeAudience({}) {
			return await attemptAnalizeAudience();
		},

		/**
		 * Se dispara la llamada de los graficos de pois|geo (sync)
		 */
		fetchGetGraphicData(
			{ commit, rootGetters },
			isAnalizePois: Boolean = false
		) {
			const graphics: GraphicType[] = isAnalizePois
				? poisGraphTypes
				: geoGraphTypes;

			graphics.forEach((pois) => {
				attemptFetchGraphic(commit, pois);
			});

			if (isAnalizePois) {
				const pois = rootGetters["audience/getPoisCount"];
				let source: ItemGraphic[] = [];

				if (pois.public)
					source.push({ name: "PUBLIC", uniques: pois.public });
				if (pois.private)
					source.push({ name: "PRIVATE", uniques: pois.private });

				commit("SET_GRAPHIC", {
					type: "pois",
					key: "pois",
					loading: false,
					source,
				});
			}
		},

		/**
		 *  Calculate Reach {geo|pois}
		 * @param person
		 * @returns
		 */
		async fetchCalculateReach({ commit }, person: PersonEntity) {
			const keyByAudienceType: "geo" | "pois" =
				person.isTypeAudiencePois() ? "pois" : "geo";
			const graph: GraphicType | undefined = reachGraphTypes.find(
				(r) => r.key === keyByAudienceType
			);
			if (!graph) return;
			return attemptFetchGraphic(commit, graph);
		},

		async fetchStoreAttribution(
			{},
			data: {
				country_code: number;
				campaign_ids: number[];
			}
		) {
			try {
				const response: ResultData =
					await personService.fetchGetWithData({
						type: "store_attribution",
						data: data,
					});

				if (
					isEmpty(response.response) &&
					response.message === "no data."
				) {
					if (
						process.env.VUE_APP_ENABLE_STORE_ATTRIBUTION_FAKE ===
						"true"
					) {
						return await Promise.resolve(
							fakeStoreAttributionResult
						);
					}

					notificationService.notifyWarning(this, {
						message: i18n.t(
							"persons.labels.store-attribution.no-data"
						),
					} as Notification);
					return Promise.reject(response);
				}

				if (isUndefined(response.response)) {
					catchError(this, response);
					return Promise.reject(response);
				}
				return await Promise.resolve(response);
			} catch (error) {
				console.error("fetchStoreAttribution", error);
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async getPostFilter(
			{ state },
			params: { type: PersonPoisKey; mode?: Mode }
		) {
			try {
				let person: PersonEntity = state.person;

				let postParams = await person.getPostParamByFilter(
					state.post_data,
					params.type,
					params.mode
				);

				return postParams;
			} catch (error) {
				console.error("getPostFilter", { params, error });
			}
		},

		async savePois({ dispatch }, param: { name: string }) {
			try {
				dispatch("loading/setLoadingData", TypeLoading.loading, {
					root: true,
				});
				await personService.postSavePois(param.name);

				notificationService.notifySuccess(this, {
					message: i18n.t("success"),
				} as Notification);
			} catch (err) {
				if (err instanceof RejectError) {
					notificationService.notifyError(this, {
						details: err.errors,
					} as Notification);
				}
				dispatch("loading/setLoadingData", undefined, { root: true });
			}

			dispatch("loading/setLoadingData", undefined, { root: true });
		},

		async saveGeoFencing(
			{ dispatch },
			param: { country_code: number; name: string; radio: number }
		) {
			try {
				dispatch("loading/setLoadingData", TypeLoading.loading, {
					root: true,
				});
				await personService.postSaveGeoFencing(param);

				notificationService.notifySuccess(this, {
					message: i18n.t("success"),
				} as Notification);
			} catch (err) {
				if (err instanceof RejectError) {
					notificationService.notifyError(this, {
						details: err.errors,
					} as Notification);
				}
				dispatch("loading/setLoadingData", undefined, { root: true });
			}

			dispatch("loading/setLoadingData", undefined, { root: true });
		},
	},
};
