import { Paginated, SortingOption } from "@/interfaces/paginated";
import {
	SegmentDataCreate,
	SegmentFilter,
	SegmentTag,
	SegmentTreeView,
	SegmentSearchOption,
} from "@/interfaces/segment";
import {
	AxiosGet,
	AxiosPost,
	AxiosPatch,
	GetData,
	GetErrors,
	GetMessage,
} from "@/services/axios-service";

import {
	prepareParams,
	modes,
	downloadFile,
	getURL,
} from "@/utils/services-global";
import { isUndefined } from "lodash";

const ROUTE = require("../api/routes").SEGMENTS;
const FILTRO_TREE_SEGMENT = [
	"parent_id",
	"advertiser_id",
	"data_provider_key",
	"segment_tree",
	"limit",
	"page",
];

const ENTITY: string = "segment";

class SegmentService {
	async paginated(params: {
		paginated?: Paginated;
		filters?: SegmentFilter;
		options?: SortingOption;
		fields?: Array<string>;
	}) {
		try {
			let filter = "";

			if (!isUndefined(params.filters)) {
				filter = getFilters(params.filters);
			}

			const url = await prepareParams({
				route: ROUTE.SEGMENTS_ROUTE,
				entity: ENTITY,
				mode: modes.PAGINATED,
				filter: filter,
				...params,
			});

			const response = await AxiosGet(url);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error),
			});
		}
	}

	async all(params: {
		filters?: SegmentFilter;
		options?: SortingOption;
		paginated?: Paginated;
	}) {
		try {
			let filter = "";

			if (!isUndefined(params.filters)) {
				filter = getFilters(params.filters);
			}

			const url = await prepareParams({
				route: ROUTE.SEGMENTS_ROUTE,
				entity: ENTITY,
				paginated: params.paginated,
				fields: [],
				mode: modes.ALL,
				filter: filter,
				options: params.options,
			});

			const response = await AxiosGet(url);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error),
			});
		}
	}

	async create(entity: SegmentDataCreate) {
		try {
			const response = await AxiosPost(ROUTE.SEGMENTS_ROUTE, entity);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error),
			});
		}
	}

	async show(id: number) {
		try {
			const response = await AxiosGet(`${ROUTE.SEGMENTS_ROUTE}/${id}`);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error),
			});
		}
	}

	async update(entity: SegmentDataCreate) {
		try {
			const response = await AxiosPatch(
				`${ROUTE.SEGMENTS_ROUTE}/${entity.id}`,
				entity
			);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error),
			});
		}
	}

	async list(params: { filters?: SegmentFilter; options?: SortingOption }) {
		try {
			let filter = "";

			if (!isUndefined(params.filters)) {
				filter = getFilters(params.filters);
			}

			const url = await prepareParams({
				route: ROUTE.SEGMENTS_ROUTE,
				entity: ENTITY,
				mode: modes.LIST,
				filter: filter,
				...params,
			});

			const response = await AxiosGet(url);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error),
			});
		}
	}

	async download(params: {
		paginated?: Paginated;
		filters?: SegmentFilter;
		options?: SortingOption;
		fields: Array<string>;
	}) {
		try {
			let filter = "";

			if (!isUndefined(params.filters)) {
				filter = getFilters(params.filters);
			}

			await downloadFile({
				entity: ENTITY,
				route: ROUTE.SEGMENTS_ROUTE,
				filter,
				mode: modes.DOWNLOAD,
				...params,
			});

			return Promise.resolve({});
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error),
			});
		}
	}

	async generatedTag(params: SegmentTag) {
		try {
			const response = await AxiosGet(
				`${ROUTE.SEGMENT_GENERATED_TAG_ROUTE}/${params.id_segment}/${params.id_tag}`
			);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error),
			});
		}
	}

	async getViewTree(params: { filters?: SegmentTreeView }) {
		let filter = "";
		let option = "";

		if (!isUndefined(params.filters)) {
			filter = getFiltersTree(FILTRO_TREE_SEGMENT, params.filters);
		}

		const url = getURL(filter, option);

		try {
			const response = await AxiosGet(
				`${ROUTE.SEGMENT_TREE_VIEW_ROUTE}/${url}`
			);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error),
			});
		}
	}

	async getViewTreeSegmentThird(params: { filters?: SegmentTreeView }) {
		let filter = "";
		let option = "";

		if (!isUndefined(params.filters)) {
			filter = getFiltersTree(FILTRO_TREE_SEGMENT, params.filters);
		}

		const url = getURL(filter, option);

		try {
			/*
			let data = { success: false, response: [] };
			await fetch("/Primer_arbol.json")
			.then(response => {
				return response.json();
			})
			.then(jsondata => {
				data.success = jsondata.success;
				data.response = jsondata.response;
				
			});
			return data.response; */
			const response = await AxiosGet(
				`${ROUTE.SEGMENT_THIRD_TREE}/${url}`
			);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error),
			});
		}
	}

	async getSegmentProviders() {
		try {
			/*
			let data = { success: false, response: [] };
			await fetch("/providers.json")
			.then(response => {
				return response.json();
			})
			.then(jsondata => {
				data.success = jsondata.success;
				data.response = jsondata.response;
				
			});
			return data.response;*/

			const response = await AxiosGet(
				`${ROUTE.SEGMENT_PROVIDERS_ROUTER}`
			);

			return Promise.resolve(GetData(response));
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error),
			});
		}
	}

	async getSegmentSearch(params: any) {
		try {
			/*
			let data = { success: false, response: [] };
			await fetch("/segmentSearch.json")
			.then(response => {
				return response.json();
			})
			.then(jsondata => {
				data.success = jsondata.success;
				data.response = jsondata.response;
				
			});
			return data.response;*/

			const options = getOptionsSegmentSearch(params.options);
			const url = getUrlSearchSegment(params, options);
			const response = await AxiosGet(`${ROUTE.SEGMENT_SEARCH}?${url}`);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error),
			});
		}
	}
}

function getUrlSearchSegment(params, options) {
	return "search=" + params.search + "&" + options;
}

function getOptionsSegmentSearch(params: any) {
	let options = "";

	if (!isUndefined(params.page)) {
		options = "page=" + params.page;
	}
	if (!isUndefined(params.limit)) {
		options += "&limit=" + params.limit;
	}
	if (!isUndefined(params.data_provider_key)) {
		options += "&data_provider_key=" + params.data_provider_key;
	}

	return options;
}

function getFiltersTree(FILTROS: string[], filters: any): string {
	let filter = "";
	for (let fil of FILTROS) {
		if (!isUndefined(filters[fil])) {
			filter += "filters[" + fil + "]=" + filters[fil] + "&";
		}
	}
	filter = filter.substring(0, filter.length - 1);
	return filter;
}

function getFilters(filters: SegmentFilter): string {
	let filter = "";

	const id = isUndefined(filters.id) ? "" : filters.id;
	const advertiser_name = isUndefined(filters.advertiser_name)
		? ""
		: filters.advertiser_name;
	const name = isUndefined(filters.name) ? "" : filters.name;
	const external_id = isUndefined(filters.external_id)
		? ""
		: filters.external_id;
	const active = isUndefined(filters.active) ? "" : !!filters.active ? 1 : 0;
	const alternative_id = isUndefined(filters.alternative_id)
		? ""
		: filters.alternative_id;
	const advertiser_id = isUndefined(filters.advertiser_id)
		? ""
		: filters.advertiser_id;
	const key = isUndefined(filters.key) ? "" : filters.key;
	const description = isUndefined(filters.description)
		? ""
		: filters.description;
	const verb_usage_id = isUndefined(filters.verb_usage_id)
		? ""
		: filters.verb_usage_id;
	const created_at = isUndefined(filters.created_at)
		? ""
		: filters.created_at;
	const updated_at = isUndefined(filters.updated_at)
		? ""
		: filters.updated_at;

	var symbol = "";

	if (id) {
		filter += `${symbol}filters[id]=${id}`;
		symbol = "&";
	}

	if (external_id) {
		filter += `${symbol}filters[external_id]=${external_id}`;
		symbol = "&";
	}

	if (name) {
		filter += `${symbol}filters[name]=${name}`;
		symbol = "&";
	}

	if (advertiser_name) {
		filter += `${symbol}filters[advertisers.name]=${advertiser_name}`;
		symbol = "&";
	}

	if (alternative_id) {
		filter += `${symbol}filters[alternative_id]=${alternative_id}`;
		symbol = "&";
	}

	if (advertiser_id) {
		filter += `${symbol}filters[advertiser_id]=${advertiser_id}`;
		symbol = "&";
	}

	if (key) {
		filter += `${symbol}filters[key]=${key}`;
		symbol = "&";
	}

	if (description) {
		filter += `${symbol}filters[description]=${description}`;
		symbol = "&";
	}

	if (verb_usage_id) {
		filter += `${symbol}filters[verb_usage_id]=${verb_usage_id}`;
		symbol = "&";
	}

	if (created_at) {
		filter += `${symbol}filters[created_at]=${created_at}`;
		symbol = "&";
	}

	if (updated_at) {
		filter += `${symbol}filters[updated_at]=${updated_at}`;
		symbol = "&";
	}

	if (active) {
		filter += `${symbol}filters[active]=${active}`;
		symbol = "&";
	}

	return filter;
}

export default new SegmentService();
