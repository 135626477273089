import { isEmpty } from "lodash";
import {
	hasRole,
	hasRoleExcluded,
	MATCHED_ROLE_ROUTE,
	ROLE_REPORT,
	ROLE_PERSONS,
	ROUTE_DEFAULT,
} from "./utils";

/**
 * Meta:
 * excludes: Estos roles no podran acceder a la ruta.
 */
export default async function ({ from, next, router, to }) {
	const excludes: Array<string> = to?.meta?.excludes ?? [];

	const hasExcluded: Boolean =
		!isEmpty(excludes) && (await hasRoleExcluded(excludes));

	if (hasExcluded) {
		/*
		const routerName =
			MATCHED_ROLE_ROUTE[
				(await hasRole(ROLE_PERSONS)) ? ROLE_PERSONS : ROUTE_DEFAULT
			];*/

		let routerName = MATCHED_ROLE_ROUTE[ROUTE_DEFAULT];

		if(await hasRole(ROLE_PERSONS)){
			routerName = MATCHED_ROLE_ROUTE[ROLE_PERSONS];
		} else if(await hasRole(ROLE_REPORT)){
			routerName = MATCHED_ROLE_ROUTE[ROLE_REPORT];
		}

		return router
			.push({
				name: routerName,
			})
			.catch((failure: any) => {
				//console.error("Permissions error on push", { failure });
			});
	}

	return next();
}
