import {
	AxiosPost,
	AxiosGet,
	GetData,
	GetErrors,
	GetMessage,
} from "@/services/axios-service";
import { modes, prepareParams } from "@/utils/services-global";
import { isEmpty, isUndefined } from "lodash";

const FILTRO_PRIVATE_POIS = [
	"id",
	"country",
	"layer_name",
	"file_name",
	"total_count_pois",
	"status",
	"error_pois",
	"create_date",
];
const T_FILTRO = {
	id: "id",
	layer_name: "layer_name",
	file_name: "file_name",
	total_count_pois: "total_pois",
	error_pois: "total_errors",
	status: "status.description",
	country: "country_name",
	create_date: "private_pois_persons.created_at",
};

const ROUTE = require("@/api/routes").PERSONS_10;
const ROUTE_PERSON = require("@/api/routes").PERSON;
const ENTITY: string = "persons";

class PrivatePoisService {

    async fetchData(params: { type: string }) {
		try {
			const url = `${matchedRoutes()[params.type]}`;
			const response = await AxiosGet(url);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error),
			});
		}
	}

	async createPrivatePOis(params: any) {
		try {
			const response = await AxiosPost(
				`${ROUTE_PERSON.PERSON_PRIVATE_POIS_ROUTE}`,
				params,
				true
			);
			return Promise.resolve({data: GetData(response), message: response.data.message});
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error),
			});
		}
	}

	async paginatedPrivatePOIs(params: any) {
		try {
			let filter = "";
			if (!isUndefined(params.filters)) {
				filter = getFiltersPrivatePOIS(
					FILTRO_PRIVATE_POIS,
					params.filters
				);
			}

			const url = await prepareParams({
				route: ROUTE_PERSON.PERSON_GET_PRIVATE_POIS_ROUTE,
				entity: ENTITY,
				mode: modes.PAGINATED,
				filter: filter,
				...params,
			});

			const response = await AxiosGet(url);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error),
			});
		}
	}
}

function matchedRoutes() {
	return {
		countries: ROUTE.PERSONS_10_COUNTRIES_ROUTE,
	};
}

function getFiltersPrivatePOIS(FILTROS: string[], filters: any): string {
	let filter = "";
	FILTROS.forEach((element) => {
		if (!isEmpty(filters[element])) {
			filter +=
				"filters[" + T_FILTRO[element] + "]=" + filters[element] + "&";
		}
	});
	filter = filter.substring(0, filter.length - 1);
	return filter;
}

export default new PrivatePoisService()