import { ElementData } from "@/interfaces/persons/v10/person";
import { ResultCountry } from "@/interfaces/persons/v10/response";
import { LikeName } from "@/interfaces/persons/v10/select_like";
import { orderBy } from "lodash";

export function getSpecResultSorted(source: ResultCountry[], order: string[]) {
	return source.sort((a, b) => {
		const A = order.indexOf(a.continent);
		const B = order.indexOf(b.continent);

		if (A > B) return -1;
		if (B > A) return 1;
		return 0;
	});
}

export function prepareSortedElementData(
	items: ResultCountry[],
	continent: string,
	order: "asc" | "desc"
) {
	let itemsOrdered = items
		.filter((i) => i.continent === continent)
		.map((item) => {
			return {
				id: item.country_code,
				value: item.name,
			} as ElementData;
		});

	itemsOrdered = orderBy(itemsOrdered, ["value"], [order]);

	return itemsOrdered;
}

export const colorSet = {
	states: "teal",
	cities: "orange",
	neighborhoods: "purple",
	categories: "red darken-1",
	subcategories: "red darken-4",
	brands: "red darken-2",
	names: "red",
	privates: "indigo",
} as { [key in LikeName]: string };
