import {
  FilterItem,
} from "@/interfaces/persons/v10/audience";
import { FilterEntity, GraphicEntity } from "./Implements";
import { AttributionsItem, BrandAndPoiItem, HeatmapItem, DailyRichAttributionsItem, TotalRichAttributionsItem } from "@/interfaces/graphic";
import { AxiosGet } from "@/services/axios-service";
import { V10 } from "@/interfaces/persons/v10/route";
import notificationService from "@/services/notification-service";
const ROUTE: V10 = require("@/api/routes").V10;
import { Notification } from "@/interfaces/proccess";
import store from "@/store";
import { ITotalPois } from "@/interfaces/persons/v10/person";
import { allowAttributions, allowRichAttributions } from "@/store/Modules/Persons/v10/utils";

export const audienceTypePois: number[] = [1, 4];

export class AudienceEntity {
  total_reach = new GraphicEntity();
  proximity_to_poi = new GraphicEntity();

  gender = new GraphicEntity();
  age = new GraphicEntity();
  niv_socio = new GraphicEntity();

  category_poi = new GraphicEntity();
  sub_category_poi = new GraphicEntity();
  marca_poi = new GraphicEntity();
  dpto_poi = new GraphicEntity();
  city_poi = new GraphicEntity();
  barrio_poi = new GraphicEntity();

  date_of_week = new GraphicEntity();
  time_of_day = new GraphicEntity();
  date = new GraphicEntity();
  time_of_day_of_week = new GraphicEntity<HeatmapItem>();

  residence_city = new GraphicEntity();
  residence_barrio = new GraphicEntity();

  iab = new GraphicEntity();
  interest = new GraphicEntity();
  sites = new GraphicEntity();
  app_name = new GraphicEntity();
  content_language = new GraphicEntity();
  city_connection = new GraphicEntity();

  carrier = new GraphicEntity();
  device_type = new GraphicEntity();
  make = new GraphicEntity();
  browser = new GraphicEntity();
  os = new GraphicEntity();

  metrics_by_brand_and_poi = new GraphicEntity<BrandAndPoiItem>();
  attributions = new GraphicEntity<AttributionsItem>(allowAttributions);
  rich_attributions = new GraphicEntity<TotalRichAttributionsItem>(allowRichAttributions);
  rich_attributions_daily = new GraphicEntity<DailyRichAttributionsItem>(allowRichAttributions);


  // Filters
  filters: FilterEntity = new FilterEntity();
  last_filters: FilterEntity = new FilterEntity();

  constructor() { }
}

export class PoisCountEntity {
  public = 0;
  private = 0;
  fetched = false;
  maxPois = 200000;

  isFetched() {
    return this.fetched;
  }

  resetFetch() {
    this.fetched = false;
    this.public = 0;
    this.private = 0;
  }

  getPublic() {
    return this.public
  }

  getPrivate() {
    return this.private
  }

  getTotal() {
    return this.public + this.private
  }

  isValid() {
    return this.getTotal() > 0 && this.getTotal() <= this.maxPois
  }
}
