import {
	AxiosGet,
	AxiosPost,
	GetData,
	GetErrors,
	GetMessage,
} from "@/services/axios-service";
import { capitalizeWords, parseEncode } from "@/utils/filter-global";
import { isArray, isEmpty, isNull, isString } from "lodash";
import { resolveUsersBy } from "@/utils/resolveObjectArray";
import {
	prepareHeatMapGraphic,
	prepareUserGenderGraphic,
} from "@/utils/amcharts/utils";
import { ItemGraphic } from "@/interfaces/graphic";
import store from "@/store";
import { isWillBeRetried } from "@/utils/persons/audience";

import { V10 } from "@/interfaces/persons/v10/route";
import { ResultData } from "@/interfaces/persons/v10/response";
import { FilterParam, IExportSegmentAudience } from "@/interfaces/persons/v10/audience";
import { RejectError } from "@/models/persons/v10/response";
import { ITotalPois } from "@/interfaces/persons/v10/person";
import { matchedAudienceDummyData } from "@/store/Modules/Persons/v10/utils";
const ROUTE: V10 = require("@/api/routes").V10;

class AudienceService {
	async fetchAnalizeAudience() {
		try {
			const response = await AxiosPost(
				ROUTE.AUDIENCE.ANALYZE.ANALIZE_AUDIENCE,
				{}
			);
			const resultData: ResultData = response.data;
			return Promise.resolve(resultData);
		} catch (error) {
			console.error("AudienceService::fetchAnalizeAudience", { error });
			return Promise.reject(new RejectError(error));
		}
	}

	async fetchFilterAudience(params: FilterParam) {
		try {
			const response = await AxiosPost(
				ROUTE.AUDIENCE.FILTER_GRAPHIC,
				params
			);
			const resultData: ResultData = response.data;
			return Promise.resolve(resultData);
		} catch (error) {
			console.error("AudienceService::fetchFilterAudience", { error });
			return Promise.reject(new RejectError(error));
		}
	}

	async fetchPoisCount() {
		try {
			const response = await AxiosGet(ROUTE.PERSONS.TOTAL_POIS)

			if(!response.data.success) {
				return Promise.reject(new RejectError("Request failed"))
			}
			return response.data as ResultData<ITotalPois[]>;
		} catch (err) {
			let error = new RejectError(err);

			if(error.message === "You must first run analyze_pois.") {
				return { response: [] as ITotalPois[] } as ResultData<ITotalPois[]>
			}
			// if(response.data.message == "You must first run analyze_pois.") {
			// 	return { response: [] as ITotalPois[] } as ResultData<ITotalPois[]> ;
			// }

			console.error("AudienceService::fetchFilterAudience", { error });
			return Promise.reject(error);
		}
	}

	/**
	 * Get resource by key
	 * @param source SourceDataFilter
	 * @param isGraphic Boolean
	 * @returns
	 */
	async fetchGraphicBykey(name: string, isFiltered = false) {
		try {
			let type = isFiltered ? "FILTER" : "ANALYZE";
			let url = ROUTE.AUDIENCE[type][name.toUpperCase()];
			let resultData: ResultData<ItemGraphic[]>;
			
			/**
			 * Verify if the data has dummy logic, and then retrieve the data from utils
			 * Use in cases like Store Attributions for example, where there's no data to show
			 * 
			 * - Only for development
			 */
			if(["rich_attributions", "rich_attributions_daily"].includes(name) && process.env.NODE_ENV === "development") {
				return Promise.resolve(matchedAudienceDummyData(name));
			}

			switch (name) {
				case "niv_socio":
					let response_br = await AxiosGet(
						ROUTE.AUDIENCE[type]["NIV_SOCIO_BR"]
					);
					let response_all = await AxiosGet(
						ROUTE.AUDIENCE[type]["NIV_SOCIO_ALL_EXP_BR"]
					);

					if (!isNull(response_br.data.response)) {
						resultData = response_br.data;
					} else {
						resultData = response_all.data;
					}

					if (response_all.data.response == "failed")
						return Promise.reject(new RejectError("Request failed"));

					return Promise.resolve(resultData);
				
				default:
					let response = await AxiosGet(url);
					resultData = response.data;

					if (response.data.response == "failed")
						return Promise.reject(new RejectError("Request failed"));
					

					return Promise.resolve(resultData);
			}
		} catch (error) {
			console.error("AudienceService::fetchResourceBykey", { error });
			return Promise.reject(new RejectError(error));
		}
	}

	async fetchPreviousFilters(commit): Promise<FilterParam | null> {
		try {
			let filters = await AxiosGet(ROUTE.AUDIENCE.GET_FILTER);
			let { response } = filters.data;

			commit("SET_FILTER_CHANGES", { filters: !isNull(response) })

			return response;
		} catch (error) {
			console.error("AudienceService::fetchResourceBykey", { error });
			return Promise.reject(new RejectError(error));
		}
	}

	async fetchExportAudience(data: IExportSegmentAudience) {
		try {
			const response = await AxiosPost(
        ROUTE.AUDIENCE.CREATE_SEGMENT,
        data
      );
			return response;
		} catch (error) {
			console.error("AudienceService::fetchPreviousFilters", { error });
			return Promise.reject(new RejectError(error));
		}
	}
}

export default new AudienceService();
