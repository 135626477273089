import { Status } from "@/interfaces/audience";
import { DailyRichAttributionsItem, ItemGraphic, TotalRichAttributionsItem } from "@/interfaces/graphic";
import {
	FilterItem,
	FilterParam,
	IFiltersObject,
	ISegmentAudience,
} from "@/interfaces/persons/v10/audience";
import { ITotalPois } from "@/interfaces/persons/v10/person";
import {
	Config,
	GraphicType,
	ResultData,
} from "@/interfaces/persons/v10/response";
import { RejectError } from "@/models/persons/v10/response";
import i18n from "@/plugins/i18n";
import router from "@/router";
import notificationService from "@/services/notification-service";
import audienceService from "@/services/persons/v10/audience-service";
import personService from "@/services/persons/v10/person-service";
import store from "@/store";
import { sleep } from "@/utils/convert";
import { ComboListOptionsCampaign } from "@/utils/resolveObjectArray";
import { isEmpty, isEqual, isString, isUndefined } from "lodash";

// Fetcher for POIs graphics
export async function attemptFetchGraphic(commit: any, params: GraphicType) {
	let willBeRetried: Boolean = true;
	let items: ItemGraphic[] = [];

	commit("SET_GRAPHIC", {
		type: params.type,
		key: params.key,
		loading: true,
		source: [] as ItemGraphic[],
	});

	while (willBeRetried) {
		try {
			// Obtener datos desde la API
			const result: ResultData = await personService.getGraphicData(
				params.key,
				params.type
			);

			// Si la respuesta es un strig (PENDING | PROCESSING) se volvera a realizar el llamado a la API
			// La respuesta null se considera como sin datos | No deberia volver a hacer el llamado
			willBeRetried = await isWillBeRetried(result);

			// Setear loading para el grafico

			if (!willBeRetried) {
				/**
				 * Parsear y Setear datos {source}
				 */

				items = await prepareItemGraphic(
					params.key,
					params.type,
					result
				);

				commit("SET_GRAPHIC", {
					type: params.type,
					key: params.key,
					loading: false,
					source: items,
				});

				return items;
			} else {
				/**
				 * if {willBeRetried} reintentar en {x} segundos
				 */
				await sleep(2000);
			}
		} catch (error) {
			console.error("while::attemptFetchGraphic", { error });
			willBeRetried = false;
			return undefined;
		}
	}
}

export const getSource = (items: ItemGraphic[] | undefined, name: string): ItemGraphic[] => {
	// Verify if the source is empty
	if (isUndefined(items)) return [];

	return (
		// Verify if response of niv_socio is only "NI"
		(isEqual(items?.map(item => item.name), ["NI"]) && name === "niv_socio")
	)
		? []
		: items;
}

// Fetcher for audience graphics
export async function attemptFetchAudienceGraphic(
	commit: any,
	name: string,
	isFiltered = false
) {
	let willBeRetried = true;

	while (willBeRetried) {
		// Exit the retries if you're not in audience tab
		if (!["PersonsAudience", "PersonsStoreAttribution"].includes(router.currentRoute.name || "")) break;

		commit("SET_GRAPHIC", {
			name,
			loading: true,
		});

		try {
			// Obtener datos desde la API
			const result = await audienceService.fetchGraphicBykey(
				name,
				isFiltered
			);
			//console.log(`utils::attemptFetchGraphic`, { result, params });

			// Si la respuesta es un strig (PENDING | PROCESSING) se volvera a realizar el llamado a la API
			// La respuesta null se considera como sin datos | No deberia volver a hacer el llamado
			willBeRetried = await isWillBeRetried(result);

			// Setear loading para el grafico

			if (!willBeRetried) {
				/**
				 * Parsear y Setear datos {source}
				 */

				const source = getSource(result.response, name);

				if (isEmpty(source) && ["total_reach"].includes(name)) {
					notificationService.notifyWarning(store, {
						message: i18n.t('Persons10.errors.no_audience') as string
					})
				}

				commit("SET_GRAPHIC", {
					name,
					loading: false,
					source,
				});
			} else {
				/**
				 * if {willBeRetried} reintentar en {x} segundos
				 */
				await sleep(5000);
			}
		} catch (error) {
			commit("SET_GRAPHIC", {
				name,
				loading: false,
				source: [],
			});

			let analize: any = error;
			if (
				analize?.message ==
				"You must first run analyze_pois or analyze_geo."
			) {
				router.push("/admin/persons/index");
			}
			willBeRetried = false;
		}
	}
}

// Fetcher public and private POIs
export async function attemptFetchPois() {
	let willBeRetried = true;

	while (willBeRetried) {
		try {
			// Obtener datos desde la API
			const result = await audienceService.fetchPoisCount();
			//console.log(`utils::attemptFetchGraphic`, { result, params });

			// Si la respuesta es un strig (PENDING | PROCESSING) se volvera a realizar el llamado a la API
			// La respuesta null se considera como sin datos | No deberia volver a hacer el llamado
			willBeRetried = await isWillBeRetried(result);

			// Setear loading para el grafico

			if (willBeRetried) {
				/**
				 * if {willBeRetried} reintentar en {x} segundos
				 */
				await sleep(1000);
			} else {
				return result.response as ITotalPois[]
			}
		} catch (error) {
			return Promise.reject(new RejectError("Request failed"))
		}
	}
}

export async function attemptAnalizeAudience() {
	let willBeRetried = true;

	while (willBeRetried) {
		try {
			// Obtener datos desde la API
			const result = await audienceService.fetchAnalizeAudience();
			willBeRetried = await isWillBeRetried(result);
			// console.log(`utils::attemptAnalizeAudience`, {
			// 	result,
			// 	willBeRetried,
			// });

			if (!willBeRetried) return Promise.resolve(result);
			await sleep(3000);
		} catch (error) {
			console.error("while::attemptAnalizeAudience", { error });
			willBeRetried = false;
			return Promise.resolve(undefined);
		}
	}
}

export async function isWillBeRetried(result: ResultData) {
	const status: Status | Boolean | undefined | null | any[] =
		result.response as any;
	// console.log(`utils::isWillBeRetried`, {
	// 	status,
	// });
	if (!isString(status)) return false;
	return [Status.PENDING, Status.PROCESSING].includes(status);
}

// Fetcher for filter audience graphics
export async function filterAudienceGraphics(filters: IFiltersObject) {
	let willBeRetried = true;
	let params = prepareFilterParams(filters);
	while (willBeRetried) {
		try {
			// Obtener datos desde la API
			const result = await audienceService.fetchFilterAudience(params);
			willBeRetried = await isWillBeRetried(result);

			if (!willBeRetried) return Promise.resolve(result);
			await sleep(3000);
		} catch (error) {
			console.error("while::filterAudienceGraphics", { error });
			willBeRetried = false;
			return Promise.resolve(undefined);
		}
	}
}

// Export audience to create segments
export async function exportAudienceSegments(
	params: ISegmentAudience,
	filters: IFiltersObject
) {
	let filterParams = prepareFilterParams(filters);
	let data = {
		...filterParams,
		...params,
	};
	let response = await audienceService.fetchExportAudience(data);
	return response;
}

function getNames(filter: FilterItem[]): string[] {
	return filter.map((item) => item.name);
}

export function convertToFilterType(
	type: string,
	items: (string | number | undefined)[]
): { id: string; data: FilterItem[] } {
	if (isUndefined(items[0])) {
		return {
			id: type,
			data: [],
		};
	}

	return {
		id: type,
		data: items.map((name) => ({
			type,
			name: name as string,
		})),
	};
}

export function prepareFilterParams(filters: IFiltersObject): FilterParam {
	const {
		age,
		app_name,
		barrio_poi,
		browser,
		carrier,
		category_poi,
		city_connection,
		city_poi,
		content_language,
		device_type,
		dpto_poi,
		gender,
		iab,
		interest,
		make,
		marca_poi,
		os,
		poi_distance,
		residence_barrio,
		residence_city,
		sites,
		sub_category_poi,
		user_type,
		niv_socio,
	} = filters;
	// const date = filters.date.length ? filters.date[0].name.split(", ") : null;

	const date_of_week = filters.date_of_week.length
		? getNames(filters.date_of_week)
		: new Array(7).fill(1).map((item, index) => index + 1);
	const time_of_day = filters.time_of_day.length
		? getNames(filters.time_of_day)
		: new Array(24).fill(1).map((item, index) => index);

	const frequency = {};

	if (typeof date_of_week === "object") {
		date_of_week.forEach((day) => {
			frequency[`${day}`] = time_of_day;
		});
	}

	return {
		filters: {
			user_id_type: getNames(user_type),
			// start_date: date ? date[0] : undefined,
			// end_date: date ? date[1] : undefined,
			radio: poi_distance[0] ? parseInt(poi_distance[0].name) : undefined,
			frequency: (filters.date_of_week.length || filters.time_of_day.length) ? frequency : undefined,
			pois: {
				city_poi: getNames(city_poi),
				dpto: getNames(dpto_poi),
				categoria: getNames(category_poi),
				subcategoria: getNames(sub_category_poi),
				marca: getNames(marca_poi),
				nombre_poi: getNames(barrio_poi),
			},
			bid_request: {
				app_bundle: [],
				app_name: getNames(app_name),
				city_seen: getNames(city_connection),
				domain: getNames(sites),
				iab: getNames(iab),
				platform_browser: getNames(browser),
				platform_device_language: getNames(content_language),
				platform_device_make: getNames(make),
				platform_device_type: getNames(device_type),
				platform_os: getNames(os),
				carrier: getNames(carrier),
			},
			gender_age: {
				gender: getNames(gender),
				age: getNames(age),
			},
			home: {
				neighborhood_residence: getNames(residence_barrio),
				city_residence: getNames(residence_city),
				nombre_estado: [],
				nivsocio: getNames(niv_socio)
			},
			interest: {
				interest: getNames(interest),
			},
		},
	};
}

export async function prepareItemGraphic(
	key: string,
	type: string,
	result: ResultData
) {
	const config: Config = matchedConfigParams()[type][key];
	const items: ItemGraphic[] = resolveResult(config, result);
	return items;
}

export function matchedConfigParams() {
	return {
		pois: {
			categories: {
				key: "cant_pois",
				value: "categoria",
			} as Config,
			subcategories: {
				key: "cant_pois",
				value: "subcat",
			} as Config,
			brands: {
				key: "cant_pois",
				value: "marca",
			} as Config,
			states: {
				key: "cant_pois",
				value: "dpto",
			} as Config,
			cities: {
				key: "cant_pois",
				value: "ciudad",
			} as Config,
			neighborhoods: {
				key: "cant_pois",
				value: "barrio",
			} as Config,
		},
		geo: {
			states: {
				key: "count",
				value: "complete_name",
			} as Config,
			cities: {
				key: "count",
				value: "complete_name",
			} as Config,
			neighborhoods: {
				key: "count",
				value: "complete_name",
			} as Config,
		},
		reach: {
			pois: {
				key: "approx_uniques",
				value: "type",
			} as Config,
			geo: {
				key: "approx_uniques",
				value: "type",
			} as Config,
		},
	};
}

export function resolveResult(config: Config, result: ResultData) {
	const items: any[] = result?.response as any[];

	if (isEmpty(items)) return [] as ItemGraphic[];

	return (
		items?.map((i) => {
			return {
				uniques: i[config.key],
				name: i[config.value],
			} as ItemGraphic;
		}) || []
	);
}

/**
 * Return true if the current country is allowed to get attributions
 * @returns boolean
 */
export function allowAttributions() {
	const { id } = store.getters["person/getCountry"];
	return allowedAttributions.includes(id);
}

/**
 * Return true if the current analysis is Store Attribution
 * @returns boolean
 */
export function allowRichAttributions() {
	/**
	 * Add verification if there's campaigns store on LocalStorage
	 * meaning that is a Store Attribution audience.
	 */
	const campaigns: ComboListOptionsCampaign[] = store.getters["person/getCampaigns"];

	/** If the campaign is empty, it means that it's just an Audience */
	const hasCampaigns = !isEmpty(campaigns);

	return hasCampaigns || Boolean(process.env.VUE_APP_ENABLE_STORE_ATTRIBUTION_FAKE);
}

export const poisGraphTypes: GraphicType[] = [
	{
		type: "pois",
		key: "categories",
	},
	{
		type: "pois",
		key: "subcategories",
	},
	{
		type: "pois",
		key: "brands",
	},
	{
		type: "pois",
		key: "states",
	},
	{
		type: "pois",
		key: "cities",
	},
	{
		type: "pois",
		key: "neighborhoods",
	},
] as GraphicType[];

export const geoGraphTypes: GraphicType[] = [
	{
		type: "geo",
		key: "states",
	},
	{
		type: "geo",
		key: "cities",
	},
	{
		type: "geo",
		key: "neighborhoods",
	},
] as GraphicType[];

export const reachGraphTypes = [
	{
		type: "reach",
		key: "geo",
	},
	{
		type: "reach",
		key: "pois",
	},
] as GraphicType[];

export const postAnalizeAudience = [
	{
		type: "reach",
		key: "geo",
	},
	{
		type: "reach",
		key: "pois",
	},
] as GraphicType[];

export const allowedAttributions = [
	620 // Portugal
]

export const emptyDummy = {
	message: "",
	success: false,
	response: []
}

export const fakeStoreAttributionResult = {
	success: true,
	message: "",
	response: [
		{
			type: "MAID",
			approx_uniques: 3350645,
		},
		{
			type: "Cookie",
			approx_uniques: 2547622,
		},
	],
} as ResultData

export const fakeRichAttributionsData = (): ResultData<TotalRichAttributionsItem[]> => {
	return {
		success: true,
		response: [
			{
				"poi_name": "INJEPOWER INJETADOS TERMOPLASTICOS (13915-67999)",
				"< 25 mts": null,
				"< 50 mts": null,
				"< 100 mts": 1,
				"< 150 mts": 1,
				"< 200 mts": 1,
				"< 250 mts": 1
			},
			{
					"poi_name": "TERMO SERRANA MATERIAIS E INSTALACOES (18075-51122)",
					"< 25 mts": null,
					"< 50 mts": null,
					"< 100 mts": null,
					"< 150 mts": null,
					"< 200 mts": 1,
					"< 250 mts": 1
			}
		],
		message: ""
	}
}

export const fakeDailyRichAttributionsData = (): ResultData<DailyRichAttributionsItem[]> => {
	return {
		success: true,
		response: [
			{
					"date": "2023-09-14",
					"poi_name": "INJEPOWER INJETADOS TERMOPLASTICOS (13915-67999)",
					"< 25 mts": null,
					"< 50 mts": null,
					"< 100 mts": 1,
					"< 150 mts": 1,
					"< 200 mts": 1,
					"< 250 mts": 1
			},
			{
					"date": "2023-09-03",
					"poi_name": "TERMO SERRANA MATERIAIS E INSTALACOES (18075-51122)",
					"< 25 mts": null,
					"< 50 mts": null,
					"< 100 mts": null,
					"< 150 mts": null,
					"< 200 mts": 1,
					"< 250 mts": 1
			}
		],
		message: ""
	}
}

export function matchedAudienceDummyData(name: string) {
	const dummyMatcher = {
		rich_attributions: fakeRichAttributionsData(),
		rich_attributions_daily: fakeDailyRichAttributionsData()
	}

	return dummyMatcher[name] || emptyDummy
}