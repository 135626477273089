import { CampaingList } from "@/interfaces/campaign";
import { ItemGraphic } from "@/interfaces/graphic";
import { FilterItem } from "@/interfaces/persons/v10/audience";
import {
	Action,
	ActionLoading,
	ActionResponse,
	AnalizeResponse,
	Audience,
	ElementData,
	Geo,
	GeoFilterData,
	GraphicAudienceType,
	GraphicData,
	GraphicGeoType,
	GraphicPoisType,
	GraphicReach,
	GraphicResponse,
	PersistentPoisData,
	Pois,
	Private,
	PoisFilterData,
	PrivateFilterData,
	StoreAttribution,
	Pagination,
	PersonResource,
} from "@/interfaces/persons/v10/person";
import { ResultContinent, ResultData } from "@/interfaces/persons/v10/response";
import { ListOption } from "@/interfaces/persons/v10/tabs/pois";
import {
	getFromStorage,
	removeFromStorage,
	setPersistentCampaigns,
	setPersistentCountry,
	storeIndex,
} from "@/services/storage-service";
import { convLocaleString, getRandomIntInclusive } from "@/utils/convert";
import { ComboListOptionsCampaign } from "@/utils/resolveObjectArray";
import { isEmpty } from "lodash";

export class SelectedDataEntity implements ElementData {
	id: number = NaN;
	value: string = "";
	extra?: string = "";
	count?: number;
	type?: string = "";
	categoria?: string;
	subcategoria?: string;
	marca?: string;
	nombre?: string;
	checked?: Boolean = false;
	identifier?: string = "";

	constructor(
		parametters?:
			| {
					id: number;
					value: string;
					count?: number;
					categoria?: string;
					subcategoria?: string;
					marca?: string;
					nombre?: string;
					checked?: Boolean;
			  }
			| undefined
	) {
		if (parametters?.id && parametters?.value) {
			this.id = parametters.id;
			this.value = parametters.value;
			this.identifier = this.getIdentifier();
		}

		if (parametters?.count) {
			this.count = parametters.count;
		}

		if (parametters?.categoria) {
			this.categoria = parametters.categoria;
		}

		if (parametters?.subcategoria) {
			this.subcategoria = parametters.subcategoria;
		}

		if (parametters?.marca) {
			this.marca = parametters.marca;
		}

		if (parametters?.nombre) {
			this.nombre = parametters.nombre;
		}

		if (parametters?.checked) {
			this.checked = parametters.checked;
		}
	}

	hasExtra() {
		return (
			!isEmpty(this.categoria) ||
			!isEmpty(this.subcategoria) ||
			!isEmpty(this.marca) ||
			!isEmpty(this.nombre)
		);
	}

	getToString() {
		if (!this.hasExtra()) return this.value;

		let text: string = this.value;

		if (!isEmpty(this.nombre)) {
			text = text.concat(` / ${this.nombre}`);
		} else if (!isEmpty(this.marca)) {
			text = text.concat(` / ${this.marca}`);
		} else if (!isEmpty(this.subcategoria)) {
			text = text.concat(` / ${this.subcategoria}`);
		} else if (!isEmpty(this.categoria)) {
			text = text.concat(` / ${this.categoria}`);
		}

		return text;
	}

	getIdentifier() {
		return `${this.id}_${this.value}_${getRandomIntInclusive(1, 100)}`;
	}

	setExtra(extra: string | undefined) {
		this.extra = extra;
	}

	setChecked(checked: Boolean = false) {
		this.checked = checked;
	}
}

/**
 * Geo
 */

export class PersonGeoEntity implements Geo {
	states: ElementData[] = [];
	cities: ElementData[] = [];
	neighborhoods: ElementData[] = [];
	constructor() {}
}

/**
 * Pois
 */

export class PersonPoisEntity implements Pois {
	categories: ElementData[] = [];
	subcategories: ElementData[] = [];
	brands: ElementData[] = [];
	names: ElementData[] = [];
	constructor() {}
}

/**
 * Private
 */

export class PersonPrivateEntity implements Private {
	privates: ElementData[] = [];
	constructor() {}
}

export class GeoFilterDataEntity implements GeoFilterData {
	selected: PersonGeoEntity = new PersonGeoEntity();
	and: PersonGeoEntity = new PersonGeoEntity();
	pre: PersonGeoEntity = new PersonGeoEntity();
	constructor(properties?: GeoFilterDataEntity) {
		if(properties) {
			this.and = properties.and;
			this.pre = properties.pre;
			this.selected = properties.selected;
		}
	}
}

export class PoisFilterDataEntity implements PoisFilterData {
	selected: Pois = new PersonPoisEntity();
	and: Pois = new PersonPoisEntity();
	pre: Pois = new PersonPoisEntity();
	constructor(properties?: PoisFilterDataEntity) {
		if(properties) {
			this.and = properties.and;
			this.pre = properties.pre;
			this.selected = properties.selected;
		}
	}
}

export class PrivateFilterDataEntity implements PrivateFilterData {
	selected: Private = new PersonPrivateEntity();
	and: Private = new PersonPrivateEntity();
	pre: Private = new PersonPrivateEntity();
	constructor(properties?: PrivateFilterDataEntity) {
		if(properties) {
			this.and = properties.and;
			this.pre = properties.pre;
			this.selected = properties.selected;
		}
	}
}

/**
 * Audience
 */

export class PersonAudienceEntity implements Audience {
	constructor() {}
}

/**
 * StoreAttribution
 */

export interface MaidUnique {
	type: string;
	approx_uniques: number;
}

export class PersonStoreAttributionEntity implements StoreAttribution {
	loading: Boolean = false;
	open: Boolean = false;
	check: Boolean = false;
	selected: CampaingList[] = [];
	response: ResultDataEntity = new ResultDataEntity();

	constructor(properties?: PersonStoreAttributionEntity) {
		if(properties) {
			this.loading = properties.loading;
			this.open = properties.open;
			this.selected = properties.selected;
			this.response = new ResultDataEntity(properties.response);
		}
	}

	setOpen(open: Boolean = false) {
		this.open = open;
	}

	setCheck(check: Boolean = false) {
		this.check = check;
	}

	setSelected(selected: CampaingList[] = []) {
		this.selected = selected;
	}

	setLoading(loading: Boolean = false) {
		this.loading = loading;
	}

	setResponse(response: ResultDataEntity) {
		this.response = response;
	}

	getResponse() {
		return this.response.response;
	}

	showMaidUnique(): Boolean {
		const items: MaidUnique[] = (this.getResponse() as MaidUnique[]) || [];
		return !isEmpty(items);
	}

	getMaidUnique() {
		const items: MaidUnique[] = (this.getResponse() as MaidUnique[]) || [];
		return items.map((i) => ({
			count: `${i.type}: ${convLocaleString(i.approx_uniques)}`,
		}));
	}
}

export class PersonTargetAudienceEntity {
	open: Boolean = false;

	constructor(properties?: PersonTargetAudienceEntity) {
		if(properties) {
			this.open = properties.open;
		}
	}

	setOpen(open: Boolean = false) {
		this.open = open;
	}
}

export class PersonSavePoisEntity {
	open: Boolean = false;

	constructor(properties?: PersonSavePoisEntity) {
		if(properties) {
			this.open = properties.open;
		}
	}

	setOpen(open: Boolean = false) {
		this.open = open;
	}
}

export class PersonGeoFencingEntity {
	open: Boolean = false;

	constructor(properties?: PersonGeoFencingEntity) {
		if(properties) {
			this.open = properties.open;
		}
	}

	setOpen(open: Boolean = false) {
		this.open = open;
	}
}

export class ActionEntity implements Action {
	analize_pois: Boolean = false;
	analize_geo: Boolean = false;
	analize_audience: Boolean = false;
	calculate_reach: Boolean = false;
	geo_fencing: Boolean = false;
	geo_report: Boolean = false;
	target_audience: Boolean = false;
	store_attribution: Boolean = false;
	save_pois: Boolean = false;

	constructor() {}
}

export class AnalizeResponseEntity implements AnalizeResponse {
	analize_pois: ResultData = new ResultDataEntity();
	analize_geo: ResultData = new ResultDataEntity();
	analize_audience: ResultData = new ResultDataEntity();
	calculate_reach: ResultData = new ResultDataEntity();
	geo_fecing: ResultData = new ResultDataEntity();
	geo_report: ResultData = new ResultDataEntity();
	target_audience: ResultData = new ResultDataEntity();
	store_attribution: ResultData = new ResultDataEntity();
	save_pois: ResultData = new ResultDataEntity();

	constructor(properties?: AnalizeResponseEntity) {
		if(properties) {
			this.analize_pois = new ResultDataEntity(properties.analize_pois);
			this.analize_geo = new ResultDataEntity(properties.analize_geo);
			this.analize_audience = new ResultDataEntity(properties.analize_audience);
			this.calculate_reach = new ResultDataEntity(properties.calculate_reach);
			this.geo_fecing = new ResultDataEntity(properties.geo_fecing);
			this.geo_report = new ResultDataEntity(properties.geo_report);
			this.target_audience = new ResultDataEntity(properties.target_audience);
			this.store_attribution = new ResultDataEntity(properties.store_attribution);
			this.save_pois = new ResultDataEntity(properties.save_pois);
		}
	}
}

export class ResultDataEntity implements ResultData {
	success: Boolean = false;
	response?: Object | Boolean | any[] | MaidUnique[] | null = null;
	message: string = "";

	constructor(params?: ResultData) {
		if(params) {
			this.success = params.success;
			this.response = params.response;
			this.message = params.message;
		}
	}
}

export class ActionLoadingEntity implements ActionLoading {
	action: Action = new ActionEntity();

	constructor() {}
}

export class ActionResponseEntity implements ActionResponse {
	action: AnalizeResponse = new AnalizeResponseEntity();

	constructor(properties?: ActionResponseEntity) {
		if(properties) {
			this.action = new AnalizeResponseEntity(properties.action);
		}
	}
}

export class GraphicDataEntity implements GraphicData {
	loading: Boolean = false;
	data: ItemGraphic[] = [];

	constructor(properties?: GraphicDataEntity) {
		if(properties) {
			this.loading = properties.loading;
			this.data = properties.data;
		}
	}
}

export class GraphicGeoTypeEntity implements GraphicGeoType {
	states: GraphicData = new GraphicDataEntity();
	cities: GraphicData = new GraphicDataEntity();
	neighborhoods: GraphicData = new GraphicDataEntity();

	constructor(properties?: GraphicGeoTypeEntity) {
		if(properties) {
			this.states = new GraphicDataEntity(properties.states);
			this.cities = new GraphicDataEntity(properties.cities);
			this.neighborhoods = new GraphicDataEntity(properties.neighborhoods);
		}
	}
}

export class GraphicPoisTypeEntity implements GraphicPoisType {
	categories: GraphicData = new GraphicDataEntity();
	subcategories: GraphicData = new GraphicDataEntity();
	brands: GraphicData = new GraphicDataEntity();
	states: GraphicData = new GraphicDataEntity();
	cities: GraphicData = new GraphicDataEntity();
	neighborhoods: GraphicData = new GraphicDataEntity();
	pois: GraphicData = new GraphicDataEntity();

	constructor(properties?: GraphicPoisTypeEntity) {
		if(properties) {
			this.categories = new GraphicDataEntity(properties.categories);
			this.subcategories = new GraphicDataEntity(properties.subcategories);
			this.brands = new GraphicDataEntity(properties.brands);
			this.states = new GraphicDataEntity(properties.states);
			this.cities = new GraphicDataEntity(properties.cities);
			this.neighborhoods = new GraphicDataEntity(properties.neighborhoods);
			this.pois = new GraphicDataEntity(properties.pois);
		}
	}
}

export class GraphicAudienceTypeEntity implements GraphicAudienceType {
	constructor() {}
}

export class GraphicReachEntity implements GraphicReach {
	geo: GraphicData = new GraphicDataEntity();
	pois: GraphicData = new GraphicDataEntity();

	constructor(properties?: GraphicReachEntity) {
		if(properties) {
			this.geo = new GraphicDataEntity(properties.geo);
			this.pois = new GraphicDataEntity(properties.pois);
		}
	}
}

export class GraphicResponseEntity implements GraphicResponse {
	pois: GraphicPoisType = new GraphicPoisTypeEntity();
	geo: GraphicGeoType = new GraphicGeoTypeEntity();
	audience: GraphicAudienceType = new GraphicAudienceTypeEntity();
	reach: GraphicReach = new GraphicReachEntity();

	constructor(properties?: GraphicResponseEntity) {
		if(properties) {
			this.pois = new GraphicPoisTypeEntity(properties.pois);
			this.geo = new GraphicGeoTypeEntity(properties.geo);
			this.audience = new GraphicAudienceTypeEntity();
			this.reach = new GraphicReachEntity(properties.reach);
		}
	}

	isReachedPois() {
		return this.reach.pois.data.length > 0;
	}
}

export class GraphicEntity<T = ItemGraphic, V = () => boolean> {
	source: T[] = [];
	loading = true;
	validator: V;

	constructor(validator?: V) {
		if(typeof validator === "function") {
			this.validator = validator;
		} else {
			this.validator = (() => true) as unknown as  V
		}
	}
}

export class FilterEntity {
	poi_distance: FilterItem[] = [];
	user_type: FilterItem[] = [];
	gender: FilterItem[] = [];
	age: FilterItem[] = [];
	niv_socio: FilterItem[] = [];
	category_poi: FilterItem[] = [];
	sub_category_poi: FilterItem[] = [];
	marca_poi: FilterItem[] = [];
	dpto_poi: FilterItem[] = [];
	city_poi: FilterItem[] = [];
	barrio_poi: FilterItem[] = [];
	// date: FilterItem[] = [];
	date_of_week: FilterItem[] = [];
	time_of_day: FilterItem[] = [];
	residence_city: FilterItem[] = [];
	residence_barrio: FilterItem[] = [];
	iab: FilterItem[] = [];
	interest: FilterItem[] = [];
	sites: FilterItem[] = [];
	app_name: FilterItem[] = [];
	content_language: FilterItem[] = [];
	city_connection: FilterItem[] = [];
	carrier: FilterItem[] = [];
	device_type: FilterItem[] = [];
	make: FilterItem[] = [];
	browser: FilterItem[] = [];
	os: FilterItem[] = [];
}

export class PersistentPoisDataEntity implements PersistentPoisData {
	country_global: SelectedDataEntity = new SelectedDataEntity();
	campaigns: ComboListOptionsCampaign[] = [];

	constructor() {
		const country_global = getFromStorage(storeIndex.COUNTRY_GLOBAL);
		const campaigns = getFromStorage(storeIndex.CAMPAIGNS);

		if (country_global) {
			this.country_global = JSON.parse(country_global);
		}

		if (campaigns) {
			this.campaigns = JSON.parse(campaigns);
		}
	}

	setCountry(country: SelectedDataEntity) {
		setPersistentCountry(country);
		this.country_global = country;
	}

	setCampaigns(campaigns: ComboListOptionsCampaign[] = []) {
		setPersistentCampaigns(campaigns);
		this.campaigns = campaigns;
	}

	clearCampaigns() {
		removeFromStorage(storeIndex.CAMPAIGNS);
		this.campaigns = [] as ComboListOptionsCampaign[];
	}

	getCountry() {
		return this.country_global;
	}
}

export class ListOptionEntity implements ListOption {
	id: number = NaN;
	value: String = "";
	disabled: Boolean = true;

	constructor(id: number, value: String, disabled: Boolean = true) {
		this.id = id;
		this.value = value;
		this.disabled = disabled;
	}
}

export class PersonResourceEntity implements PersonResource {
	countries: ResultContinent[] = [];
	audience_type: ListOptionEntity[] = [];

	constructor(properties?: PersonResourceEntity) {
		if(properties) {
			this.countries = properties.countries;
			this.audience_type = properties.audience_type;
		}
	}

	setResource<T>(key: string, value: T) {
		this[key] = value;
	}
}

export class PaginationEntity implements Pagination {
	totalPages: number = 1;
	itemsPerPage: number = 10;
	page: number = 0;
	constructor() {}

	currentPage(): number {
		return this.page + 1;
	}

	getTotalPages(): number {
		return this.totalPages;
	}

	setLastPage(): void {
		this.page = this.totalPages - 1;
	}

	setPage(page: number = 0): void {
		this.page = page;
	}

	async setTotalPages(count: number): Promise<void> {
		this.totalPages = Math.ceil(count / this.itemsPerPage);
		if (!count) {
			this.setPage();
		}
	}

	nextPage(): void {
		if (this.page < this.totalPages - 1) {
			this.page++;
		}
	}

	previousPage(): void {
		if (this.page > 0) {
			this.page--;
		}
	}

	verifyPage(): void {
		if (this.page === this.totalPages && this.page > 0) {
			this.page--;
		}
	}

	isZero(): Boolean {
		return this.page === 0;
	}

	isEqual(): Boolean {
		return this.page === this.totalPages - 1;
	}

	isZeroColor(): string {
		return this.isZero() ? "" : "secondary";
	}

	isEqualColor(): string {
		return this.isEqual() ? "" : "secondary";
	}

	getPaginatedItems(items: ElementData[]) {
		const start = this.page * this.itemsPerPage;
		const end = start + this.itemsPerPage;
		return items.slice(start, end);
	}
}
