import {
    AxiosPost,
    AxiosGet,
    GetData,
    GetErrors,
    GetMessage
} from '@/services/axios-service';
import {
    isEmpty,
    isUndefined,
} from 'lodash';
import {
    SegmentUploadFilter,
    SegmentUploadOption,
    SegmentUploadPaginated
} from '@/interfaces/segment_upload'

export const SEGMENT_UPLOAD_ROUTE = "/api/segment_uploads";
const ROUTE = require("../api/routes").CONTINENT;

class SegmentUploadService {

    async create(params: any) {
        try {
            const response = await AxiosPost(`${SEGMENT_UPLOAD_ROUTE}`, params.formData, true);
            return Promise.resolve({data: GetData(response), message: response.data.message});
        } catch (error) {
            return Promise.reject({
                success: false,
                message: GetMessage(error),
                errors: GetErrors(error)
            });
        }
    }

    async paginated(params: { paginated: SegmentUploadPaginated, filters?: SegmentUploadFilter, options?: SegmentUploadOption}) {
        try {
            let filter = ''
            let option = ''

            if (!isUndefined(params.filters)) {
                filter = getFilters(params.filters)
            }

            if (!isUndefined(params.options)) {
                option += getOptions(params.options, 'paginated', params.paginated)
            }

            const url = getURL(filter, option)

            const response = await AxiosGet(`${SEGMENT_UPLOAD_ROUTE}/${url}`);
            return Promise.resolve(GetData(response));
        } catch (error) {
            return Promise.reject({
                success: false,
                message: GetMessage(error),
                errors: GetErrors(error)
            });
        }
    }

    async getContinentList(){
        try {
			const response = await AxiosGet(`${ROUTE.CONTINENT_ROUTE}`);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error)
			});
		}
    }
}

function getFilters(filters: SegmentUploadFilter): string {
    let filter = ''
    return filter
}

function getOptions(options: SegmentUploadOption, mode: string, paginated?: SegmentUploadPaginated): string {
    let option = ''

    const sort = (isUndefined(options.sort)) ? '' : options.sort
    const order = (isUndefined(options.order)) ? '' : options.order

    option += 'sort=' + sort + '&order=' + order + '&mode=' + mode

    if (mode == 'paginated') {
        option += '&page=' + paginated?.page + '&limit=' + paginated?.limit
    }

    return option
}

function getURL(filters: string, options: string): string {
    let url = ''

    if (!isEmpty(filters) && !isEmpty(options)) {
        url = '?' + filters + '&' + options
    } else if (!isEmpty(filters)) {
        url = '?' + filters
    } else if (!isEmpty(options)) {
        url = '?' + options
    }

    return url
}

export default new SegmentUploadService()