import {
	Campaign,
	CampaignDataCreate,
	CampaingFilters,
	CampaingList,
} from "@/interfaces/campaign";
import { ResultPaginate, SortingOption } from "@/interfaces/paginated";
import CampaignService from "@/services/campaign-service";
import notificationService from "@/services/notification-service";
import { Notification } from "@/interfaces/proccess";
import { CatcherError, catchError } from "@/store/Util";
import {
	ComboListOptionsCampaign,
	resolveList,
	resolveListCampaignData,
	resolveListParams,
} from "@/utils/resolveObjectArray";
import i18n from "@/plugins/i18n";

export const CampaignModule = {
	namespaced: true,
	state: () => ({
		result_paginate: {} as ResultPaginate,
		campaign: {} as Campaign,
		campaigns: [] as Campaign[],
		campaigns_list: [] as CampaingList[],
	}),
	mutations: {
		SET_CAMPAIGN(state, _campaign: Campaign = {} as Campaign) {
			state.campaign = _campaign;
		},
		SET_RESULT_PAGINATED(
			state,
			_result_paginate: ResultPaginate = {} as ResultPaginate
		) {
			state.result_paginate = _result_paginate;
		},
		SET_RESULT_LIST(
			state: {
				campaigns_list: CampaingList[] | ComboListOptionsCampaign[];
			},
			_result_list: CampaingList[] | ComboListOptionsCampaign[] = []
		) {
			state.campaigns_list = _result_list;
		},
	},
	getters: {
		getCampaignList(state: any): CampaingList[] {
			return state.campaigns_list;
		},
		getCampaign(state: any): Campaign {
			return state.campaign;
		},
	},
	actions: {
		async create(
			{ commit },
			params: { data: CampaignDataCreate; continue: Boolean }
		) {
			try {
				const response = await CampaignService.create(params.data);
				commit("SET_CAMPAIGN", response);
				/*
				notificationService.notifySuccess(this, 
					{
						to: params.continue ? "" : "CampaignsIndex",
					} as Notification);*/
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async update(
			{},
			params: { data: Campaign; id: number; continue: Boolean }
		) {
			try {
				const response = await CampaignService.update(
					params.data,
					params.id
				);
				/*
						notificationService.notifySuccess(this, {
							to: params.continue
								? "LineItemCreate"
								: "CampaignsIndex"
						} as Notification);
						*/
				return await Promise.resolve(response);
			} catch (error) {
				CatcherError(this.dispatch, error, { to: "" });
				return await Promise.reject(error);
			}
		},

		async show({ commit }, id: number) {
			try {
				const response = await CampaignService.show(id);
				commit("SET_CAMPAIGN", response);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error, { to: "CampaignsIndex" });
				return await Promise.reject(error);
			}
		},

		async paginated({ commit }, params) {
			try {
				const response = await CampaignService.paginated(params);
				commit("SET_RESULT_PAGINATED", response);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async download({}, params) {
			try {
				await CampaignService.download(params);
				return await Promise.resolve({});
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async list({ commit }, params) {
			try {
				const response = await CampaignService.list(params);
				const values = resolveList(response);
				commit("SET_RESULT_LIST", values);
				return await Promise.resolve(values);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async all(
			{ commit }: any,
			params: {
				parsed?: any;
				filters?: CampaingFilters | undefined;
				options?: SortingOption | undefined;
			}
		) {
			try {
				const response = await CampaignService.all(params);
				let result: ComboListOptionsCampaign[] = [];
				if (params.parsed) {
					result = resolveListCampaignData(response, false);
				} else {
					result = response;
				}
				commit("SET_RESULT_LIST", result);
				return await Promise.resolve(result);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async getStoreAttributions(
			{ commit }: any,
		) {
			try {
				const response = await CampaignService.getStoreAttribution();
				let result: ComboListOptionsCampaign[] = [];
				result = resolveListCampaignData(response, false);
				commit("SET_RESULT_LIST", result);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async getById({ commit }, id: number) {
			try {
				const response = await CampaignService.show(id);
				//commit('SET_CAMPAIGN', response);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error, { to: "" });
				return await Promise.reject(error);
			}
		},
		async createAssociationWithModifier(
			{ commit },
			params: { entity: any }
		) {
			try {
				const response = await CampaignService.createAssociation(
					params.entity
				);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async setActive({ commit }, params: { id: number; active: Boolean }) {
			try {
				const response = await CampaignService.setActive(
					params.id,
					params.active
				);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error, { to: "" });
				return await Promise.reject(error);
			}
		},

		async continentsList({ commit }, params) {
			try {
				const response = await CampaignService.getContinentList();
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async getCampaign({ commit }, id: number) {
			try {
				const response = await CampaignService.show(id);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
	},
};
