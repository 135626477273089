export enum LikeType {
	GEO = "geo",
	POIS = "pois",
}

export const AssignIDs = [-1000, -1001, -1002, -2000, -2001, -2002, -2003];

export enum AssignID {
	states = -1000,
	cities = -1001,
	neighborhoods = -1002,
	categories = -2000,
	subcategories = -2001,
	brands = -2002,
	names = -2003,
}

export enum LikeName {
	STATES = "states",
	CITIES = "cities",
	NEIGHBORHOODS = "neighborhoods",
	CATEGORIES = "categories",
	SUBCATEGORIES = "subcategories",
	BRANDS = "brands",
	NAMES = "names",
	PRIVATES = "privates"
}

export interface LikeData {
	all: Boolean;
	term: string | null;
}

export interface LikeAllGeo {
	states: LikeData;
	cities: LikeData;
	neighborhoods: LikeData;
}

export interface LikeAllPois {
	categories: LikeData;
	subcategories: LikeData;
	brands: LikeData;
	names: LikeData;
}

export interface LikeDataAll {
	geo: LikeAllGeo;
	pois: LikeAllPois;
}
