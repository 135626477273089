
import Vue from "vue";

export default Vue.extend({

    name: "ActionsTable",
    props: { },
    components: {},

    data: () => ({}),

    created() {},
	mounted() {},

    computed: {

        checkStatus(){
            return this.params.checkStatus;
        },

        disabledDownload(){
            if(this.checkStatus){
                return this.params.data.status != "ready";
            }
            return false;
        },

        getRedirect(){
            return this.params.redirect;
        },

        getID(){
            return this.params.data.id;
        },

        getExternalID(){
            return this.params.data.external_id;
        },

        getToActivityLog(){
            return this.params.activityLog;
        },

        getEntityName(){
            return this.params.entityName;
        },
		getSendEmail(){
            return this.params.sendEmail;
        },


        getTagEdit(){
            return "Edit " + this.getEntityName;
        },

        getTagDuplicated(){
            return "Duplicate " + this.getEntityName;
        },

        getTagLog(){
            return "Activity Log " + this.getEntityName;
        },

        getTagDownload(){
            return "Download";
        },
		getTagSendEmail(){
            return "Send Email";
        },
        getTagList(){
            return "View Lines ";
        },

        showConvertLatLong(){
            return this.params.convertLatLong && this.params.contextT.componentParent.shouldShowConvertLatLong(this.getID);
        },

        showEdit(){
            return this.params.edit;
        },
		showSendEmail(){
            return this.params.sendEmail;
        },

        showDuplicated(){
            return this.params.duplicated;
        },

        showActivityLog(){
            return this.params.log;
        },

        showDownload(){
            return this.params.download;
        },
        showList(){
            return this.params.list;
        }

    },

    methods: {

        redirectToEdit(){
            this.$router.push({
                name: this.getRedirect,
                params: { id: this.getID }
            });
        },

        redirectToActivityLog(){
            this.$router.push({
                name: this.getToActivityLog,
                query: { id: this.getID }
            });
        },

        redirectToLines(){
            this.$router.push({
                name: "LineItemList",
                query: { campaign_id: this.getID }
            });
        },

        invokeParentDuplicatedMethod() {
            let param = {
                id: this.getID,
                external_id: this.getExternalID
            }
            this.params.contextT.componentParent.openDialogDuplicated(param);
        },
		invokeParentSendEmail() {
            let param = {
                id: this.getID,
            }
            this.params.contextT.componentParent.sendEmailCPI(param);
        },
        invokeParentDownloadMethod() {
            let param = {
                id: this.getID,
            }
            this.params.contextT.componentParent.downloadReport(param);
        },

        invokeConvertToPrivatePois() {
            let param = {
                id: this.getID
            }
            this.params.contextT.componentParent.openDialogPrivatePois(param);
        }
    },

    watch:{

    }

});
