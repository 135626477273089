import {
    AxiosPost,
    AxiosGet,
    GetData,
    GetErrors,
    GetMessage,
    AxiosDelete
} from '@/services/axios-service'
import {
    SegmentCategoryDataCreate,
    SegmentCategoryFilter,
    SegmentCategoryOption,
    SegmentCategoryPaginated
} from '@/interfaces/segment_category'
import { isEmpty, isUndefined } from 'lodash'

export const SEGMENT_CATEGORY_ROUTE = '/api/segment_categories'
export const SEGMENT_CATEGORY_DELETE_ROUTE = '/api/segment_categories'

class SegmentCategoryService {
    
    async paginated(params: { paginated?: SegmentCategoryPaginated, filters?: SegmentCategoryFilter, options?: SegmentCategoryOption }) {
        try {
            let filter = ''
            let option = ''

            if (!isUndefined(params.filters)) {
                filter = getFilters(params.filters)
            }

            if (!isUndefined(params.options)) {
                option += getOptions(params.options, 'paginated', params.paginated)
            }

            const url = getURL(filter, option)
            const response = await AxiosGet(SEGMENT_CATEGORY_ROUTE + url)
            return Promise.resolve(GetData(response));
            
        } catch (error) {
            return Promise.reject({
                success: false,
                message: GetMessage(error),
                errors: GetErrors(error)
            });
        }
    }

    async all(params: { filters?: SegmentCategoryFilter, options?: SegmentCategoryOption }) {
        try {
            let filter = ''
            let option = ''

            if (!isUndefined(params.filters)) {
                filter = getFilters(params.filters)
            }

            if (!isUndefined(params.options)) {
                option += getOptions(params.options, 'all')
            }

            const url = getURL(filter, option);
            const response = await AxiosGet(SEGMENT_CATEGORY_ROUTE + url)
            return Promise.resolve(GetData(response));
            
        } catch (error) {
            return Promise.reject({
                success: false,
                message: GetMessage(error),
                errors: GetErrors(error)
            });
        }
    }

    async show(id: number) {
        try {
            const response = await AxiosGet(`${SEGMENT_CATEGORY_ROUTE}/${id}`);
            return Promise.resolve(GetData(response));
        } catch (error) {
            return Promise.reject({
                success: false,
                message: GetMessage(error),
                errors: GetErrors(error)
            });
        }
    }

    async create(entity: SegmentCategoryDataCreate) {
        try {
            const response = await AxiosPost(SEGMENT_CATEGORY_ROUTE, entity);
            return Promise.resolve(GetData(response));
        } catch (error) {
            return Promise.reject({
                success: false,
                message: GetMessage(error),
                errors: GetErrors(error)
            });
        }
    }

    async delete(id: number) {
        try {
            const response = await AxiosDelete(`${SEGMENT_CATEGORY_DELETE_ROUTE}/${id}`);
            return Promise.resolve(GetData(response));
        } catch (error) {
            return Promise.reject({
                success: false,
                message: GetMessage(error),
                errors: GetErrors(error)
            });
        }
    }

}

function getFilters(filters: SegmentCategoryFilter): string {
    let filter = ''
    
    const name = (isUndefined(filters.name)) ? '' : filters.name
    const external_id = (isUndefined(filters.external_id)) ? '' : filters.external_id
    const active = (isUndefined(filters.active)) ? '' : filters.active
    const advertiser_id = (isUndefined(filters.advertiser_id)) ? '' : filters.advertiser_id
    const key = (isUndefined(filters.key)) ? '' : filters.key
    const parent_id = (isUndefined(filters.parent_id)) ? '' : filters.parent_id
    
    filter += 'filters[name]=' + name + '&filters[advertiser_id]=' + advertiser_id + '&filters[key]=' + key + '&filters[parent_id]=' + parent_id + '&filters[external_id]=' + external_id + '&filters[active]=' + active
    
    return filter
}

function getOptions(options: SegmentCategoryOption, mode: string, paginated?: SegmentCategoryPaginated): string {
    let option = ''

    const sort = (isUndefined(options.sort)) ? '' : options.sort
    const order = (isUndefined(options.order)) ? '' : options.order

    option += 'sort=' + sort + '&order=' + order + '&mode=' + mode

    if (mode == 'paginated') {
        option += '&page=' + paginated?.page + '&limit=' + paginated?.limit
    }

    return option
}

function getURL(filters: string, options: string): string {
    let url = ''

    if (!isEmpty(filters) && !isEmpty(options)) {
        url = '?' + filters + '&' + options
    } else if (!isEmpty(filters)) {
        url = '?' + filters
    } else if (!isEmpty(options)) {
        url = '?' + options
    }

    return url
}

export default new SegmentCategoryService()