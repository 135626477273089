import DashboardService from "@/services/dashboard-service";
import { catchError } from "@/store/Util";

export const DashboardModule = {
	namespaced: true,
	state: () => ({
		report_list: [] as Array<any>,
	}),
	mutations: {
		SET_DATA(state: any, params: { key: string; value: any }) {
			state[params.key] = params.value;
		},
	},
	getters: {
		getData: (state: any) => (key: string) => {
			return state[key];
		},
	},
	actions: {
		setData({ commit }: any, params: { key: string; value: any }) {
			return new Promise((resolve) => {
				commit("SET_DATA", params);
				resolve(params);
			});
		},
		async getReportList() {
			try {
				const response: Array<any> =
					await DashboardService.reportList();
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
	},
};
