import { isEmpty, isNull, isNumber, isUndefined } from "lodash";
import {
	ElementData,
	Person,
	GeoFilterData,
	PoisFilterData,
	Audience,
	ActionLoading,
	ActionResponse,
	ElementDataWithType,
	PrivateFilterData,
} from "@/interfaces/persons/v10/person";
import {
	PersonKeyof,
	PersonKey,
	GeoFilterDataKeyof,
	GeoKeyof,
	PoisKeyof,
	TypeFilterKey,
	PersonGeoKey,
	PersonPoisKey,
	ActivePanelTab,
	AnalizeType,
	MatchFilter,
	PersonStorageKey,
} from "@/interfaces/persons/v10/types";
import {
	SelectedDataEntity,
	GeoFilterDataEntity,
	PoisFilterDataEntity,
	PersonAudienceEntity,
	PersonStoreAttributionEntity,
	ActionLoadingEntity,
	ActionResponseEntity,
	GraphicResponseEntity,
	PersonTargetAudienceEntity,
	PersonSavePoisEntity,
	PersonGeoFencingEntity,
	PrivateFilterDataEntity,
	MaidUnique,
} from "./Implements";
import {
	ConfigPaginate,
	ConfigPost,
	Geography,
} from "@/interfaces/persons/v10/response";
import { ConfigPostEntity, GeographyEntity } from "./response";
import {
	AssignID,
	AssignIDs,
	LikeName,
} from "@/interfaces/persons/v10/select_like";
import { Mode } from "@/interfaces/persons/v10/query/global";
import { PostDataEntity } from "./Query/Pois";
import {
	ModuleSelectAllEntity,
	SelectAllGeoEntity,
	SelectAllPoisEntity,
	SelectAllPrivateEntity,
	TotalTypeAllEntity,
} from "./SelectAll/Class";
import { TotalTypeAll } from "@/interfaces/persons/v10/select_all";
import i18n from "@/plugins/i18n";
import { getFromStorage } from "@/services/storage-service";
import { isBackFromAudience } from "@/utils/services-global";
import { convLocaleString } from "@/utils/convert";

export const endpointKeys = {
	BRAZIL: "brasil",
	COLOMBIA: "colombia",
	PORTUGAL: "portugal",
	SPAIN: "spain",
	CHILE: "chile",
	TURKEY: "turkey",
	WORLD: "world",
};

export const countryCodeNames = {
	76: "BRAZIL",
	170: "COLOMBIA",
	620: "PORTUGAL",
	724: "SPAIN",
	152: "CHILE",
	792: "TURKEY",
};

export const countryDefault: ElementData = {
	id: 170,
	value: "Colombia",
};

export const countryPortugal: ElementData = {
	id: 620,
	value: "Portugal",
};

export const audienceTypeDefault: ElementData = {
	id: 1,
	value: "People that were seen close to specific points (POIs)",
};

export const audienceTypePois: number[] = [1, 4];
export const audienceTypeGeo: number[] = [2, 3];

export const audienceTypeIDDefault: number = 1;

export const shownMaxSelection: number = 100;

export const hideViewClearFilters: string[] = [
	"names",
	"neighborhoods",
	"privates",
];

export class PersonEntity implements Person {
	country_global: ElementData = new SelectedDataEntity({
		id: countryDefault.id,
		value: countryDefault.value,
	});
	audience_type: ElementData = new SelectedDataEntity({
		id: audienceTypeDefault.id,
		value: audienceTypeDefault.value,
	});
	geo: GeoFilterData = new GeoFilterDataEntity();
	pois: PoisFilterData = new PoisFilterDataEntity();
	privates: PrivateFilterData = new PrivateFilterDataEntity();
	store_attribution: PersonStoreAttributionEntity =
		new PersonStoreAttributionEntity();
	target_audience = new PersonTargetAudienceEntity();
	save_pois = new PersonSavePoisEntity();
	geo_fencing = new PersonGeoFencingEntity();
	audience: Audience = new PersonAudienceEntity();
	/**
	 * ButtonAction
	 * Loading & Response
	 */
	loading: ActionLoading = new ActionLoadingEntity();
	response: ActionResponse = new ActionResponseEntity();

	/**
	 * Graphics
	 * pois & audience
	 * Loading & ItemGraphic[]
	 *
	 */
	graphics: GraphicResponseEntity = new GraphicResponseEntity();

	// Select All Data
	select_all: ModuleSelectAllEntity = new ModuleSelectAllEntity();

	createPerson(properties: PersonEntity) {
		this.country_global = new SelectedDataEntity({
			id: properties.country_global.id,
			value: properties.country_global.value,
		});
		this.audience_type = new SelectedDataEntity({
			id: properties.audience_type.id,
			value: properties.audience_type.value,
		});
		this.geo = new GeoFilterDataEntity(properties.geo);
		this.pois = new PoisFilterDataEntity(properties.pois);
		this.privates = new PrivateFilterDataEntity(properties.privates);
		this.store_attribution = new PersonStoreAttributionEntity(
			properties.store_attribution
		);
		this.target_audience = new PersonTargetAudienceEntity(
			properties.target_audience
		);
		this.save_pois = new PersonSavePoisEntity(properties.save_pois);
		this.geo_fencing = new PersonGeoFencingEntity(properties.geo_fencing);
		this.loading = new ActionLoadingEntity();
		this.response = new ActionResponseEntity(properties.response);
		this.graphics = new GraphicResponseEntity(properties.graphics);
		this.select_all = new ModuleSelectAllEntity(properties.select_all);

		return {
			country_global: this.country_global,
			audience_type: this.audience_type,
			geo: this.geo,
			pois: this.pois,
			privates: this.privates,
			store_attribution: this.store_attribution,
			save_pois: this.save_pois,
			geo_fencing: this.geo_fencing,
			loading: this.loading,
			response: this.response,
			graphics: this.graphics,
			select_all: this.select_all,
		} as PersonEntity;
	}

	constructor(country?: ElementData, audience_type?: ElementData) {
		if (isBackFromAudience()) {
			const person: PersonEntity | undefined = this.getDataFromStorage();
			if (!person) return;
			const p = this.createPerson(person);
			return;
		}

		if (country) {
			this.country_global = country;
		}
		if (audience_type) {
			this.audience_type = audience_type ?? audienceTypeDefault;
		}
	}

	getDataFromStorage(): PersonEntity | undefined {
		const storedPerson = getFromStorage(PersonStorageKey.STORED_PERSON);
		return storedPerson ? JSON.parse(storedPerson) : undefined;
	}

	// Getters

	getCountry() {
		return this.country_global;
	}

	getByKey(key: PersonKeyof) {
		return this[key];
	}

	getCountryCode(
		attribute: "id" | "value" = "id",
		type?: "string" | "array"
	) {
		let countryGlobal: ElementData = this.country_global;

		let countries: ElementData[] = this.hasCountry() ? [countryGlobal] : [];

		if (type === "string") {
			return countryGlobal[attribute];
		}

		if (type === "array") {
			return countries.map((c) => {
				if (!isNumber(c[attribute])) {
					return String(c[attribute]).toUpperCase();
				}
				return c[attribute];
			});
		}

		return countryGlobal;
	}

	getSelectedByType(type: PersonKeyof) {
		if (type == PersonKey.COUNTRY_GLOBAL) {
			return this.hasCountry() ? [this.country_global] : [];
		}
		if (type == PersonKey.AUDIENCE_TYPE) {
			return this.hasAudienceType() ? [this.audience_type] : [];
		}
		if (type == PersonKey.GEO) {
			return [
				...this.geo.selected.states,
				...this.geo.selected.cities,
				...this.geo.selected.neighborhoods,
			];
		}
		if (type == PersonKey.POIS) {
			return [
				...this.pois.selected.categories,
				...this.pois.selected.subcategories,
				...this.pois.selected.brands,
				...this.pois.selected.names,
			];
		}
		if (type == PersonKey.PRIVATE) {
			return [...this.privates.selected.privates];
		}
		return [];
	}

	addToolbar(type: TypeFilterKey) {
		let filters = [] as any[];

		const filterSelecteds = this.geo[type];

		for (const [key, value] of Object.entries(filterSelecteds)) {
			for (const element of value) {
				filters.push({
					type: type,
					key: key,
					id: element.id,
					value: element.value,
					tooltip: `${element.value} (${key.toUpperCase()})`,
				});
			}
		}

		return {
			title: type === TypeFilterKey.SELECTED ? "Geography" : "And",
			filters: filters.flat(),
		};
	}

	getGeoToolbars() {
		let toolbars = [this.addToolbar(TypeFilterKey.SELECTED)];

		if (!this.isTypeAudiencePois()) {
			toolbars.push(this.addToolbar(TypeFilterKey.AND));
		}

		return toolbars;
	}

	async getFilterNameByType(type: PersonKeyof, item: ElementData) {
		if (type == PersonKey.GEO) {
			if (this.geo.selected.states.includes(item)) return "states";

			if (this.geo.selected.cities.includes(item)) return "cities";

			if (this.geo.selected.neighborhoods.includes(item))
				return "neighborhoods";
		}

		if (type == PersonKey.POIS) {
			if (this.pois.selected.categories.includes(item))
				return "categories";

			if (this.pois.selected.subcategories.includes(item))
				return "subcategories";

			if (this.pois.selected.names.includes(item)) return "names";

			if (this.pois.selected.brands.includes(item)) return "brands";
		}

		if (type == PersonKey.PRIVATE) {
			if (this.privates.selected.privates.includes(item))
				return "privates";
		}

		return "";
	}

	getSelectedFilters(type: PersonKeyof) {
		let filters: ElementData[] = [];

		if (type == PersonKey.COUNTRY_GLOBAL) {
			return this.hasCountry()
				? [{ ...this.country_global, type: PersonKey.COUNTRY_GLOBAL }]
				: [];
		}
		if (type == PersonKey.AUDIENCE_TYPE) {
			return this.hasAudienceType()
				? [{ ...this.audience_type, type: PersonKey.AUDIENCE_TYPE }]
				: [];
		}

		if (type == PersonKey.GEO) {
			return [
				...this.addTypeToElement(
					this.geo.selected.states,
					LikeName.STATES
				),
				...this.addTypeToElement(
					this.geo.selected.cities,
					LikeName.CITIES
				),
				...this.addTypeToElement(
					this.geo.selected.neighborhoods,
					LikeName.NEIGHBORHOODS
				),
			];
		}

		if (type == PersonKey.POIS) {
			return [
				...this.addTypeToElement(
					this.pois.selected.categories,
					LikeName.CATEGORIES
				),
				...this.addTypeToElement(
					this.pois.selected.subcategories,
					LikeName.SUBCATEGORIES
				),
				...this.addTypeToElement(
					this.pois.selected.brands,
					LikeName.BRANDS
				),
				...this.addTypeToElement(
					this.prepareChippedFilters(PersonKey.POIS, "names"),
					LikeName.NAMES
				),
			];
		}

		if (type == PersonKey.PRIVATE) {
			return [
				...this.addTypeToElement(
					this.privates.selected.privates,
					LikeName.PRIVATES
				),
			];
		}

		return filters;
	}

	addTypeToElement(
		items: ElementData[],
		type: LikeName
	): ElementDataWithType[] {
		return items.map((item) => ({
			...item,
			type,
		}));
	}

	prepareChippedFilters(
		type: GeoKeyof | PersonKey,
		name: keyof SelectAllGeoEntity | keyof SelectAllPoisEntity
	) {
		let elements: ElementData[] = this[type].selected[name];
		let like: TotalTypeAll = this.select_all[type][name];
		if (!isEmpty(elements) && like.total.checked && like.total.term) {
			elements = [
				{
					id: AssignID[name],
					value: i18n
						.t("select_all.like.names", {
							term: like.total.term,
						})
						.toString(),
				},
			];
		}

		if (isEmpty(elements)) {
			like = new TotalTypeAllEntity();
		}

		return elements;
	}

	getCountryArray() {
		if (!this.hasCountry()) return [];
		return [this.country_global.value];
	}

	getAudienceTypeArray() {
		if (!this.hasAudienceType()) return [];
		return [this.audience_type.value];
	}

	getParamsStates() {
		return this.geo.pre.states.map((s) => s.id);
	}

	getParamsCities() {
		return this.geo.pre.cities.map((s) => s.id);
	}

	getGeoConfig(paginate: ConfigPaginate, type: GeoKeyof): ConfigPaginate {
		let config: ConfigPaginate = {
			...paginate,
			country_code: this.country_global.id,
			filters: {},
		};

		config.filters["codigo_estado"] = this.geo.pre.states.map((s) => s.id);

		if (type === "cities") {
			if (this.getParamsStates().length > 0) {
				config.filters["codigo_estado"] = this.getParamsStates();
			}
		}

		if (type === "neighborhoods") {
			if (this.getParamsStates().length > 0) {
				config.filters["codigo_estado"] = this.getParamsStates();
			}
			if (this.getParamsCities().length > 0) {
				config.filters["codigo_municipio"] = this.getParamsCities();
			}
		}

		return config;
	}

	getPoisConfig(paginate: ConfigPaginate, type: PoisKeyof): ConfigPaginate {
		let config: ConfigPaginate = paginate;
		config = paginate;
		config.country_code = this.country_global.id;
		config.audience_type = this.audience_type.id;

		config.filters = {
			geography: [
				{
					codigo_estado: this.geo.selected.states.map((s) => s.id),
					codigo_ciudad: this.geo.selected.cities.map((s) => s.id),
					codigo_barrio: this.geo.selected.neighborhoods.map(
						(s) => s.id
					),
				},
			],
			categoria: undefined,
			subcategoria: undefined,
			marca: undefined,
			nombre: undefined,
		};

		if (type === "categories") return config;

		config.filters.categoria = this.pois.pre.categories.map((s) => s.value);

		if (type === "subcategories") return config;

		config.filters.subcategoria = this.pois.pre.subcategories.map(
			(s) => s.value
		);

		if (type === "brands") return config;

		config.filters.marca = this.pois.pre.brands.map((s) => s.value);

		return config;
	}

	getPostConfig(): ConfigPost {
		let config: ConfigPost = new ConfigPostEntity();
		config.country_code = this.country_global.id;
		config.audience_type = this.audience_type.id;

		config.filters = {
			geography: [
				{
					codigo_estado: this.geo.selected.states.map((s) => s.id),
					codigo_ciudad: this.geo.selected.cities.map((s) => s.id),
					codigo_barrio: this.geo.selected.neighborhoods.map(
						(s) => s.id
					),
				} as Geography,
			],
			categoria: this.pois.selected.categories.map(
				(s) => s.value
			) as string[],
			subcategoria: this.pois.selected.subcategories.map(
				(s) => s.value
			) as string[],
			marca: this.pois.selected.brands.map((s) => s.value) as string[],
			nombre: this.pois.selected.names.map((s) => s.value) as string[],
		};

		return config;
	}

	async addGeographyFilters(filterType: TypeFilterKey) {
		const check = {
			states: this.select_all.geo.states,
			cities: this.select_all.geo.cities,
			neighborhoods: this.select_all.geo.neighborhoods,
		};

		const geography: GeographyEntity = new GeographyEntity();

		geography.addGeography(
			"states",
			check.states,
			this.geo[filterType].states.map((f) => f.id)
		);

		geography.addGeography(
			"cities",
			check.cities,
			this.geo[filterType].cities.map((f) => f.id)
		);

		geography.addGeography(
			"neighborhoods",
			check.neighborhoods,
			this.geo[filterType].neighborhoods.map((f) => f.id)
		);

		return geography;
	}

	async addFilterToGeo(config: ConfigPostEntity, filterType: TypeFilterKey) {
		const check = {
			states: this.select_all.geo.states,
			cities: this.select_all.geo.cities,
			neighborhoods: this.select_all.geo.neighborhoods,
		};

		config.addGeoFilter(
			"states",
			check.states,
			this.geo[filterType].states.map((f) => f.id)
		);

		config.addGeoFilter(
			"cities",
			check.cities,
			this.geo[filterType].cities.map((f) => f.id)
		);

		config.addGeoFilter(
			"neighborhoods",
			check.neighborhoods,
			this.geo[filterType].neighborhoods.map((f) => f.id)
		);

		return config;
	}

	async addFilterToPois(config: ConfigPostEntity) {
		const check = {
			categories: this.select_all.pois.categories,
			subcategories: this.select_all.pois.subcategories,
			brands: this.select_all.pois.brands,
			names: this.select_all.pois.names,
			privates: this.select_all.privates.privates,
		};

		config.addPoisFilter(
			"categories",
			check.categories,
			this.pois.selected.categories.map((f) => f.value)
		);
		config.addPoisFilter(
			"subcategories",
			check.subcategories,
			this.pois.selected.subcategories.map((f) => f.value)
		);
		config.addPoisFilter(
			"brands",
			check.brands,
			this.pois.selected.brands.map((f) => f.value)
		);
		config.addPoisFilter(
			"names",
			check.names,
			this.pois.selected.names.map((f) => f.value)
		);

		config.addLayers(this.privates.selected.privates.map((f) => f.id));

		return config;
	}

	/**
	 * Get Body: Analize Geo
	 * @returns
	 */
	async getBodyAnalizeGeo(): Promise<ConfigPost> {
		// init config geo filters
		let config: ConfigPostEntity = new ConfigPostEntity({
			country_code: this.country_global.id,
			audience_type: this.audience_type.id,
		});

		// add geography: selected
		config = await this.addFilterToGeo(config, TypeFilterKey.SELECTED);

		if (this.hasElementData(PersonKey.GEO, TypeFilterKey.AND)) {
			// add geography: and (conditional)
			config = await this.addFilterToGeo(config, TypeFilterKey.AND);
		}

		return config;
	}

	/**
	 * Get Body: Analize Pois
	 * @returns
	 */
	async getBodyAnalizePois(): Promise<ConfigPost> {
		// init config post filters
		let config: ConfigPostEntity = new ConfigPostEntity({
			country_code: this.country_global.id,
			audience_type: this.audience_type.id,
		});

		// add geography filters
		config.setFilterGeography(
			await this.addGeographyFilters(TypeFilterKey.SELECTED)
		);

		// add filters pois
		config = await this.addFilterToPois(config);

		return config;
	}

	async getBodyPois(): Promise<ConfigPostEntity> {
		// init config geo filters
		let config: ConfigPostEntity = new ConfigPostEntity({
			country_code: this.country_global.id,
			audience_type: this.audience_type.id,
		});

		config.setFilterGeography(
			await this.addGeographyFilters(TypeFilterKey.SELECTED)
		);

		if (this.hasElementData(PersonKey.GEO, TypeFilterKey.AND)) {
			config.setFilterGeography(
				await this.addGeographyFilters(TypeFilterKey.AND)
			);
		}

		return config;
	}

	/**
	 * Get Body Post: Geo|Pois
	 * @returns
	 */
	async getPostBody(): Promise<ConfigPostEntity> {
		let config: ConfigPostEntity = new ConfigPostEntity({
			country_code: this.country_global.id,
			audience_type: this.audience_type.id,
		});

		// add geography filters
		config.setFilterGeography(
			await this.addGeographyFilters(TypeFilterKey.SELECTED)
		);

		if (this.isTypeAudiencePois()) {
			config = await this.addFilterToPois(config);
		} else {
			if (this.hasElementData(PersonKey.GEO, TypeFilterKey.AND)) {
				config.setFilterGeography(
					await this.addGeographyFilters(TypeFilterKey.AND)
				);
			}

			return this.parsedGeoPostBody(config);
		}

		return config;
	}

	/**
	 * Parsed Post Body for Geo
	 * @param config
	 * @returns
	 */
	parsedGeoPostBody(config: ConfigPostEntity) {
		config.filters.geography = config.filters.geography.map((g) => {
			if (g.codigo_ciudad) {
				if (g.codigo_ciudad) {
					g["codigo_municipio"] = g.codigo_ciudad;
				}
				if (g._like_ciudad) {
					g["_like_municipio"] = g._like_ciudad;
				}

				delete g.codigo_ciudad;
				delete g._like_ciudad;
			}

			return g;
		});

		return config;
	}

	async getPostParamByFilter(
		posData: PostDataEntity,
		poisType: PersonPoisKey,
		mode?: Mode
	): Promise<ConfigPost> {
		let paginateData = posData.paginateData[poisType];

		if (mode) {
			await paginateData.setMode(mode);
		}

		let config: ConfigPostEntity = await this.getBodyPois();

		switch (poisType) {
			case PersonPoisKey.CATEGORIES:
				break;

			case PersonPoisKey.SUBCATEGORIES:
				config.filters.categoria = this.pois.pre.categories.map(
					(s) => s.value
				);
				break;

			case PersonPoisKey.BRANDS:
				config.filters.categoria = this.pois.pre.categories.map(
					(s) => s.value
				);
				config.filters.subcategoria = this.pois.pre.subcategories.map(
					(s) => s.value
				);
				break;

			case PersonPoisKey.NAMES:
				config.filters.categoria = this.pois.pre.categories.map(
					(s) => s.value
				);
				config.filters.subcategoria = this.pois.pre.subcategories.map(
					(s) => s.value
				);
				config.filters.marca = this.pois.pre.brands.map((s) => s.value);
				break;
		}

		let configPaginateData = {
			...paginateData,
			...config,
		};

		return configPaginateData;
	}

	getFiltersForCarto() {
		return {
			_country: this.getCountryCode("id", "array"),
			_poisCategory: this.pois.selected.categories,
			_poisSubCategory: this.pois.selected.subcategories,
			_poisMarca: this.pois.selected.brands,
			_poisName: this.pois.selected.names,
			_state: this.geo.selected.states,
			_municipalities: this.geo.selected.cities,
			_neighborhood: this.geo.selected.neighborhoods,
		};
	}

	getConfigModeAll() {
		return {
			country_code: this.country_global.id,
			mode: Mode.ALL,
		};
	}

	getEndpointKey() {
		const country_code = this.country_global?.id;
		const country_name = countryCodeNames[country_code] || "WORLD";

		return endpointKeys[country_name];
	}

	getEndpoint() {
		return process.env.VUE_APP_CARTO_URL;
		// return `${process.env.VUE_APP_CARTO_URL}/${this.getEndpointKey()}`;
	}

	// Setters

	setCountry(country: ElementData) {
		this.country_global = country;
	}

	setByKey(key: PersonKeyof, value: any) {
		this[key] = value;
	}

	// Checkers

	hasCountry() {
		return !isUndefined(this.country_global.id);
	}

	hasAudienceType() {
		return (
			!isUndefined(this.audience_type.id) &&
			!isNull(this.audience_type.id) &&
			!isNaN(this.audience_type.id)
		);
	}

	hasStates() {
		return Boolean(this.geo.selected.states.length > 0);
	}

	hasCities() {
		return Boolean(this.geo.selected.cities.length > 0);
	}

	hasNeighborhoods() {
		return Boolean(this.geo.selected.neighborhoods.length > 0);
	}

	hasCategories() {
		return Boolean(this.pois.selected.categories.length > 0);
	}

	hasSubcategories() {
		return Boolean(this.pois.selected.subcategories.length > 0);
	}

	hasBrands() {
		return Boolean(this.pois.selected.brands.length > 0);
	}

	hasNames() {
		return Boolean(this.pois.selected.names.length > 0);
	}

	hasGeoSelected() {
		return this.hasElementData(PersonKey.GEO, TypeFilterKey.SELECTED);
	}

	hasPrivateSelected() {
		return this.hasElementData(PersonKey.PRIVATE, TypeFilterKey.SELECTED);
	}

	hasPoisSelected() {
		return this.hasElementData(PersonKey.POIS, TypeFilterKey.SELECTED);
	}

	isCertainArea() {
		if (!this.audience_type.id) return false;
		return audienceTypeGeo.includes(this.audience_type.id);
	}

	enabledGraphicPois() {
		if (!this.audience_type.id) return false;
		return audienceTypePois.includes(this.audience_type.id);
	}

	isTypeAudiencePois() {
		if (!this.audience_type.id) return false;
		return audienceTypePois.includes(this.audience_type.id);
	}

	isTypeAudienceGeo() {
		if (!this.audience_type.id) return false;
		return audienceTypeGeo.includes(this.audience_type.id);
	}

	isAnalizedPois() {
		const response = this.response.action.analize_pois.response;
		return typeof response === "boolean" && Boolean(response);
	}

	isAnalizedGeo() {
		const response = this.response.action.analize_geo.response;
		return typeof response === "boolean" && Boolean(response);
	}

	isAnalized() {
		const typeAction = this.isTypeAudiencePois()
			? AnalizeType.POIS
			: AnalizeType.GEO;
		const response = this.response.action[typeAction].response;
		return typeof response === "boolean" && Boolean(response);
	}

	isEnableAnalizePois() {
		return (
			this.hasCountry() &&
			this.hasAudienceType() &&
			(this.hasGeoSelected() || this.hasPoisSelected())
		);
	}

	// Actions

	/**
	 * Toggle Select All
	 * @param key "country_global|audience_type|geo"
	 * @param type "pre|selected|and"
	 * @param filter "states|cities|neighborhoods"
	 * @param elements "{id,value}[]"
	 */
	async toggleSelectAll(
		key: PersonKeyof,
		type: GeoFilterDataKeyof,
		filter: GeoKeyof,
		elements: ElementData[]
	) {
		let elems: ElementData[] = elements;

		const elemUniq: ElementData[] = [...new Set(elems)] as ElementData[];

		this[key][type][filter] = elemUniq;

		if (type === ("selected" as GeoFilterDataKeyof)) {
			this[key]["pre"][filter] = elemUniq;
		}

		this[key]["and"][filter] = [];
	}

	/**
	 * Toggle Selected All
	 * @param params
	 * @returns
	 */
	async toggleSelectedAll(params: {
		key: PersonKeyof;
		type: GeoFilterDataKeyof;
		all: Boolean;
		filter: PersonGeoKey | PersonPoisKey;
		elements: ElementData[];
	}) {
		const { key, type, filter, all, elements } = params;

		let selectedData: ElementData[] = this[key][type][filter];

		let mergedSelectedData: ElementData[] = [] as ElementData[];

		if (all) {
			mergedSelectedData = selectedData.concat(elements);
			// Filtrar elementos duplicados
			mergedSelectedData = this.uniqueElements(mergedSelectedData);
		} else {
			mergedSelectedData = selectedData.filter(
				(s) => !elements.some((e) => e.id === s.id)
			);
		}

		if (type === ("selected" as GeoFilterDataKeyof)) {
			// this[key]["pre" as GeoFilterDataKeyof][filter] = mergedSelectedData;
			if (
				![PersonGeoKey.NEIGHBORHOODS, PersonPoisKey.NAMES].includes(
					filter
				)
			) {
				this[key][TypeFilterKey.PRE][filter] = mergedSelectedData;
			}
		}

		this[key][type][filter] = mergedSelectedData;
		this[key]["and" as GeoFilterDataKeyof][filter] = [] as ElementData[];
	}

	/**
	 *
	 * @param params
	 */
	async togglePreFilter(params: {
		key: PersonKey;
		filter: PersonGeoKey | PersonPoisKey;
		toggle: Boolean;
		element: ElementData;
	}) {
		const { key, filter, toggle, element } = params;
		let elems: ElementData[] = this[key][TypeFilterKey.PRE][filter];

		if (toggle) {
			elems.push(element);
		} else {
			elems = elems.filter((i) => i.id !== element.id);
			await this.cleanFilterChilds(
				key,
				TypeFilterKey.PRE,
				filter,
				element
			);
		}

		const elemUniq: ElementData[] = [...new Set(elems)] as ElementData[];

		if (
			![PersonGeoKey.NEIGHBORHOODS, PersonPoisKey.NAMES].includes(filter)
		) {
			this[key][TypeFilterKey.PRE][filter] = elemUniq;
		}
	}

	/**
	 *
	 * @param key "country_global|audience_type|geo"
	 * @param type "pre|selected|and"
	 * @param filter "states|cities|neighborhoods"
	 * @param element "{id,value}"
	 */
	async updatePreFilter(
		key: PersonKey,
		type: TypeFilterKey,
		filter: PersonGeoKey | PersonPoisKey,
		element: ElementData
	) {
		let elems: ElementData[] = this[key][type][filter];

		if (elems.some((e) => e.id === element.id)) {
			elems = elems.filter((i) => i.id !== element.id);
			await this.cleanFilterChilds(key, type, filter, element);
		} else {
			elems.push(element);
		}

		const elemUniq: ElementData[] = [...new Set(elems)] as ElementData[];

		this[key][type][filter] = elemUniq;
	}

	async cleanPreSeleccion(
		key: PersonKey,
		type: TypeFilterKey,
		filter: PersonGeoKey | PersonPoisKey,
		field: MatchFilter,
		compare: ElementData
	) {
		let elements: ElementData[] = this[key][type][filter];
		this[key][type][filter] = elements.filter(
			(s) => s[field] !== compare.value
		);
	}

	async cleanAllFilterChilds(filter: PersonGeoKey | PersonPoisKey) {
		if (
			![
				PersonPoisKey.CATEGORIES,
				PersonPoisKey.SUBCATEGORIES,
				PersonPoisKey.BRANDS,
				PersonPoisKey.NAMES,
			].includes(filter as PersonPoisKey)
		)
			return;

		this.pois.pre.names = [];

		if (
			[PersonPoisKey.CATEGORIES, PersonPoisKey.SUBCATEGORIES].includes(
				filter as PersonPoisKey
			)
		) {
			this.pois.pre.brands = [];
		}

		if (filter !== PersonPoisKey.CATEGORIES) return;

		this.pois.pre.subcategories = [];
	}

	async cleanFilterChilds(
		key: PersonKey,
		type: TypeFilterKey,
		filter: PersonGeoKey | PersonPoisKey,
		element: ElementData
	) {
		if (
			![
				PersonPoisKey.CATEGORIES,
				PersonPoisKey.SUBCATEGORIES,
				PersonPoisKey.BRANDS,
				PersonPoisKey.NAMES,
			].includes(filter as PersonPoisKey)
		)
			return;

		/**
		 * Names siempre se limpia
		 */
		this.cleanPreSeleccion(
			key,
			type,
			PersonPoisKey.NAMES,
			MatchFilter[filter],
			element
		);

		/**
		 * Brands se limpia solo si {filter} -> categories | subcategories
		 */
		if (
			[PersonPoisKey.CATEGORIES, PersonPoisKey.SUBCATEGORIES].includes(
				filter as PersonPoisKey
			)
		) {
			this.cleanPreSeleccion(
				key,
				type,
				PersonPoisKey.BRANDS,
				MatchFilter[filter],
				element
			);
		}

		if (filter !== PersonPoisKey.CATEGORIES) return;

		/**
		 * Subcategories solo se limpia si {filter} -> categories
		 */
		this.cleanPreSeleccion(
			key,
			type,
			PersonPoisKey.SUBCATEGORIES,
			MatchFilter[filter],
			element
		);
	}

	existElementData(elements: ElementData[], id: number) {
		return elements.some((e) => e.id === id);
	}

	mergeElements(elements1: ElementData[], elements2: ElementData[]) {
		let set = new Set([...elements1, ...elements2]);
		let mergedArray = [...set];
		return mergedArray;
	}

	uniqueElements(elements: ElementData[]) {
		return elements.filter(
			(item, index, self) =>
				self.findIndex((i) => i.id === item.id) === index
		);
	}

	addDeleteElement(elements: ElementData[], element: ElementData) {
		const index = elements.findIndex((item) => item.id === element.id);

		if (index !== -1) {
			// El elemento ya existe en el elements, eliminarlo
			elements.splice(index, 1);
		} else {
			// El elemento no existe en el elements, agregarlo
			elements.push(element);
		}

		// Filtrar elementos duplicados
		return this.uniqueElements(elements);
	}

	/**
	 *
	 * @param key "country_global|audience_type|geo|pois"
	 * @param type "pre|selected|and"
	 * @param filter "states|cities|neighborhoods"
	 * @param element "{id,value}"
	 */
	async updateFilter(
		key: PersonKey,
		type: TypeFilterKey,
		filter: PersonGeoKey | PersonPoisKey,
		element: ElementData
	) {
		let filterSelecteds: ElementData[] = this[key][type][filter];

		this[key][type][filter] = this.addDeleteElement(
			filterSelecteds,
			element
		);
	}

	addSelectedState(key: string, element: ElementData) {
		let elements: ElementData[] = this.geo.selected[key];
		if (elements.includes(element)) {
			this.geo.selected[key] = elements.filter(
				(i) => i.id !== element.id
			);
			return;
		}
		this.geo.selected[key].push(element);
		const uniqueElements = [...new Set(this.geo.selected[key])];
		this.geo.selected[key] = uniqueElements;
	}

	/**
	 * Set New filters
	 * @param key "geo"
	 * @param type "pre|selected|and"
	 * @param filter "states|cities|neighborhoods"
	 * @param elements "[{id,value}]"
	 */
	async setNewFilters(
		key: string,
		type: string,
		filter: string,
		elements: ElementData[]
	) {
		const uniqueElements = [...new Set(elements)];
		this[key][type][filter] = uniqueElements;
	}

	/**
	 * Draggend From
	 * @param key "country_global|audience_type|geo"
	 * @param type "filters|pre|selected|and"
	 * @param element "{id,value}"
	 */
	draggendFilter(type: PersonKeyof, key: GeoFilterDataKeyof, element: any) {
		const elementData: ElementData = {
			id: element.id,
			value: element.value,
			count: element.count,
		};
		let elems: ElementData[] = this[type][key][element.key];
		elems.push(elementData);
		const uniqueElements = [...new Set(elems)];
		this[type][key][element.key] = uniqueElements;
	}

	/**
	 *
	 * Remove From Selected
	 * @param element
	 * @param personKey
	 * @returns
	 */
	removeFromSelected(
		element: ElementData,
		personKey: PersonKey,
		filterType?: TypeFilterKey
	) {
		if (PersonKey.COUNTRY_GLOBAL === personKey) {
			this.country_global = new SelectedDataEntity();
			return;
		}

		if (PersonKey.AUDIENCE_TYPE === personKey) {
			this.audience_type = new SelectedDataEntity();
			return;
		}

		if (
			[PersonKey.GEO, PersonKey.POIS, PersonKey.PRIVATE].includes(
				personKey
			)
		) {
			if (!filterType) return;

			let type = PersonKey.POIS;

			switch (personKey) {
				case PersonKey.GEO:
					type = PersonKey.GEO;
					break;
				case PersonKey.POIS:
					type = PersonKey.POIS;
					break;
				case PersonKey.PRIVATE:
					type = PersonKey.PRIVATE;
					break;
			}

			if (AssignIDs.includes(element.id)) {
				if (!element.type) return;
				this[personKey][filterType][element.type] = [] as ElementData[];

				// reset select all & like
				this.select_all.resetByType(type);
			} else {
				this.filterElementDataUnique(element, personKey, filterType);

				if (filterType === TypeFilterKey.SELECTED) {
					this.filterElementDataUnique(
						element,
						personKey,
						TypeFilterKey.PRE
					);
				}
			}

			if (!this.hasElementData(personKey, TypeFilterKey.SELECTED)) {
				this.resetElementDataByKey(personKey, TypeFilterKey.AND);
			}
		}
	}

	/**
	 * Filter Element Data by [key]
	 * @param element ElementData
	 * @param personKey geo|pois
	 * @param filterType selected|pre|and
	 */
	filterElementDataUnique(
		element: ElementData,
		personKey: PersonKey,
		filterType: TypeFilterKey
	) {
		let filters = Object.entries(this[personKey][filterType]);
		for (const [filterKey, filter] of filters) {
			let filtered: ElementData[] = (filter as ElementData[]).filter(
				(f) => f.id !== element.id
			);
			const uniqueElements = [...new Set(filtered)];
			this[personKey][filterType][filterKey] = uniqueElements;
		}
	}

	/**
	 * Delete elementData from filters
	 * @param personKey geo|pois
	 * @param filterType selected|pre|and
	 * @param filterKey
	 * @param element
	 */
	deleteElementData(
		personKey: PersonKey, // geo|pois
		filterType: TypeFilterKey, //selected|pre|and
		filterKey: PersonGeoKey | PersonPoisKey, // states|cities|neighborhoods
		element: ElementData
	) {
		let filters: ElementData[] = this[personKey][filterType][filterKey];
		let filtered: ElementData[] = filters.filter(
			(f) => f.id !== element.id
		);
		const uniqueElements = [...new Set(filtered)];
		this[personKey][filterType][filterKey] = uniqueElements;
	}

	/**
	 *
	 * Reset All Element Data
	 * @param personKey geo|pois
	 * @param filterType selected|pre|and
	 */
	resetElementDataByKey(personKey: string, filterType: string) {
		let filters = Object.entries(this[personKey][filterType]);
		for (const [filterKey] of filters) {
			this[personKey][filterType][filterKey] = [] as ElementData[];
		}
	}

	/**
	 * Has any filters geo|pois
	 * @param personKey
	 * @param filterType
	 * @returns
	 */
	hasElementData(personKey: PersonKey, filterType: TypeFilterKey) {
		let filters = Object.values(this[personKey][filterType]);
		return filters?.some((value) => !isEmpty(value));
	}

	changeAudienceType(resetGeo: Boolean = true) {
		if (resetGeo) {
			this.geo = new GeoFilterDataEntity();
		}
		this.pois = new PoisFilterDataEntity();
		this.audience = new PersonAudienceEntity();
		this.store_attribution = new PersonStoreAttributionEntity();
		this.loading = new ActionLoadingEntity();
		this.response = new ActionResponseEntity();
		this.graphics = new GraphicResponseEntity();
	}

	async changeFilters() {
		this.audience = new PersonAudienceEntity();
		this.store_attribution = new PersonStoreAttributionEntity();
		this.loading = new ActionLoadingEntity();
		this.response = new ActionResponseEntity();
		this.graphics = new GraphicResponseEntity();
	}

	/**
	 * Check if the geofilters are valid
	 * (if they are not valid, the resources are restarted)
	 * @returns
	 */
	async verifyFilters(activePanel: ActivePanelTab) {
		// Si tiene seleccionado audience_type
		// Si tiene seleccionado Pois pero no Geo
		if (
			this.hasAudienceType() &&
			this.hasPoisSelected() &&
			!this.hasGeoSelected()
		) {
			// Se resetea todo excepto geo
			this.changeAudienceType(false);

			const validTabs: ActivePanelTab[] = [
				ActivePanelTab.AUDIENCE_TYPE,
				ActivePanelTab.GEO,
			];

			if (!validTabs.includes(activePanel))
				return ActivePanelTab.AUDIENCE_TYPE;
		}

		if (activePanel === ActivePanelTab.POIS) {
			return ActivePanelTab.GEO;
		}

		return undefined;
	}

	/**
	 * Clear Filter
	 * @param personKey
	 * @param filterType
	 * @param filterKey
	 */
	clearFilter(
		personKey: PersonKey, // geo|pois
		filterType: TypeFilterKey, //selected|pre|and
		filterKey: PersonGeoKey | PersonPoisKey // states|cities|neighborhoods
	) {
		this[personKey][filterType][filterKey] = [] as ElementData[];

		if (filterType !== TypeFilterKey.PRE) return;
		this.cleanAllFilterChilds(filterKey);
	}

	/**
	 * Clear All Filters by type GEO|POIS
	 * @param type
	 * @returns
	 */
	clearAllFilterByType(type: PersonKey) {
		switch (type) {
			case PersonKey.GEO:
				this.geo = new GeoFilterDataEntity();
				this.pois = new PoisFilterDataEntity();
				this.select_all[type] = new SelectAllGeoEntity();
				break;

			case PersonKey.POIS:
				this.pois = new PoisFilterDataEntity();
				this.select_all[type] = new SelectAllPoisEntity();
				break;

			case PersonKey.PRIVATE:
				this.privates = new PrivateFilterDataEntity();
				this.select_all[type] = new SelectAllPrivateEntity();
				break;
		}
	}
}
